import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Pane, Spinner } from "evergreen-ui";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthentication, initAuthentication } from "../store/authSlice";
import { api } from "../services/api";

export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const authLoading = useSelector((state) => state.authentication.loading);
  const location = useLocation();

  const authCheck = () => {
    dispatch(initAuthentication());
    api.users.current().then((res) => {
      dispatch(checkAuthentication({ status: res.status, data: res.data }));
    }).catch((err) => {
      dispatch(checkAuthentication({ status: err.response.status, data: err.response.data }));
    });
  };

  useEffect(() => {
    authCheck();
  }, [location]);

  return authLoading ? <RouteLoading /> : children;
};

const RouteLoading = () => (
  <Pane
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    width="100%">
    <Spinner size={64} />
  </Pane>
);

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
