import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, Text, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const InviteGithubUserForm = ({ submit }) => {
  // const [allowEdit, setAllowEdit] = useState(false);
  const currentUser = useSelector((state) => state.authentication.user);

  const formik = useFormik({
    initialValues: {
      github_name: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      github_name: Yup.string().required('Name is required'),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Invite a new Github account</Heading>
            </Pane>
          </Pane>

          <Pane paddingTop={majorScale(1)} paddingBottom={majorScale(1)}>
            <FormField label="Github Name" validationMessage={errors.github_name}>
              <TextInput
                data-testid="github_name"
                name="github_name"
                onChange={handleChange}
                value={values.github_name}
                placeholder="Example: JCaimo, tmerello2001, jdrossell"
                //readOnly={!allowEdit}
                isInvalid={errors.github_name && true} />
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default InviteGithubUserForm;
