import React from 'react';
import { Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
/*
import ReactCountryFlag from 'react-country-flag';
*/
import BaseDataField from './base';

const LocationDataField = ({ name, countryCode, countryName, loading }) => (
  <BaseDataField name={name} loading={loading}>
    <Text>
{/*      <ReactCountryFlag
        countryCode={countryCode}
        style={{ fontSize: '24px' }}
      />*/}
      <Text>{countryName}</Text>
    </Text>
  </BaseDataField>
);

LocationDataField.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  loading: PropTypes.bool,
};

LocationDataField.defaultProps = {
  countryCode: undefined,
  countryName: undefined,
  loading: false,
};

export default LocationDataField;
