export default function filter(searchTerm, list, itemProperties) {
  const safeToLower = (value) => (typeof value === 'string' ? value.toLowerCase() : '');

  const predicates = {};

  itemProperties.forEach((property) => {
    predicates[property] = (item) => {
      const propertyValue = item[property];
      return safeToLower(propertyValue).includes(safeToLower(searchTerm));
    };
  });

  return list.filter((item) => Object.values(predicates).some((predicate) => predicate(item)));
}
