import React, { useState, useEffect } from 'react';
import {
  Pane,
  IconButton,
  majorScale,
  SearchInput,
  Button,
  Spinner,
  EditIcon,
  TrashIcon,
  Text,
  Badge,
  toaster,
  Tooltip,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import SettingsNavigation from '../navigation';
import CreateEmailGroupSheet from './sidesheets/create';
import { searchParams } from '../../../services/history';
import { api } from '../../../services/api';
import appTypes from '../../../constants/types';
import { AuthorizedWrapper } from '../../../components/authorized-wrapper';
import { stickyExpandOnScroll } from '../../../assets/scripts/sticky';
import PaginationMenu from '../../../components/pagination-menu';
import NoResults from '../../../components/no-results';

const SettingsEmailGroupsPage = () => {
  const [loading, setLoading] = useState(false);
  const [newOpen, setNewOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [groups, setGroups] = useState([]);
  const [metadata, setMetadata] = useState({});

  const fetchData = async () => {
    try {
      const page = searchParams.get('page') || 1;
      const itemsPerPage = searchParams.get('per_page') || 10;
      const { data } = await api.groups.list(page, itemsPerPage, searchTerm);
      setGroups(data.data);
      setMetadata(data.metadata);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    stickyExpandOnScroll('-24px');
    const event = setTimeout(() => {
      searchParams.set('search', searchTerm);
      fetchData();
    }, 500);

    return () => clearTimeout(event);
  }, [newOpen, searchTerm]); // TODO: Refresh only on close

  return (
    <>
      <Pane>
        <SettingsNavigation />

        <Pane
          id="jampp-sticky-expand"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={majorScale(2)}
          marginBottom={majorScale(4)}
          elevation={0}
          position="sticky"
          top="-24px"
          backgroundColor="white"
          zIndex="9"
          marginX={-1}>
          <SearchInput
            placeholder="Search team"
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={loading}
            value={searchTerm}
            display="flex"
            alignItems="center"
            maxWidth="50%" />

          <Pane display="flex" alignItems="center">
            <AuthorizedWrapper resource="email_groups" action="create">
              <Button
                appearance="primary"
                onClick={() => setNewOpen(true)}
                disabled={loading}>
                Create new association
              </Button>
            </AuthorizedWrapper>
          </Pane>
        </Pane>

        {loading && (
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={400}>
            <Spinner />
          </Pane>
        )}

        {!loading && groups.length < 1 && (
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={250}>
            <NoResults
              message="There are no email groups associations to show"
              buttonText="Create a new associations"
              buttonAction={() => setNewOpen(true)} />
          </Pane>
        )}

        {!loading && groups.length > 0 && (
          <>
            {groups.map((group) => (
              <EmailGroup key={group.id} group={group} />
            ))}
            <PaginationMenu
              pageMetadata={metadata}
              onPreviousPage={() => fetchData()}
              onNextPage={() => fetchData()}
              onChangePage={() => fetchData()}
              onItemsPerPageChange={() => fetchData()} />
          </>
        )}
      </Pane>

      {newOpen && (
        <CreateEmailGroupSheet
          visible={newOpen}
          close={() => setNewOpen(false)} />
      )}
    </>
  );
};

const EmailGroup = ({ group }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    backgroundColor="white"
    justifyContent="space-between"
    marginY={majorScale(2)}
    padding={majorScale(2)}
    borderRadius="3px">
    <Pane>
      <Pane display="flex" alignItems="center">
        <Text>{group.name}</Text>
      </Pane>
      <Pane marginTop={majorScale(1)}>
        {group.groups.length > 3 ? (
          <Pane>
            {group.groups.slice(0, 3).map((g) => (
              <Badge color="neutral" key={g} marginRight={majorScale(1)}>
                {g}
              </Badge>
            ))}
            <Tooltip
              position="top"
              appearance="card"
              statelessProps={{
                boxShadow:
                  '0 0 1px rgb(67 90 111 / 30%), 0 8px 10px -4px rgb(67 90 111 / 47%)',
              }}
              content={(
                <Pane>
                  {group.groups.slice(3, group.groups.length).map((g) => (
                    <Badge>{g}</Badge>
                  ))}
                </Pane>
              )}>
              <Badge color="neutral" marginRight={majorScale(1)}>
                And
                {' '}
                {group.groups.length - 3}
                {' '}
                more
              </Badge>
            </Tooltip>
          </Pane>
        ) : (
          group.groups.map((a) => (
            <Badge color="neutral" key={a} marginRight={majorScale(1)}>
              {a}
            </Badge>
          ))
        )}
      </Pane>
    </Pane>
  </Pane>
);

EmailGroup.propTypes = {
  group: PropTypes.shape({
    location: appTypes.location.props.isRequired,
    team: appTypes.team.props.isRequired,
    name: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default SettingsEmailGroupsPage;
