import React from 'react';
import PropTypes from 'prop-types';
import {
  Pane,
  majorScale,
  LinkIcon,
  Card,
  Text,
  Strong,
  Link,
  Small,
} from 'evergreen-ui';
import appTypes from '../../constants/types';

const SnipeAsset = ({ asset, selected, select, isSelectable }) => (
  <Card
    marginY={majorScale(2)}
    padding={majorScale(2)}
    backgroundColor="#FFF"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    hoverElevation={isSelectable ? 1 : null}
    activeElevation={0}
    elevation={isSelectable && selected ? 0 : 0}
    cursor={isSelectable ? 'pointer' : 'default'}
    border={selected ? '2px solid #3366FF' : 'default'}
    onClick={() => isSelectable && select && select(asset)}
  >
    <Pane display="flex" alignItems="center">
      <img
        style={{ maxWidth: '72px', maxHeight: '72px' }}
        src={asset.image}
        alt={asset.serial}
      />
      <Pane marginLeft={majorScale(2)}>
        <Pane>
          <Text>{asset.model}</Text>
        </Pane>
        {asset.serial && (
          <Pane>
            <Strong color="muted">
              <Small>Serial: </Small>
            </Strong>
            <Text color="muted">
              <Small>{asset.serial}</Small>
            </Text>
          </Pane>
        )}
        {asset.location && (
          <Pane>
            <Strong color="muted">
              <Small>Location: </Small>
            </Strong>
            <Text color="muted">
              <Small>{asset.location}</Small>
            </Text>
          </Pane>
        )}
      </Pane>
    </Pane>
    <Pane textAlign="right">
      {asset.specs.map((spec) => (
        <Pane key={spec.key}>
          <Strong color="muted">{`${spec.label}: `}</Strong>
          <Text color="muted">{spec.value}</Text>
        </Pane>
      ))}
      <Link
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        marginTop={majorScale(1)}
        href={asset.link}
        target="_blank"
      >
        <LinkIcon marginRight={majorScale(1)} />
        Link to snipe
      </Link>
    </Pane>
  </Card>
);

export default SnipeAsset;

SnipeAsset.propTypes = {
  asset: appTypes.asset.props.isRequired,
  selected: PropTypes.bool,
  select: PropTypes.func,
  isSelectable: PropTypes.bool,
};

SnipeAsset.defaultProps = {
  selected: false,
  select: null,
  isSelectable: false,
};
