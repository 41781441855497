const accounts = [
  {
    key: 'google',
    name: 'Google',
    editable: false,
    image: 'https://cdn.cdnlogo.com/logos/g/35/google-icon.svg',
  },
  {
    key: 'jira',
    name: 'Jira',
    editable: true,
    image: 'https://cdn.worldvectorlogo.com/logos/jira-1.svg',
  },
  {
    key: 'vpn',
    name: 'VPN Staff',
    editable: true,
    image: 'https://cdn.worldvectorlogo.com/logos/openvpn-2.svg',
  },
  {
    key: 'silver',
    name: 'Silver',
    editable: true,
    image:
      'https://uploads-ssl.webflow.com/60ccf8130541d43f4836b8ef/60ccf8130541d4c34c36b940_webclip.png',
  },
  {
    key: 'snipe',
    name: 'Snipe',
    editable: false,
    image: 'https://uploads-ssl.webflow.com/60ccf8130541d43f4836b8ef/60ccf8130541d4c34c36b940_webclip.png',
  },
];

export default accounts;
