import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  majorScale,
  Pane,
  toaster,
  Button,
  Dialog,
  SearchInput
} from 'evergreen-ui';
import { api } from '../../services/api';
import filter from '../../assets/scripts/filter';
import SnipeAsset from '../snipe-asset';

const SelectSnipeAssignedAssetsDialog = ({ onSelect, email }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState({id:''});

  let unmounted = false;

  async function fetchData() {
    try {
      const { data } = await api.snipe.listAssetsFromUser(email);
      if (!unmounted) {
        if (data.laptops && Array.isArray(data.laptops)) {
          setAssets(data.laptops);
        } else {
          setAssets([]);
          toaster.notify('This user does not have any assigned assets');
        }
        setLoading(false);
      }
    } catch (error) {
      if (!unmounted) {
        toaster.danger('There was an error while processing your request');
        setLoading(false);
      }
    }
  }  

  useEffect(() => {
    if (dialogOpened && email) {
      fetchData();
    }
    return () => {
      unmounted = true;
      setSearchTerm('');
    };
  }, [dialogOpened, email]);


  const handleConfirm = (close) => {
    if (onSelect && selectedAsset) {
      onSelect(selectedAsset);
    }
    close();
  };

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setDialogOpened(true);
        }}
        disabled={!email || email.trim() === ''}
        >
        Search assigned assets
      </Button>

      <Dialog
        width="50%"
        isShown={dialogOpened}
        title="Search assigned assets"
        onCloseComplete={() => setDialogOpened(false)}
        onConfirm={(close) => handleConfirm(close)}
        onCancel={(close) => onCancel(close)}
        isConfirmLoading={loading}
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <Pane>
          <Pane
            position="sticky"
            top="0px"
            backgroundColor="white"
            paddingBottom={majorScale(2)}
            marginX="-2px"
            borderBottom="muted"
            zIndex="1">
            <SearchInput
              width="100%"
              placeholder="Filter by name"
              disabled={loading}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </Pane>
        </Pane>
        <Pane padding={majorScale(1)} flex="1"> 
              <>
                <Pane>
                  {filter(searchTerm, assets, ['serial']).map((asset) => (
                  <SnipeAsset
                    key={asset.id}
                    asset={asset}
                    select={setSelectedAsset}
                    isSelectable
                  />
                ))}
                </Pane>
              </>
        <Pane>
          </Pane>
        </Pane>
      </Dialog>
    </>
  );
};

export default SelectSnipeAssignedAssetsDialog;

SelectSnipeAssignedAssetsDialog.propTypes = {
  onSelect: PropTypes.func,
  email: PropTypes.string,
};

SelectSnipeAssignedAssetsDialog.defaultProps = {
  onSelect: null,
  email: '',
};
