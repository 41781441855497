import React from 'react';
import {
  Pane,
} from 'evergreen-ui';
import SectionCard from '../../../components/section-card';
import SettingsNavigation from '../navigation';
import { SettingsEmailTemplates, SettingsEmailPredefined, SettingsEmailProviderConfig } from './components';

const SettingsEmailPage = () => (
  <Pane>
    <SettingsNavigation />

    <SettingsEmailProviderConfig />
    <SettingsEmailTemplates />
    <SettingsEmailPredefined />
  </Pane>
);

export default SettingsEmailPage;
