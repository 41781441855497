import React from 'react';
import appTypes from '../../../../constants/types';
import { Text } from 'evergreen-ui';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const AddNewSshKeyDetails = ({ ticket }) => (
  <SectionCard title="Personal Information">
    <TextDataField
      name="Name"
      value={ticket && ticket.ticket_data.new_ssh_key_user} />
    <TextDataField
      name="SSH public key"
      value={ticket && <Text style={{ wordBreak: 'break-all' }}>{ticket.ticket_data.new_ssh_key}</Text>} />
  </SectionCard>
);

AddNewSshKeyDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
AddNewSshKeyDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
