import React, { useState, useEffect } from 'react';
import { Combobox } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { api } from '../../services/api';

const SelectGoogleOrgUnitInput = ({ value, onChange, disabled }) => {
  const [loading, setLoading] = useState(true);
  const [orgUnits, setOrgUnits] = useState([]);

  let unmounted = false;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.google.listOrgUnits();
        onChange(data[0]);
        if (!unmounted) {
          setOrgUnits(data);
          setLoading(false);
        }
      } catch (error) {
        if (!unmounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Combobox
      items={orgUnits}
      itemToString={(item) => (item ? `${item.name} (${item.path})` : '')}
      selectedItem={value}
      onChange={(selected) => onChange && onChange(selected || '')}
      disabled={disabled}
      isLoading={loading}
      width="40%"
      openOnFocus
      data-testid="orgunit" />
  );
};

SelectGoogleOrgUnitInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectGoogleOrgUnitInput.defaultProps = {
  value: null,
};

export default SelectGoogleOrgUnitInput;
