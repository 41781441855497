import React from 'react';
import { minorScale, Pane, Text, Avatar } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseDataField from './base';

const UserDataField = ({ name, userName, loading }) => (
  <BaseDataField name={name} loading={loading}>
    <Pane display="flex" alignItems="center" marginTop={minorScale(1)}>
      <Avatar name={userName} />
      <Text marginLeft={minorScale(1)}>{userName}</Text>
    </Pane>
  </BaseDataField>
);

UserDataField.propTypes = {
  name: PropTypes.string.isRequired,
  userName: PropTypes.string,
  loading: PropTypes.bool,
};

UserDataField.defaultProps = {
  userName: null,
  loading: false,
};

export default UserDataField;
