import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const SnipeCheckinAssetDetails = ({ ticket }) => (
  <SectionCard title="Account information">
    <TextDataField
      name="Email"
      value={ticket && ticket.ticket_data.email} />
    <TextDataField
      name="Asset ID"
      value={ticket && ticket.ticket_data.asset_id} />
  </SectionCard>
);

SnipeCheckinAssetDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
SnipeCheckinAssetDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
