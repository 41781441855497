import React, { useState, useEffect } from 'react';
import {
  Pane,
  Heading,
} from 'evergreen-ui';
import SectionCard from '../../../../components/section-card';
import { api } from '../../../../services/api';

const TotalPendingTicketsChart = () => {
  const [metricData, setMetricData] = useState({ total: 0 });
  const [loading, setLoading] = useState(false);

  let unmounted = false;
  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.metrics.totalTickets('pending');
      if (!unmounted) {
        setMetricData(data);
        setLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <SectionCard
      title="Pending tickets">
      <Pane display="flex" alignItems="center" justifyContent="center">
        {!loading && (
          <Heading size={900}>
            {`${metricData.total} Tickets`}
          </Heading>
        )}
      </Pane>
    </SectionCard>
  );
};

export default TotalPendingTicketsChart;
