import React, { useState } from 'react';
import {
  Pane, FormField, Switch, majorScale,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseSheet from '../../../../components/sidesheets/base';
import formHelp from '../../../../constants/help';
import appTypes from '../../../../constants/types';

const EditSheet = ({ visible, feature, close, submit }) => {
  const [editedFeature, setEditedFeature] = useState(feature);
  const newSubmit = () => {
    submit(editedFeature);
  };

  const handleChange = (value) => {
    setEditedFeature({ ...editedFeature, enabled: value });
  };

  const form = (
    <Pane>
      <FormField label="Enabled" display="flex" justifyContent="space-between" marginTop={majorScale(1)} onChange={(e) => handleChange(e.target.checked)}>
        <Switch height={20} checked={editedFeature.enabled} />
      </FormField>
    </Pane>
  );

  return (
    <BaseSheet.Create
      form={form}
      close={close}
      help={formHelp.user}
      visible={visible}
      submitLoading={false}
      title={`Edit ${editedFeature.label.charAt(0).toLowerCase()}${editedFeature.label.slice(1)}`}
      okText="Save"
      onSubmit={newSubmit} />
  );
};

EditSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  feature: appTypes.feature.props.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default EditSheet;
