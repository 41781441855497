/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    loading: true,
    user: {
      role: {
        permissions: {},
      },
    },
  },
  reducers: {
    initAuthentication: (state) => {
      state.loading = true;
    },
    checkAuthentication: (state, payload) => {
      if (payload.payload.status === 200) {
        state.user = payload.payload.data;
        state.loading = false;
      } else {
        window.location = payload.payload.data.redirect;
      }
    },
  },
});

export const { initAuthentication, checkAuthentication } = authenticationSlice.actions;

export default authenticationSlice.reducer;
