import React, { useEffect, useState } from 'react';
import { Pane, SelectField, majorScale, toaster, FormField } from 'evergreen-ui';
import { CustomCheckbox, SelectGoogleOrgUnitInput } from '../../../../../../components/inputs';
import accounts from '../../../../../../constants/accounts';
import { api } from '../../../../../../services/api';

const AccountsStep = (formik) => {
  const [loading, setLoading] = useState(true);
  const [enabledAccounts, setEnabledAccounts] = useState([]);

  useEffect(async () => {
    try {
      const { data } = await api.settings.get('onboarding_ticket');
      setEnabledAccounts(data.accounts);
      setLoading(false);
    } catch (error) {
      toaster.error('Error occured while loading enabled accounts. Please try again later');
      setEnabledAccounts(['google', 'snipe']);
      setLoading(false);
    }
  }, []);

  return {
    title: 'Accounts',
    description: 'If you only want to create the Google account, skip this step',
    body: !loading && (
      <Pane>
        {accounts.filter((account) => enabledAccounts.includes(account.key)).map((account) => {
          const isVpnStaffSelected = account.key === 'vpn';
          return (
            <React.Fragment key={account.key}>
              <CustomCheckbox
                name={`accounts.${account.key}`}
                label={account.name}
                disabled={!account.editable}
                onCheck={formik.handleChange}
                defaultValue={formik.values.accounts && formik.values.accounts[account.key]}
              />
              {account.key === 'google' && formik.values.accounts && formik.values.accounts.google && (
                <FormField
                  label="Organizational unit"
                  marginTop={majorScale(2)}
                  maxWidth="50%"
                  validationMessage={formik.errors.google_org_unit}>
                  <SelectGoogleOrgUnitInput
                    value={formik.values.google_org_unit}
                    onChange={(selected) => formik.setFieldValue('google_org_unit', selected)} />
                </FormField>
              )}
              {isVpnStaffSelected && formik.values.accounts && formik.values.accounts.vpn && (
                <SelectField
                  label="Organization"
                  hint="Before you continue, select the access level for the Jampper."
                  validationMessage={formik.errors.organization}
                  data-testid="organization"
                  name="organization"
                  maxWidth="50%"
                  marginTop={majorScale(2)}
                  onChange={formik.handleChange}
                  value={formik.values.organization}
                  isInvalid={formik.errors.organization && true}
                >
                  <option value="BI">Business Intelligence</option>
                  <option value="Customer_Solutions">Customer Solutions</option>
                  <option value="External">External</option>
                  <option value="Finance">Finance</option>
                  <option value="Internet">Internet</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Partners">Partners</option>
                  <option value="People">People</option>
                  <option value="Product">Product</option>
                  <option value="Revenue">Revenue</option>
                  <option value="Tech">Tech</option>
                </SelectField>
              )}
            </React.Fragment>
          );
        })}
      </Pane>
    ),
  };
};

export default AccountsStep;
