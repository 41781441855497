import React from 'react';
import { Pane } from 'evergreen-ui';
import GithubConnectSettings from './components/github-connect';

const UserSettingsPage = () => (
  <Pane>
    <GithubConnectSettings />
  </Pane>
);

export default UserSettingsPage;
