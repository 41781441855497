import React, { useState, useEffect } from 'react';
import {
  Pane,
  majorScale,
  Tab,
  Tablist,
  Text,
  toaster,
  Spinner,
  SearchInput,
} from 'evergreen-ui';
import _ from 'lodash';
import { api } from '../../../../../../services/api';
import SnipeAsset from '../../../../../../components/snipe-asset';

const tabs = [
  { id: 'laptops', label: 'Laptops' },
  { id: 'monitors', label: 'Monitors' },
];

const AssetsStep = (formik) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState({ laptops: [], monitors: [] });

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.snipe.listAvailableAssetsOnOnboarding();
        setAssets(data);
        setLoading(false);
      } catch (error) {
        toaster.danger('There was an error while proccessing your request');
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const selectAsset = (asset) => {
    formik.setFieldValue(`assets.${asset.type}`, asset);
  };

  const filter = () => {
    if (searchTerm.length < 1) {
      return assets[tabs[currentTab].id];
    }
    return _.filter(
      assets[tabs[currentTab].id],
      (a) => a.serial.toLowerCase().includes(searchTerm.toLowerCase())
        || a.model.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  return {
    title: 'Assets',
    description: "If you don't want to assign assets, skip this step",
    body: (
      <Pane>
        <Tablist marginBottom={majorScale(2)}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              onSelect={() => setCurrentTab(index)}
              isSelected={index === currentTab}>
              {tab.label}
            </Tab>
          ))}
        </Tablist>
        <Pane padding={majorScale(1)} flex="1">
          <SearchInput
            placeholder="Search by model or serial"
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={
              loading
              || (currentTab === 0 && assets.laptops.length < 1)
              || (currentTab === 1 && assets.monitors.length < 1)
            } />

          {/* TODO: Add clear button */}

          {loading ? (
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={400}>
              <Spinner />
              <Text marginLeft={majorScale(2)}>Loading assets</Text>
            </Pane>
          ) : (
            <>
              {/* Laptops */}
              <Pane
                role="tabpanel"
                display={tabs[currentTab].id === 'laptops' ? 'block' : 'none'}>
                {filter().map((asset) => (
                  <SnipeAsset
                    key={asset.id}
                    asset={{ type: 'laptop', ...asset }}
                    select={selectAsset}
                    isSelectable
                    selected={
                      formik.values.assets
                      && formik.values.assets.laptop
                      && formik.values.assets.laptop.id === asset.id
                    } />
                ))}
              </Pane>

              {/* Monitors */}
              <Pane
                role="tabpanel"
                display={tabs[currentTab].id === 'monitors' ? 'block' : 'none'}>
                {filter().map((asset) => (
                  <SnipeAsset
                    key={asset.id}
                    asset={{ type: 'monitor', ...asset }}
                    select={selectAsset}
                    isSelectable
                    selected={
                      formik.values.assets
                      && formik.values.assets.monitor
                      && formik.values.assets.monitor.id === asset.id
                    } />
                ))}
              </Pane>
            </>
          )}
        </Pane>
      </Pane>
    ),
  };
};

export default AssetsStep;
