import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const getSearchParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
};

const setSearchParam = (key, value) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);
  history.push({
    pathname: window.location.pathname,
    search: `?${queryParams.toString()}`,
  });
};

const removeSearchParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(key);
  history.push({
    pathname: window.location.pathname,
    search: `?${queryParams.toString()}`,
  });
};

export const searchParams = {
  get: getSearchParam,
  set: setSearchParam,
  remove: removeSearchParam,
};

export default history;
