import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const GoogleAddMemberToGroupDetails = ({ ticket }) => (
  <SectionCard title="Group Details">
      <TextDataField
      name="Member Email"
      value={ticket && ticket.ticket_data.member_email} />
    <TextDataField
      name="Group Email"
      value={ticket && ticket.ticket_data.group_email} />
  </SectionCard>
);

GoogleAddMemberToGroupDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
GoogleAddMemberToGroupDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
