import PropTypes from 'prop-types';

const allowedUser = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
  }),
  default: {
    id: null,
    email: null,
    name: null,
    enabled: null,
  },
};

const role = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    uname: PropTypes.string.isRequired,
    active_directory_cn: PropTypes.string,
    permissions: PropTypes.shape({
      actions: PropTypes.arrayOf(PropTypes.string),
      resource: PropTypes.string.isRequired,
      filters: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.shape({
          type: 'dynamic' || 'static',
          resource: PropTypes.string.isRequired,
          field: PropTypes.string.isRequired,
        }),
      })),
    }),
  }),
  defaults: {
    active_directory_cn: undefined,
    permissions: [],
  },
};

const location = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    country_code: PropTypes.string.isRequired,
    aliases: PropTypes.arrayOf(PropTypes.string),
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    created_by: allowedUser.props.isRequired,
  }),
  defaults: {
    aliases: [],
  },
};

const feature = {
  props: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    value_type: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  })
};

const team = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    aliases: PropTypes.arrayOf(PropTypes.string),
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    created_by: allowedUser.props.isRequired,
  }),
  defaults: {
    aliases: [],
  },
};

const help = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      required: PropTypes.bool,
      description: PropTypes.string.isRequired,
    }),
  ),
};

const asset = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    serial: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    link: PropTypes.string,
    image: PropTypes.string,
    location: PropTypes.string,
    specs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }),
};

const onboarding = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    personal_email: PropTypes.string.isRequired,
    team: team.props.isRequired,
    location: location.props.isRequired,
    jampp_email: PropTypes.string.isRequired,
    email_groups: PropTypes.arrayOf(PropTypes.string),
    accounts: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(asset.props),
    status: PropTypes.string.isRequired,
    run_logs: PropTypes.string,
    run_date: PropTypes.string,
    run_by: allowedUser.props,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    created_by: allowedUser.props.isRequired,
  }),
  default: PropTypes.shape({
    email_groups: [],
    accounts: [],
    assets: [],
    run_logs: null,
    run_date: null,
    run_by: null,
  }),
};

const ticket = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    operation: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string.isRequired,
    status_reason: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    // ticket_data: PropTypes.object,
    created_by: allowedUser.props.isRequired,
    updated_by: allowedUser.props,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),
  default: PropTypes.shape({
    operation: 'create',
    description: '',
    status_reason: '',
    // ticket_data: {},
    updated_by: null,
  }),
};

const emailTemplate = {
  props: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    design: PropTypes.shape,
    html: PropTypes.string,
    deletable: PropTypes.bool,
    variables: PropTypes.arrayOf(PropTypes.string),
  }),
  default: PropTypes.shape({
    design: null,
    html: null,
    deletable: true,
    variables: null,
  }),
};

const appTypes = {
  location,
  team,
  allowedUser,
  help,
  asset,
  onboarding,
  ticket,
  role,
  emailTemplate,
  feature,
};

export default appTypes;
