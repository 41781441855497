import React, { useState, useEffect } from 'react';
import {
  Button,
  CogIcon,
  toaster,
} from 'evergreen-ui';
import { api } from '../../../../../services/api';
import UpdateProviderSheeet from './sidesheets/update';
import SectionCard from '../../../../../components/section-card';
import { TextDataField } from '../../../../../components/data-fields';
import { AuthorizedWrapper } from '../../../../../components/authorized-wrapper';

const SettingsEmailProviderConfig = () => {
  const settingName = 'email_provider';
  // Flags
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState(1);
  const [editOpen, setEditOpen] = useState(false);

  let unmounted = false;
  const fetchData = async () => {
    try {
      const { data } = await api.settings.get(settingName);
      if (!unmounted) {
        setConfig(data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <SectionCard
      title="Provider configuration"
      button={(
        <AuthorizedWrapper resource="settings" action="update">
          <Button iconBefore={CogIcon} marginY={-6} onClick={() => setEditOpen(true)}>
            Update provider
          </Button>
        </AuthorizedWrapper>
      )}>

      <TextDataField
        name="Credentials provider"
        value={config && config.provider}
        loading={loading} />

      {config && config.provider === 'vault' && (
        <TextDataField
          name="Secret path"
          value={config.vault_secret_path}
          loading={loading} />
      )}

      {editOpen && (
        <UpdateProviderSheeet
          // submit={updateSetting}
          visible={editOpen}
          config={config}
          close={() => {
            setEditOpen(false);
          }} />
      )}

    </SectionCard>
  );
};

export default SettingsEmailProviderConfig;
