import Create from './create';
import Details from './details';
import Edit from './edit';

const BaseSheet = {
  Create,
  Details,
  Edit,
};

export default BaseSheet;
