import React, { useState, useEffect } from 'react';
import {
  Pane, majorScale, TextInput, FormField, toaster,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import _ from 'lodash';
import { api } from '../../../../services/api';
import { searchParams } from '../../../../services/history';
import BaseSheet from '../../../../components/sidesheets/base';
import { CustomSelect, CustomTagInput } from '../../../../components/inputs';
import { countriesForSelect } from '../../../../constants/countries';
import formHelp from '../../../../constants/help';

const EditLocationSheet = ({ visible, close }) => {
  const id = searchParams.get('selected');
  const [loading, setLoading] = useState(true);

  const validateForm = (values) => {
    const errors = {};
    if (!values.country) {
      errors.country = 'This field is required';
    }
    if (!values.name || values.name.length < 1) {
      errors.name = 'This field is required';
    }
    return errors;
  };

  const submitForm = async (values) => {
    const data = values;
    data.country_code = data.country.value;

    try {
      await api.locations.update(id, data);
      toaster.success(`${values.name} updated successfully`);
      close();
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: submitForm,
    validate: validateForm,
    validateOnChange: true,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.locations.get(id);
        formik.setFieldValue(
          'country',
          _.filter(countriesForSelect(), { value: data.country_code })[0],
        );
        formik.setFieldValue('name', data.name);
        formik.setFieldValue('aliases', data.aliases);
        setLoading(false);
      } catch (error) {
        toaster.danger('There was an error while proccessing your request');
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const form = (
    <Pane>
      <FormField
        label="Country"
        maxWidth="50%"
        validationMessage={formik.errors.country}>
        <CustomSelect
          items={countriesForSelect()}
          defaultValue={formik.values.country}
          onChange={(selected) => formik.setFieldValue('country', selected)} />
      </FormField>
      <FormField
        label="Location name"
        marginTop={majorScale(2)}
        validationMessage={formik.errors.name}>
        <TextInput
          name="name"
          onChange={formik.handleChange}
          defaultValue={formik.values.name}
          isInvalid={formik.errors.name && true} />
      </FormField>
      <FormField label="Location aliases" marginTop={majorScale(2)}>
        <CustomTagInput
          defaultValue={formik.values.aliases}
          onChange={(values) => formik.setFieldValue('aliases', values)} />
      </FormField>
    </Pane>
  );

  return (
    <BaseSheet.Edit
      form={form}
      close={close}
      help={formHelp.location}
      visible={visible}
      loading={loading}
      submitLoading={false}
      submitDisabled={Object.keys(formik.errors).length > 0}
      title="location"
      onSubmit={formik.handleSubmit} />
  );
};

EditLocationSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditLocationSheet;
