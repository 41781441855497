import AccountsStep from './accounts';
import AssetsStep from './assets';
import EmailsStep from './emails';
import ReviewStep from './review';
import UserDataStep from './user-data';

const Steps = {
  UserData: UserDataStep,
  Accounts: AccountsStep,
  Assets: AssetsStep,
  Emails: EmailsStep,
  Review: ReviewStep,
};

export default Steps;
