import React, { useState, useEffect } from 'react';
import { Combobox } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { api } from '../../services/api';
import appTypes from '../../constants/types';

const SelectUserInput = ({ defaultValue, onChange, disabled }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.users.list(1, 1000);
        setUsers(_.sortBy(data.data, 'name'));
        setLoading(false);
        return;
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Combobox
      items={users}
      initialSelectedItem={defaultValue}
      onChange={(selected) => onChange && onChange(selected || null)}
      itemToString={(item) => (item ? `${item.name} (${item.email})` : '')}
      disabled={disabled}
      isLoading={loading}
      width="100%"
      openOnFocus
      data-testid="user" />
  );
};

SelectUserInput.propTypes = {
  defaultValue: appTypes.team.props,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectUserInput.defaultProps = {
  defaultValue: null,
};

export default SelectUserInput;
