import React, { useState, useEffect } from 'react';
import { Button, Card, SelectField, FormField, Heading, majorScale, Pane, Checkbox, TextInput, toaster } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectAWSEC2InstanceType } from '../../../../../components/dialogs';
import { api } from '../../../../../services/api';

const ModifyEC2SandboxForm = ({ submit }) => {
  let unmounted = false;

  const formik = useFormik({
    initialValues: {
      aws_region: 'us-east-1',
      instance_id: '',
      instance_type: { change: false, value: '' },
      ebs_disk: { change: false, value: 0 },
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      aws_region: Yup.string().required('AWS region is required'),
      instance_id: Yup.string().required('Instance id is required'),
      instance_type: Yup.object().shape({
        change: Yup.bool(),
        value: Yup.string(),
      }),
      ebs_disk: Yup.object().shape({
        change: Yup.bool(),
        value: Yup.number(),
      }),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  const selectInstanceType = (instanceType) => {
    if (instanceType) {
      formik.setFieldValue('instance_type.value', instanceType.name);
    }
  };

  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState([]);
  async function fetchRegions() {
    setRegionsLoading(true);
    try {
      const { data } = await api.aws.listRegions();
      if (!unmounted) {
        setRegions(data);
        setRegionsLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS regions');
      setRegionsLoading(false);
    }
  }

  useEffect(() => {
    fetchRegions();
    return () => {
      unmounted = true;
    };
  }, []);

  const [instances, setInstances] = useState([]);
  const [instancesLoading, setInstancesLoading] = useState([]);
  async function fetchInstances() {
    setInstancesLoading(true);
    try {
      const { data } = await api.aws.listEC2SandboxInstances(values.aws_region);
      if (!unmounted) {
        setInstances(data);
        formik.setFieldValue('instance_id', data.length > 0 ? data[0].instance_id : '');
        setInstancesLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS regions');
      setInstancesLoading(false);
    }
  }

  useEffect(() => {
    fetchInstances();
    return () => {
      unmounted = true;
    };
  }, [values.aws_region]);

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Modify an existing EC2 Sandbox instance</Heading>
            </Pane>
          </Pane>

          <Pane paddingY={majorScale(2)}>
            <Pane display="flex">
              <SelectField
                label="Region"
                validationMessage={errors.aws_region}
                data-testid="aws_region"
                name="aws_region"
                disabled={regionsLoading}
                onChange={handleChange}
                value={values.aws_region}
                isInvalid={errors.aws_region && true}>
                {!regionsLoading && regions.length > 0
                  && regions.map((region) => (
                    <option key={region} value={region}>{region}</option>
                  ))}
              </SelectField>
              <SelectField
                marginLeft={majorScale(2)}
                label="Instance to modify"
                hint="Only instances you own are shown"
                validationMessage={errors.instance_id}
                data-testid="aws_region"
                name="aws_region"
                disabled={instancesLoading}
                onChange={handleChange}
                value={values.instance_id}
                isInvalid={errors.instance_id && true}>
                {!instancesLoading && instances.length > 0
                  && instances.map((instance) => (
                    <option key={instance.instance_id} value={instance}>
                      {instance.instance_name}
                      {' '}
                      (
                      {instance.private_ip}
                      )
                    </option>
                  ))}
                {instancesLoading && <option>Loading...</option>}
                {!instancesLoading && instances.length < 1
                  && <option disabled>No instances to show</option>}
              </SelectField>
            </Pane>

            <Checkbox
              label="Change instance type"
              checked={values.instance_type.change}
              onChange={(e) => formik.setFieldValue('instance_type.change', e.target.checked)} />

            {values.instance_type.change && (
              <Pane display="flex">
                <FormField label="Instance type">
                  <Pane display="flex">
                    <TextInput value={values.instance_type.value} readOnly width="50%" marginRight={majorScale(1)} />
                    <SelectAWSEC2InstanceType
                      region={values.aws_region}
                      onSelect={selectInstanceType} />
                  </Pane>
                </FormField>
              </Pane>
            )}

            <Checkbox
              label="Change EBS disk size"
              checked={values.ebs_disk.change}
              onChange={(e) => formik.setFieldValue('ebs_disk.change', e.target.checked)} />

            {values.ebs_disk.change && (
              <FormField
                label="EBS disk"
                validationMessage={errors.ebs_disk ? errors.ebs_disk.value : null}
                hint="The amount of GiB you need (apart from root device)">
                <TextInput
                  data-testid="ebs_disk.value"
                  name="ebs_disk.value"
                  type="number"
                  onChange={handleChange}
                  value={values.ebs_disk.value}
                  isInvalid={errors.ebs_disk && errors.ebs_disk.value} />
              </FormField>
            )}

          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default ModifyEC2SandboxForm;
