import React from 'react';
import { Badge, majorScale, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseDataField from './base';
import getStatusColor from '../../assets/scripts/getStatusColor';

const StatusDataField = ({ name, status, loading }) => (
  <BaseDataField name={name} loading={loading}>
    {status && (
    <Pane marginTop={majorScale(1)}>
      <Badge color={getStatusColor(status)}>{status}</Badge>
    </Pane>
    )}
  </BaseDataField>
);

StatusDataField.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  loading: PropTypes.bool,
};

StatusDataField.defaultProps = {
  status: 'pending',
  loading: false,
};

export default StatusDataField;
