import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';
import { silverRoles } from '../../../../constants/silver';

export const SilverAccountDetails = ({ ticket }) => (
  <SectionCard title="Account information">
    <TextDataField
      name="Name"
      value={ticket && ticket.ticket_data.name} />
    <TextDataField
      name="Email"
      value={ticket && ticket.ticket_data.email} />
    <TextDataField
      name="Role"
      value={ticket && silverRoles[ticket.ticket_data.role]} />
  </SectionCard>
);

SilverAccountDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
SilverAccountDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
