import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'evergreen-ui';
import Navbar from '../navbar';
import Drawer from '../drawer';
import './index.scss';

export const Layout = ({ children }) => {
  const [drawerOpened, setDrawerOpened] = useState(window.innerWidth > 1024);
  const componentRef = useRef();

  const changeDrawer = () => {
    setDrawerOpened(!drawerOpened);
  };

  useEffect(() => {
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current;
        if (!ref.contains(e.target) && drawerOpened && window.innerWidth <= 1024) {
          setDrawerOpened(0);
        }
      }
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });

  return (
    <Pane className="content">
      <Drawer opened={drawerOpened} />
      <Pane className="router-children" ref={componentRef}>
        <Navbar onChangeDrawer={changeDrawer} />
        <section className="route" id="jampp-route">
          {children}
        </section>
      </Pane>
    </Pane>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
