import React from 'react';
import dayjs from 'dayjs';
import { Heading, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import Skeleton from '../skeleton';

const DatesDataField = ({ created, updated, loading }) => {
  if (loading) {
    return (
      <Pane>
        <Skeleton width="150px" height="12px" />
        <Skeleton width="150px" height="12px" style={{ marginTop: '8px' }} />
      </Pane>
    );
  }

  return (
    <Pane>
      <Heading size={100}>
        Created
        {' '}
        {dayjs(created).format('DD-MM-YYYY')}
      </Heading>
      <Heading size={100}>
        Updated
        {' '}
        {dayjs(updated).format('DD-MM-YYYY')}
      </Heading>
    </Pane>
  );
};

DatesDataField.propTypes = {
  created: PropTypes.string,
  updated: PropTypes.string,
  loading: PropTypes.bool,
};

DatesDataField.defaultProps = {
  created: null,
  updated: null,
  loading: false,
};

export default DatesDataField;
