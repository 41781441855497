import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, TextInput, toaster } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectGithubRepositoryDialog } from '../../../../../components/dialogs';
import { SelectGithubTeamInput } from '../../../../../components/inputs'

const AddTeamToRepoForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      team_name: null,
      repo_name: 'ansible',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      team_name: Yup.string().nullable().required('Name of the team is required'),
      repo_name: Yup.string().required('Name of the repository is required'),
    }),
    validateOnChange: true,
  });

  const { values, errors } = formik;

  const selectGithubRepository = (githubRepositories) => {
    if (githubRepositories) {
      formik.setFieldValue('repo_name', githubRepositories.name);
    }
  };

  const selectGithubTeam = (githubTeams) => {
    if (githubTeams) {
      formik.setFieldValue('team_name', githubTeams);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Checkbox
            label="Enter name of the repository manually"
            checked={allowEdit}
            onChange={(e) => {
              setAllowEdit(e.target.checked);
            }}
          />
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Add a team to a repository</Heading>
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)} paddingTop={majorScale(1)}>
            <FormField
              label="Team"
              marginTop={majorScale(2)}
              maxWidth="50%"
              validationMessage={formik.errors.team_name}>
              <SelectGithubTeamInput
                value={formik.values.team_name}
                onSelect={selectGithubTeam}
                readOnly={!allowEdit}
              />
            </FormField>
            <Pane paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
              <FormField label="Repository Name" validationMessage={errors.repo_name}>
                <Pane>
                  <TextInput
                    value={values.repo_name}
                    readOnly={!allowEdit}
                    width="12%"
                    marginRight={majorScale(1)}
                    onChange={(e) => formik.setFieldValue('repo_name', e.target.value)}
                  />
                  <SelectGithubRepositoryDialog
                    onSelect={selectGithubRepository}
                    readOnly={!allowEdit}
                  />
                </Pane>
              </FormField>
            </Pane>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default AddTeamToRepoForm;
