import React, { useState } from 'react';
import {
  Pane,
  majorScale,
  Heading,
  Card,
  Text,
  Button,
  HelpIcon,
  CrossIcon,
} from 'evergreen-ui';
import appTypes from '../../../constants/types';

const SheetHelp = ({ help }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Pane marginTop="-48px">
        <Button
          type="button"
          appearance="minimal"
          iconBefore={open ? CrossIcon : HelpIcon}
          onClick={() => setOpen(!open)}
        >
          {open ? 'Close help' : 'Help'}
        </Button>
      </Pane>

      {open && (
        <Pane marginY={majorScale(2)}>
          <Card backgroundColor="white" elevation={0} padding={majorScale(2)}>
            {help.map(h => (
              <Pane key={h.key} marginBottom={majorScale(2)}>
                <Heading size={400}>
                  {h.title}
                  {h.required && <Text color="red500"> (required)</Text>}
                </Heading>
                <Text>{h.description}</Text>
              </Pane>
            ))}
          </Card>
        </Pane>
      )}
    </>
  );
};

SheetHelp.propTypes = {
  help: appTypes.help.props,
};

SheetHelp.defaultProps = {
  help: [],
};

export default SheetHelp;
