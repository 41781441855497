import React from 'react';
import { Text } from 'evergreen-ui';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const NewEC2SandboxDetails = ({ ticket }) => (
  <>
    <SectionCard title="Owner information">
      <TextDataField
        name="Name"
        value={ticket && ticket.ticket_data.owner_name} />
      <TextDataField
        name="Email"
        value={ticket && ticket.ticket_data.owner_email} />
      <TextDataField
        name="SSH public key"
        value={ticket && <Text style={{ wordBreak: 'break-all' }}>{ticket.ticket_data.owner_ssh_key}</Text>} />
    </SectionCard>
    <SectionCard title="EC2 specifications">
      <TextDataField
        name="Region"
        value={ticket && ticket.ticket_data.aws_region} />
      <TextDataField
        name="Instance type"
        value={ticket && ticket.ticket_data.instance_type} />
      <TextDataField
        name="EBS disk"
        value={ticket && `${ticket.ticket_data.ebs_disk} GiB`} />
    </SectionCard>
  </>
);

NewEC2SandboxDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
NewEC2SandboxDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
