import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, Text, TextareaField, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddNewSshKeyForm = ({ submit }) => {
  // const [allowEdit, setAllowEdit] = useState(false);
  const currentUser = useSelector((state) => state.authentication.user);
  const sshKeyRegex = /^(ssh-rsa|ssh-dss|ssh-ed25519|ecdsa-sha2-nistp) [A-Za-z0-9+\/]+={0,3}( .+)?$/;

  const formik = useFormik({
    initialValues: {
      new_ssh_key_user: '',
      new_ssh_key: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      new_ssh_key_user: Yup.string().required('Name is required'),
      new_ssh_key: Yup.string()
      .required('SSH public key is required')
      .test(
        'is-ssh-key',
        'The provided string does not look like a valid SSH public key.',
        value => sshKeyRegex.test(value)
      ),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Add a new SSH key to Ansible repository</Heading>
            </Pane>
          </Pane>

          <Pane paddingTop={majorScale(2)}>
            <FormField label="Full Name" validationMessage={errors.new_ssh_key_user}>
              <TextInput
                data-testid="new_ssh_key_user"
                name="new_ssh_key_user"
                onChange={handleChange}
                value={values.new_ssh_key_user}
                //readOnly={!allowEdit}
                placeholder="Example: John Doe, Jane Roe"
                isInvalid={errors.new_ssh_key_user && true} />
            </FormField>
            <Pane display="flex" marginTop={majorScale(2)} width="50%">
              <TextareaField
                label="SSH Public Key"
                name="new_ssh_key"
                data-testid="new_ssh_key"
                value={values.new_ssh_key}
                onChange={handleChange}
                validationMessage={errors.new_ssh_key}
                //readOnly={!allowEdit}
                placeholder="Paste your public SSH key here"
                width="100%"
                isInvalid={errors.new_ssh_key && true} />
            </Pane>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default AddNewSshKeyForm;
