import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, Text, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectGithubTeamInput, SelectGithubUserInput } from '../../../../../components/inputs'

const AddUserToTeamForm = ({ submit }) => {
  // const [allowEdit, setAllowEdit] = useState(false);
  const currentUser = useSelector((state) => state.authentication.user);

  const formik = useFormik({
    initialValues: {
      team_name: null,
      user_name: null,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      team_name: Yup.string().nullable().required('Name of the team is required'),
      user_name: Yup.string().nullable().required('Name of the user is required'),
    }),
    validateOnChange: true,
  });

  const selectGithubTeam = (githubTeams) => {
    if (githubTeams) {
      formik.setFieldValue('team_name', githubTeams);
    }
  };

  const selectGithubUser = (githubUsers) => {
    if (githubUsers) {
      formik.setFieldValue('user_name', githubUsers);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Add user to a team</Heading>
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)} paddingTop={majorScale(1)}>
            <FormField
                label="Team"
                marginTop={majorScale(2)}
                maxWidth="50%"
                validationMessage={formik.errors.team_name}>
                <SelectGithubTeamInput value={formik.values.team_name}
                  onSelect={selectGithubTeam} />
              </FormField>
            <Pane paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
            <FormField
                label="User"
                marginTop={majorScale(2)}
                maxWidth="50%"
                validationMessage={formik.errors.user_name}>
                <SelectGithubUserInput value={formik.values.user_name}
                  onSelect={selectGithubUser} />
              </FormField>
            </Pane>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default AddUserToTeamForm;
