import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  Badge,
  majorScale,
  toaster,
  InlineAlert,
  Card,
  Strong,
  TextInputField,
  Combobox,
  Pagination,
} from 'evergreen-ui';
import { api } from '../../../services/api';
import SectionCard from '../../../components/section-card';
import { BaseDataField } from '../../../components/data-fields';
import { useUserAccess } from '../../../contexts/user-access-content';

const AWSIAMRoleAccess = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [serviceAccessDetails, setServiceAccessDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const { userAccessLoading } = useUserAccess();
  const [currentPage, setCurrentPage] = useState(1);

  let unmounted = false;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesResponse = await api.aws.listRoles();
        if (rolesResponse && rolesResponse.data) {
          const formattedRoles = rolesResponse.data.map((role) => ({ label: role }));
          setRoles(formattedRoles);
        }
      } catch (error) {
        toaster.danger('Failed to fetch roles: ' + error.message);
      } finally {
        if (!unmounted) {
          setLoading(false);
        }
      }
    };
    if (!userAccessLoading) {
      fetchRoles();
    }

    return () => {
      unmounted = true;
    };
  }, [userAccessLoading]);

  const transformPolicies = (attached, inline) => {
    let processed = {};
    const process = (current, policies) => {
      // eslint-disable-next-line no-underscore-dangle
      const _processed = current;
      Object.entries(policies).forEach(([service, actions]) => {
        const s = service.toLowerCase();
        if (!Object.keys(_processed).includes(s)) {
          _processed[s] = [];
        }

        actions.forEach((action) => {
          const a = action !== '*' ? action.split(':')[1].toLowerCase() : '*';
          if (!_processed[s].includes(a)) {
            _processed[s].push(a);
          }
        });
      });

      return _processed;
    };

    // Process policies
    processed = process(processed, attached);
    processed = process(processed, inline);

    return processed;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedRole) return;

      setLoading(true);
      setServiceAccessDetails({});
      try {
        const response = await api.aws.computeIAMRoleAccess(selectedRole.label);
        if (response && response.data) {
          const { data } = response;
          if (!unmounted) {
            setServiceAccessDetails(transformPolicies(data.AttachedPolicies, data.InlinePolicies));
          }
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message.name
          : 'An unexpected error occurred';
        toaster.danger('Error occurred while loading AWS Role permissions', { description: errorMessage });
      } finally {
        if (!unmounted) {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedRole]);

  const filteredResults = (term) => Object.keys(serviceAccessDetails).filter((service) => (
    service.includes(term)
    || (serviceAccessDetails[service].filter((action) => action.includes(term))).length > 0
  ));

  return (
    <SectionCard title="AWS Roles">
      <Pane>
        {loading && selectedRole && (
          <InlineAlert intent="none" marginBottom={16}>
            We are computing the access of this AWS IAM Role.
            This may take some minutes, please do not close the window.
          </InlineAlert>
        )}

        {loading && roles.length === 0 && (
          <InlineAlert intent="none" marginBottom={16}>
            Searching for AWS IAM Roles...
          </InlineAlert>
        )}
        <Combobox
          items={roles}
          itemToString={(item) => (item ? item.label : '')}
          onChange={(selected) => {
            setSelectedRole(selected);
            setLoading(true);
          }}
          disabled={loading}
          placeholder="Select an IAM Role"
          marginBottom={majorScale(2)} />

        {!loading && Object.keys(serviceAccessDetails).length > 0 && (
          <BaseDataField name="AWS Services Access" loading={loading}>
            <Pane>
              <TextInputField
                  label=""
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                  placeholder="Search for permissions..."
                  marginBottom={majorScale(3)} />

              {Object.keys(serviceAccessDetails).length === 0 ? (
                <Text>No access to AWS services for this role.</Text>
              ) : (
                filteredResults(searchQuery).sort((serviceA, serviceB) => {
                  if (serviceA < serviceB) return -1;
                  if (serviceA > serviceB) return 1;
                  return 0;
                }).slice(
                  (currentPage - 1) * 15,
                  (currentPage - 1) * 15 + 15,
                ).map((service) => (
                  <Card
                    key={service}
                    marginY={majorScale(2)}
                    paddingX={majorScale(2)}
                    paddingY={majorScale(1)}
                    backgroundColor="#FFF"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    border="default">
                    <Pane width="100%">
                      <Pane><Strong size={400}>{service}</Strong></Pane>
                      <Pane display="flex" flexWrap="wrap" marginTop={majorScale(1)} maxWidth="100%">
                        {serviceAccessDetails[service].map((action) => (
                          <Badge
                            key={action}
                            color={searchQuery.length > 0 && action.includes(searchQuery) ? 'yellow' : 'neutral'}
                            marginRight={majorScale(1)}
                            marginBottom={majorScale(1)}>
                            {action}
                          </Badge>
                        ))}
                      </Pane>
                    </Pane>
                  </Card>
                ))
              )}
            </Pane>
            {Object.keys(serviceAccessDetails).length > 15
            && (
              <Pagination
                page={currentPage}
                totalPages={Object.keys(serviceAccessDetails).length / 15}
                onPreviousPage={() => setCurrentPage(currentPage - 1)}
                onNextPage={() => setCurrentPage(currentPage + 1)} />
            )}
          </BaseDataField>
        )}
      </Pane>
    </SectionCard>
  );
};

export default AWSIAMRoleAccess;
