import React from 'react';
import {
  Pane,
  majorScale,
} from 'evergreen-ui';
import { CreatedTicketsChart, ResolvedTicketsChart, TotalCreatedTicketsChart, TotalRegisteredUsersChart, TotalPendingTicketsChart, AverageResolutionTimeChart } from './charts';

const SettingsMetricsPage = () => (
  <Pane>

    <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
      <Pane width="25%" marginRight={majorScale(2)}>
        <TotalCreatedTicketsChart />
      </Pane>
      <Pane width="25%" marginRight={majorScale(2)}>
        <TotalPendingTicketsChart />
      </Pane>
      <Pane width="25%" marginRight={majorScale(2)}>
        <TotalRegisteredUsersChart />
      </Pane>
      <Pane width="25%" marginRight={majorScale(2)}>
        <AverageResolutionTimeChart />
      </Pane>
    </Pane>

    <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
      <Pane width="50%" marginRight={majorScale(2)}>
        <CreatedTicketsChart />
      </Pane>
      <Pane width="50%" marginRight={majorScale(2)}>
        <ResolvedTicketsChart />
      </Pane>
    </Pane>

  </Pane>
);

export default SettingsMetricsPage;
