import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const PritunlAccountDetails = ({ ticket }) => (
  <SectionCard title="Account information">
    <TextDataField
      name="Email"
      value={ticket && ticket.ticket_data.email} />
    <TextDataField
      name="Organization"
      value={ticket && ticket.ticket_data.organization} />
  </SectionCard>
);

PritunlAccountDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
PritunlAccountDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
