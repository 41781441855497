import React, { useState } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';

const GoogleCreateGroupForm = ({ submit }) => {
  const currentUser = useSelector((state) => state.authentication.user);
  const [allowEdit, setAllowEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      group_name: '',
      group_email: '',
      group_description: '',
      owner_email: currentUser.email,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      group_name: Yup.string().required('Name of the new group is required'),
      group_email: Yup.string().required('Email of the new group is required'),
      owner_email: Yup.string().required('Email of the owner is required'),
    }),
    validateOnChange: false,
  });
  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('owner_email', user.email);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Create a new Google group</Heading>
            <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
          </Pane>

          <Pane paddingTop={majorScale(2)}>
            <FormField label="Group Name" validationMessage={errors.group_name} paddingBottom={majorScale(2)}>
              <TextInput
                data-testid="group_name"
                name="group_name"
                onChange={handleChange}
                value={values.group_name}
                isInvalid={errors.group_name && true} />
            </FormField>
            <FormField label="Group Email" validationMessage={errors.group_email} paddingBottom={majorScale(2)}>
              <TextInput
                data-testid="group_email"
                name="group_email"
                onChange={handleChange}
                value={values.group_email}
                isInvalid={errors.group_email && true} />
            </FormField>
            <FormField label="Group Description" validationMessage={errors.group_description} paddingBottom={majorScale(2)}>
              <TextInput
                data-testid="group_description"
                name="group_description"
                onChange={handleChange}
                value={values.group_description}
                isInvalid={errors.group_description && true} />
            </FormField>
            <FormField label="Owner Email"
            validationMessage={errors.owner_email}
            paddingBottom={majorScale(2)}
            hint="If you want to change the owner of the group, click the 'Load user from Google' button">
              <TextInput
                data-testid="owner_email"
                name="owner_email"
                onChange={handleChange}
                value={values.owner_email}
                readOnly={!allowEdit}
                isInvalid={errors.owner_email && true}/>
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default GoogleCreateGroupForm;
