import React from 'react';
import { majorScale, Pane, Heading } from 'evergreen-ui';
import PropTypes from 'prop-types';
import Skeleton from '../skeleton';

const BaseDataField = ({ name, loading, children }) => (
  <Pane marginBottom={majorScale(2)}>
    {loading ? (
      <>
        <Skeleton width="150px" height="14px" />
        <Skeleton width="250px" height="18px" style={{ marginTop: '4px' }} />
      </>
    ) : (
      <>
        <Heading size={400}>{name}</Heading>
        {children}
      </>
    )}
  </Pane>
);

BaseDataField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

BaseDataField.defaultProps = {
  children: undefined,
  loading: false,
};

export default BaseDataField;
