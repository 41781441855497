const formHelp = {
  location: [
    {
      key: 'country',
      title: 'Country',
      required: true,
      description: 'Country this location belongs to.',
    },
    {
      key: 'name',
      title: 'Location name',
      required: true,
      description:
        'Location name. This is the name that you will see when selecting a location.',
    },
    {
      key: 'aliases',
      title: 'Location aliases',
      description:
        'Other ways to name the location. For example, aliases are used when you import a list from Trello or when you filter locations using the search box.',
    },
  ],
  team: [
    {
      key: 'name',
      title: 'Team name',
      required: true,
      description:
        'Team name. This is the name that you will see when selecting a location.',
    },
    {
      key: 'aliases',
      title: 'Team aliases',
      description:
        'Other ways to name the team. For example, aliases are used when you import a list from Trello or when you filter teams using the search box.',
    },
  ],
  user: [
    {
      key: 'role',
      title: 'Role',
      required: false,
      description:
        'The role describes which actions this user is allowed to do.',
    },
    {
      key: 'enabled',
      title: 'Enabled',
      required: false,
      description:
        'Allow or deny the access of this user to the Access Platform.',
    },
  ],
  predefinedEmail: [
    {
      key: 'name',
      title: 'Name',
      required: true,
      description: 'The name for the predefined email',
    },
    {
      key: 'subject',
      title: 'Email subject',
      required: true,
      description: 'The subject of the email that will be sent',
    },
    {
      key: 'template',
      title: 'Template',
      required: true,
      description: 'The template that will be used in this email',
    },
    {
      key: 'template_vars',
      title: 'Template varibales',
      required: false,
      description: 'If the template has variables to render, here is where you specify the values',
    },
    {
      key: 'attachments',
      title: 'Attachments',
      required: false,
      description: 'If you want to send attached files',
    },
  ],
  emailProvider: [
    {
      key: 'vault',
      title: 'Vault',
      required: false,
      description:
        'You must specify the path to a secret that contains the following keys: smtp_host, smtp_port, smtp_username, smtp_password and smtp_sender',
    },
    {
      key: 'environment',
      title: 'Environment variables',
      required: false,
      description:
        'You must export the following environment variables: SMTP_HOST, SMTP_PORT, SMTP_USERNAME, SMTP_PASSWORD, SMTP_SENDER',
    },
  ],
};

export default formHelp;
