import React, { useState, useEffect } from 'react';
import { Combobox } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { api } from '../../services/api';

const SelectGithubUserInput = ({ value, onSelect, disabled }) => {
  const [loading, setLoading] = useState(true);
  const [getUsers, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.github.getUsers();
        setUsers(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Combobox
      items={getUsers.map((user) => user.name)}
      selectedItem={value}
      onChange={(selected) => onSelect && onSelect(selected || null)}
      disabled={disabled}
      isLoading={loading}
      width="40%"
      openOnFocus
      data-testid="User"
    />
  );
};

SelectGithubUserInput.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectGithubUserInput.defaultProps = {
  value: null,
};

export default SelectGithubUserInput;
