import React, { useState, useEffect, useMemo } from 'react';
import {
  Pane, majorScale, TextInput, FormField, toaster, Text, Paragraph, Spinner, FilePicker
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { api } from '../../../../../../services/api';
import BaseSheet from '../../../../../../components/sidesheets/base';
import { CustomSelect } from '../../../../../../components/inputs';
import formHelp from '../../../../../../constants/help';

const CreatePredefinedEmailSheet = ({ visible, close }) => {
  let unmounted = false;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateVarsLoading, setTemplateVarsLoading] = useState(false);
  const [templateVars, setTemplateVars] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const fetchTemplates = async () => {
    setTemplatesLoading(true);
    try {
      const { data } = await api.email.templates.list();
      if (!unmounted) {
        setTemplates(data);
        setTemplatesLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        toaster.danger('There was an error while proccessing your request');
        setTemplatesLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTemplates();

    return () => {
      unmounted = true;
    };
  }, []);

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'This field is required';
    }
    if (!values.subject) {
      errors.subject = 'This field is required';
    }
    if (!values.template) {
      errors.template = 'This field is required';
    }
    if (templateVars.length > 0 && !values.template_values) {
      errors.template_values = 'This field is required';
    }
    return errors;
  };

  async function loadAttachment(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        resolve(event.target.result);
      };
    });
  }

  const submitForm = async (values) => {
    setSubmitLoading(true);
    const data = values;
    data.attachments = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const attachment of attachments) {
      // eslint-disable-next-line no-await-in-loop
      const loadedAttachment = await loadAttachment(attachment);
      data.attachments.push({
        name: attachment.name,
        type: attachment.type,
        size: attachment.size,
        file: loadedAttachment,
      });
    }

    try {
      await api.email.predefined.create(data);
      setSubmitLoading(false);
      toaster.success('Predefined email created successfully');
      close();
    } catch (error) {
      setSubmitLoading(false);
      toaster.danger('There was an error while proccessing your request');
    }
  };

  const formik = useFormik({
    initialValues: {
      name: 'Untiteled predefined email',
      subject: 'New communication',
      template: null,
      template_values: null,
    },
    onSubmit: submitForm,
    validate: validateForm,
    validateOnChange: true,
  });

  const fetchTemplateVars = async (templateId) => {
    setTemplateVarsLoading(true);
    try {
      const { data } = await api.email.templates.details(templateId);
      if (!unmounted) {
        setTemplateVars(data.variables);
        formik.setFieldValue('template_values', null);
        setTemplateVarsLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        toaster.danger('There was an error while proccessing your request');
        setTemplateVarsLoading(false);
      }
    }
  };

  const form = (
    <Pane>
      <FormField
        label="Name"
        maxWidth="50%"
        validationMessage={formik.errors.name}>
        <TextInput
          name="name"
          onChange={formik.handleChange}
          defaultValue={formik.values.name}
          isInvalid={formik.errors.name && true} />
      </FormField>
      <FormField
        label="Email Subject"
        marginTop={majorScale(2)}
        validationMessage={formik.errors.subject}>
        <TextInput
          name="subject"
          width="100%"
          onChange={formik.handleChange}
          defaultValue={formik.values.subject}
          isInvalid={formik.errors.subject && true} />
      </FormField>

      {/* TODO: Use SelectEmailTemplateInput */}
      <FormField
        label="Template"
        maxWidth="50%"
        marginTop={majorScale(2)}
        validationMessage={formik.errors.template}>
        <CustomSelect
          items={templates.map((t) => ({ label: t.name, value: t.id }))}
          disabled={templatesLoading}
          onChange={(selected) => {
            formik.setFieldValue('template', selected ? selected.value : null);
            if (selected) {
              fetchTemplateVars(selected.value);
            }
          }} />
      </FormField>

      <FormField
        label="Template variables"
        maxWidth="75%"
        marginTop={majorScale(2)}
        validationMessage={formik.errors.template_values}>
        {templateVarsLoading && (
          <Pane display="flex" alignItems="center">
            <Spinner size={18} />
            <Text marginLeft={majorScale(1)}>Loading template variables</Text>
          </Pane>
        )}
        {!templateVarsLoading && !formik.values.template && (
          <Text color="muted">Select a template first</Text>
        )}
        {!templateVarsLoading && formik.values.template && templateVars.length < 1 && (
          <Text color="muted">Template does not have variables</Text>
        )}
        {!templateVarsLoading
          && formik.values.template
          && templateVars.length > 0
          && templateVars.map((variable) => (
            <Pane key={variable} display="flex" alignItems="center" marginY={majorScale(1)}>
              <Pane width="50%">
                <Paragraph>{variable}</Paragraph>
              </Pane>
              <Pane width="50%">
                <TextInput
                  name={`template_values.${variable}`}
                  width="100%"
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.template_values && true} />
              </Pane>
            </Pane>
          ))}
      </FormField>

      <FormField
        label="Attachments"
        maxWidth="100%"
        marginTop={majorScale(2)}>
        <FilePicker
          multiple
          width="100%"
          onChange={(files) => setAttachments(files)}
          placeholder="Select the file here!" />
      </FormField>
    </Pane>
  );

  return (
    <BaseSheet.Create
      form={form}
      close={close}
      help={formHelp.predefinedEmail}
      visible={visible}
      submitLoading={submitLoading}
      submitDisabled={!formik.isValid}
      title="Create a predefined email"
      onSubmit={formik.handleSubmit} />
  );
};

CreatePredefinedEmailSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CreatePredefinedEmailSheet;
