import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  IconButton,
  majorScale,
  toaster,
  EditIcon,
} from 'evergreen-ui';
import filter from '../../../assets/scripts/filter';
import EditSheet from './sidesheets/edit';
import SettingsNavigation from '../navigation';
import { searchParams } from '../../../services/history';
import { api } from '../../../services/api';
import appTypes from '../../../constants/types';
import { stickyExpandOnScroll } from '../../../assets/scripts/sticky';

const SettingsFeaturesPage = () => {
  const [searchTerm] = useState('');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [features, setFeatures] = useState([]);
  const setting_name = 'features';

  const fetchData = async () => {
    try {
      const featuresData = await api.settings.get(setting_name);
      setFeatures(featuresData.data);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
    }
  };

  useEffect(() => {
    fetchData();
    stickyExpandOnScroll('-24px');
  }, []);

  const openEditFeatureSheet = (feature) => {
    setSelectedFeature(feature);
    setEditOpen(true);
  };

  const updateSetting = async (feature) => {
    const settingsFeatures = []
    for (const f of features) {
      if (f.name === feature.name) {
        settingsFeatures.push(feature);
      } else {
        settingsFeatures.push(f);
      }
    }
    api.settings.update(setting_name, settingsFeatures);
  };

  return (
    <Pane>
      <SettingsNavigation />
      {filter(searchTerm, features, ['name']).map((feature) => (
        <Feature key={feature.name} feature={feature} edit={openEditFeatureSheet} />
      ))}

      {editOpen && (
        <EditSheet
          submit={updateSetting}
          visible={editOpen}
          feature={selectedFeature}
          close={() => {
            setEditOpen(false);
            searchParams.remove('selected');
          }} />
      )}
    </Pane>
  );
};

const Feature = ({ feature, edit }) => {
  
  const handleEdit = () => {
    edit(feature);
  };
  return (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    backgroundColor="white"
    justifyContent="space-between"
    marginY={majorScale(2)}
    padding={majorScale(2)}
    borderRadius="3px">
    <Text>{feature.label}</Text>

    <IconButton
      icon={EditIcon}
      onClick={handleEdit}
      marginRight={majorScale(2)} />
  </Pane>
  );
};

Feature.propTypes = {
  feature: appTypes.allowedUser.props.isRequired,
};

export default SettingsFeaturesPage;
