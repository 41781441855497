import React from 'react';
import {
  Pane,
  majorScale,
  Heading,
  SideSheet,
  Card,
  Button,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import SheetHelp from '../components/help';
import appTypes from '../../../constants/types';

const Create = ({
  visible,
  close,
  title,
  form,
  onSubmit,
  help,
  submitLoading,
  submitDisabled,
  okText,
}) => (
  <SideSheet
    isShown={visible}
    onCloseComplete={close}
    containerProps={{
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    }}
  >
    <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
      <Pane padding={16}>
        <Heading size={600}>{title}</Heading>
      </Pane>
    </Pane>

    <form style={{ flex: 1, height: '100%' }} onSubmit={onSubmit}>
      <Pane
        flex="1"
        overflowY="scroll"
        background="tint1"
        height="100%"
        padding={16}
      >
        <Card backgroundColor="white" elevation={0} padding={majorScale(2)}>
          {form}
        </Card>

        <Pane
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginY={majorScale(2)}
        >
          <Pane display="flex" justifyContent="flex-end">
            <Button type="button" onClick={close} disabled={submitLoading}>
              Cancel
            </Button>
            <Button
              marginLeft={majorScale(2)}
              appearance="primary"
              type="submit"
              isLoading={submitLoading}
              disabled={submitDisabled}
            >
              {okText}
            </Button>
          </Pane>
        </Pane>

        {help && <SheetHelp help={help} />}
      </Pane>
    </form>
  </SideSheet>
);

Create.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  form: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  help: appTypes.help.props,
  submitLoading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  okText: PropTypes.string,
};

Create.defaultProps = {
  title: '',
  help: [],
  submitLoading: false,
  submitDisabled: false,
  okText: 'Create',
};

export default Create;
