import React from 'react';
import { Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseDataField from './base';

const TextDataField = ({ name, value, loading, emptyMessage }) => (
  <BaseDataField name={name} loading={loading}>
    {value ? (
      <Text>{value}</Text>
    ) : (
      <Text color="muted">{emptyMessage}</Text>
    )}
  </BaseDataField>
);

TextDataField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

TextDataField.defaultProps = {
  value: undefined,
  loading: false,
  emptyMessage: 'Empty',
};

export default TextDataField;
