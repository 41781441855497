import React, { useState } from 'react';
import { Checkbox } from 'evergreen-ui';
import PropTypes from 'prop-types';

const CustomCheckbox = ({ name, label, onCheck, disabled, defaultValue }) => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChange = e => {
    setChecked(e.target.checked);
    if (onCheck) {
      onCheck(e);
    }
  };

  return (
    <Checkbox
      name={name}
      label={label}
      checked={checked}
      disabled={disabled}
      onChange={e => handleChange(e)}
    />
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onCheck: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
};

CustomCheckbox.defaultProps = {
  name: '',
  label: '',
  onCheck: null,
  disabled: false,
  defaultValue: false,
};

export default CustomCheckbox;
