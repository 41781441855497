import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  IconButton,
  majorScale,
  SearchInput,
  toaster,
  EyeOpenIcon,
  Spinner,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import SettingsNavigation from '../navigation';
import DetailsSheet from './sidesheets/details';
import { searchParams } from '../../../services/history';
import { api } from '../../../services/api';
import appTypes from '../../../constants/types';
import { AuthorizedWrapper } from '../../../components/authorized-wrapper';
import { stickyExpandOnScroll } from '../../../assets/scripts/sticky';
import PaginationMenu from '../../../components/pagination-menu';
import NoResults from '../../../components/no-results';

const SettingsRolesPage = () => {
  const [loading, setLoading] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [roles, setRoles] = useState([]);
  const [metadata, setMetadata] = useState({});

  let unmounted = false;
  const fetchData = async () => {
    setLoading(true);
    try {
      const page = searchParams.get('page') || 1;
      const itemsPerPage = searchParams.get('per_page') || 10;
      const { data } = await api.roles.list(page, itemsPerPage, searchTerm);
      if (!unmounted) {
        setRoles(data.data);
        setMetadata(data.metadata);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    stickyExpandOnScroll('-24px');
    const event = setTimeout(() => {
      searchParams.set('search', searchTerm);
      fetchData();
    }, 500);

    return () => {
      clearTimeout(event);
      unmounted = true;
    };
  }, [searchTerm]);

  return (
    <Pane>
      <SettingsNavigation />
      <Pane
        id="jampp-sticky-expand"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={majorScale(2)}
        marginBottom={majorScale(4)}
        elevation={0}
        position="sticky"
        top="-24px"
        backgroundColor="white"
        zIndex="9"
        marginX={-1}>
        <SearchInput
          placeholder="Search user"
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={loading}
          value={searchTerm}
          display="flex"
          alignItems="center"
          maxWidth="50%" />
      </Pane>

      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}>
          <Spinner />
        </Pane>
      )}

      {!loading && roles.length < 1 && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={250}>
          <NoResults
            message="There are no roles to show" />
        </Pane>
      )}

      {!loading && roles.length > 0 && (
        <>
          {roles.map((role) => (
            <Role key={role.id} role={role} openDetails={() => setDetailsOpen(true)} />
          ))}
          <PaginationMenu
            pageMetadata={metadata}
            onPreviousPage={() => fetchData()}
            onNextPage={() => fetchData()}
            onChangePage={() => fetchData()}
            onItemsPerPageChange={() => fetchData()} />
        </>
      )}

      {detailsOpen && (
        <DetailsSheet
          visible={detailsOpen}
          close={() => {
            setDetailsOpen(false);
            searchParams.remove('selected');
          }} />
      )}
    </Pane>
  );
};

const Role = ({ role, openDetails }) => {
  const handleOpenDetails = () => {
    searchParams.set('selected', role.id);
    openDetails();
  };

  return (
    <Pane
      elevation={0}
      display="flex"
      alignItems="center"
      backgroundColor="white"
      justifyContent="space-between"
      marginY={majorScale(2)}
      padding={majorScale(2)}
      borderRadius="3px">
      <Text>{role.name}</Text>

      <Pane display="flex" alignItems="center">
        <AuthorizedWrapper resource="roles" action="details">
          <IconButton
            icon={EyeOpenIcon}
            onClick={handleOpenDetails}
            marginRight={majorScale(2)} />
        </AuthorizedWrapper>
      </Pane>
    </Pane>
  );
};

Role.propTypes = {
  role: appTypes.role.props.isRequired,
  openDetails: PropTypes.func.isRequired,
  // openEditUserSheet: PropTypes.func.isRequired,
};

export default SettingsRolesPage;
