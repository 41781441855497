import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  IconButton,
  EditIcon,
  TrashIcon,
  toaster,
  EyeOpenIcon,
  majorScale,
  SearchInput,
  Button,
  Spinner,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import SettingsNavigation from '../navigation';
import CreateLocationSheet from './sidesheets/create';
import LocationDetailsSheet from './sidesheets/details';
import EditLocationSheet from './sidesheets/edit';
import { api } from '../../../services/api';
import { searchParams } from '../../../services/history';
import DeletePopConfirm from '../../../components/popconfirm/delete';
import appTypes from '../../../constants/types';
import { AuthorizedWrapper } from '../../../components/authorized-wrapper';
import { stickyExpandOnScroll } from '../../../assets/scripts/sticky';
import PaginationMenu from '../../../components/pagination-menu';
import NoResults from '../../../components/no-results';

const SettingsLocationsPage = () => {
  const [loading, setLoading] = useState(true);
  const [newOpen, setNewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [locations, setLocations] = useState([]);
  const [metadata, setMetadata] = useState({});

  const fetchData = async () => {
    try {
      const page = searchParams.get('page') || 1;
      const itemsPerPage = searchParams.get('per_page') || 10;
      const { data } = await api.locations.list(page, itemsPerPage, searchTerm);
      setLocations(data.data);
      setMetadata(data.metadata);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    stickyExpandOnScroll('-24px');
    const event = setTimeout(() => {
      searchParams.set('search', searchTerm);
      fetchData();
    }, 500);

    return () => clearTimeout(event);
  }, [searchTerm, newOpen, editOpen]); // TODO: Refresh only on close

  const deleteLocation = async (id) => {
    try {
      await api.locations.delete(id);
      fetchData();
    } catch (error) {
      toaster.danger('ERROR');
    }
  };

  return (
    <Pane>
      <SettingsNavigation />
      <Pane
        id="jampp-sticky-expand"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={majorScale(2)}
        marginBottom={majorScale(4)}
        elevation={0}
        position="sticky"
        top="-24px"
        backgroundColor="white"
        zIndex="9"
        marginX={-1}>
        <SearchInput
          placeholder="Search team"
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={loading}
          value={searchTerm}
          display="flex"
          alignItems="center"
          maxWidth="50%" />

        <Pane display="flex" alignItems="center">
          <AuthorizedWrapper resource="locations" action="create">
            <Button
              appearance="primary"
              onClick={() => setNewOpen(true)}
              disabled={loading}>
              Create new location
            </Button>
          </AuthorizedWrapper>
        </Pane>
      </Pane>

      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}>
          <Spinner />
        </Pane>
      )}

      {!loading && locations.length < 1 && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={250}>
          <NoResults
            message="There are no locations to show"
            buttonText="Create a new location"
            buttonAction={() => setNewOpen(true)} />
        </Pane>
      )}

      {!loading && locations.length > 0 && (
        <>
          {locations.map((location) => (
            <Location
              key={location.id}
              location={location}
              openDetails={() => setDetailsOpen(true)}
              openEdit={() => setEditOpen(true)}
              deleteLocation={deleteLocation} />
          ))}
          <PaginationMenu
            pageMetadata={metadata}
            onPreviousPage={() => fetchData()}
            onNextPage={() => fetchData()}
            onChangePage={() => fetchData()}
            onItemsPerPageChange={() => fetchData()} />
        </>
      )}

      {detailsOpen && (
        <LocationDetailsSheet
          visible={detailsOpen}
          close={() => {
            setDetailsOpen(false);
            searchParams.remove('selected');
          }} />
      )}

      {newOpen && (
        <CreateLocationSheet
          visible={newOpen}
          close={() => setNewOpen(false)} />
      )}

      {editOpen && (
        <EditLocationSheet
          visible={editOpen}
          close={() => {
            setEditOpen(false);
            searchParams.remove('selected');
          }} />
      )}
    </Pane>
  );
};

const Location = ({
  location, openDetails, openEdit, deleteLocation,
}) => {
  const handleOpenDetails = () => {
    searchParams.set('selected', location.id);
    openDetails();
  };

  const handleEdit = () => {
    searchParams.set('selected', location.id);
    openEdit();
  };

  const handleDelete = () => {
    deleteLocation(location.id);
  };

  return (
    <Pane
      elevation={0}
      display="flex"
      alignItems="center"
      backgroundColor="white"
      justifyContent="space-between"
      marginY={majorScale(2)}
      padding={majorScale(2)}
      borderRadius="3px">
      <Text>
        <ReactCountryFlag
          countryCode={location.country_code}
          style={{ fontSize: '24px' }} />
        {' '}
        {location.name}
      </Text>

      <Pane display="flex" alignItems="center">
        <AuthorizedWrapper resource="locations" action="details">
          <IconButton
            icon={EyeOpenIcon}
            onClick={handleOpenDetails}
            marginRight={majorScale(2)} />
        </AuthorizedWrapper>
        <AuthorizedWrapper resource="locations" action="update">
          <IconButton
            icon={EditIcon}
            onClick={handleEdit}
            marginRight={majorScale(2)} />
        </AuthorizedWrapper>
        <AuthorizedWrapper resource="locations" action="delete">
          <DeletePopConfirm onConfirm={handleDelete}>
            <IconButton
              icon={TrashIcon}
              intent="danger" />
          </DeletePopConfirm>
        </AuthorizedWrapper>
      </Pane>
    </Pane>
  );
};

Location.propTypes = {
  location: appTypes.location.props.isRequired,
  openDetails: PropTypes.func.isRequired,
  openEdit: PropTypes.func.isRequired,
  deleteLocation: PropTypes.func.isRequired,
};

export default SettingsLocationsPage;
