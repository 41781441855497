import React, { useState, useEffect } from 'react';
import {
  Button,
  Code,
  Dialog,
  majorScale,
  minorScale, Pane,
  Pre,
  ShareIcon,
  toaster,
} from 'evergreen-ui';
import { useParams } from 'react-router-dom';
import Skeleton from '../skeleton';
import { api } from '../../services/api';

const ExecutionLogsDialog = () => {
  const { id } = useParams();
  const [dialogOpened, setDialogOpened] = useState(false);

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  let unmounted = false;

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.tickets.viewLogs(id);
      if (!unmounted) {
        setLogs(data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (dialogOpened && logs.length < 1) {
      fetchData();
    }
    return () => {
      unmounted = true;
    };
  }, [dialogOpened]);

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <Button
        type="button"
        size="small"
        // appearance="minimal"
        iconAfter={ShareIcon}
        marginTop={minorScale(1)}
        onClick={() => {
          setDialogOpened(true);
        }}>
        View logs
      </Button>

      <Dialog
        isShown={dialogOpened}
        title="Execution logs"
        hasFooter={false}
        onCloseComplete={() => setDialogOpened(false)}
        onCancel={(close) => onCancel(close)}
        width="50%"
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
          paddingBottom: majorScale(4),
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <Pane backgroundColor="#474d66" padding={majorScale(1)} borderRadius="3px" overflowX="scroll">
          {loading
            ? (
              <>
                <Skeleton width="100%" height="16px" style={{ marginBottom: '4px' }} />
                <Skeleton width="50%" height="16px" style={{ marginBottom: '4px' }} />
                <Skeleton width="80%" height="16px" style={{ marginBottom: '4px' }} />
                <Skeleton width="25%" height="16px" style={{ marginBottom: '4px' }} />
                <Skeleton width="75%" height="16px" style={{ marginBottom: '4px' }} />
              </>
            ) : (
              <>
                {logs.map((log) => (
                  <Pre key={log.id}>
                    <Code appearance="minimal" color="white">
                      {log.date}
                      {' '}
                      -
                      {' '}
                      {log.loglevel}
                      :
                      {' '}
                      {log.message}
                    </Code>
                  </Pre>
                ))}
              </>
            )}
        </Pane>

      </Dialog>
    </>
  );
};

export default ExecutionLogsDialog;
