import React, { useState, useRef, useEffect } from 'react';
import {
  Position,
  SideSheet,
  Pane,
  IconButton,
  CrossIcon,
  Tooltip,
  FloppyDiskIcon,
  majorScale,
  toaster,
} from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import EmailEditor from 'react-email-editor';
import appTypes from '../../constants/types';

const EmailEditorSheet = ({ visible, close, template, save }) => {
  const emailEditorRef = useRef(null);
  const [saveLoading, setSaveLoading] = useState(false);

  const onReady = (editor) => {
    if (template) {
      editor.loadDesign(template.design);
    }
  };

  const saveTemplate = (notify = false) => {
    const editor = emailEditorRef.current?.editor;

    editor?.exportHtml(async (data) => {
      setSaveLoading(true);
      await save(data);
      setSaveLoading(false);

      if (notify) {
        toaster.success('Changes saved successfully');
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      saveTemplate(false);
    }, 60000);

    return () => clearInterval(interval);
  });

  return (
    <SideSheet
      width="100%"
      position={Position.RIGHT}
      isShown={visible}
      preventBodyScrolling
      shouldCloseOnEscapePress={false}
      shouldCloseOnOverlayClick={false}
      onCloseComplete={close}>
      <Pane height="calc(100vh - 3px)" display="flex">
        <Pane paddingX={majorScale(1)} paddingY={majorScale(2)} display="flex" flexDirection="column">
          <Tooltip content="Close editor">
            <IconButton icon={CrossIcon} onClick={close} disabled={saveLoading} />
          </Tooltip>
          <Tooltip content="Save template">
            <IconButton
              icon={FloppyDiskIcon}
              marginTop={majorScale(1)}
              disabled={saveLoading}
              onClick={() => saveTemplate(true)} />
          </Tooltip>
        </Pane>
        <Pane display="flex" width="100%" id="email-editor">
          <EmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            minHeight="100%"
            options={{
              displayMode: 'email',
              safeHTML: true,
              appearance: {
                loader: {
                  html: '<span>Loading...</span>',
                },
              },
              tools: {
                menu: { enabled: false },
                html: { enabled: false },
              },
            }} />
        </Pane>
      </Pane>
    </SideSheet>
  );
};

EmailEditorSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  template: appTypes.emailTemplate.isRequired,
  save: PropTypes.func.isRequired,
};

export default EmailEditorSheet;
