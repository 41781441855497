import React, { useState, useEffect } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, toaster, InlineAlert, Combobox } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../../../services/api';

const ResetIAMUserPasswordForm = ({ submit }) => {
  const [loading, setLoading] = useState(false);
  const [iamUsers, setIAMUsers] = useState([]);

  const formik = useFormik({
    initialValues: {
      iam_user: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      iam_user: Yup.string().required('IAM User required'),
    }),
    validateOnChange: false,
  });

  let unmounted = false;

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.aws.listIAMUsers();
      if (!unmounted) {
        setIAMUsers(data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS IAM users.');
      setLoading(false);
    } 
  }

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);


  const { errors } = formik;

  const transformedIAMUsers = iamUsers.map(user => ({ label: user, value: user }));
  
  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Reset IAM User Password</Heading>
          </Pane>
          {loading && (
            <InlineAlert intent="none" marginBottom={16} marginTop={20}>
              We are computing all the IAM users in our account.
              This usually takes a few seconds, please do not close the window.
            </InlineAlert>
          )}
          <FormField
            validationMessage={errors.iam_user}
            width="100%"
            label="IAM User"
            marginTop={majorScale(2)}>
            <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
            <Combobox
              items={transformedIAMUsers}
              itemToString={item => (item ? item.label : '')}
              onChange={selected => {
                formik.setFieldValue('iam_user', selected.value);
              }}
              disabled={loading}
              placeholder="Select an IAM User"
            />
            </Pane>
          </FormField>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border="default"
          marginTop={majorScale(2)}>
          <Button type="button" disabled={formik.isSubmitting} marginRight={majorScale(2)}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
}  

export default ResetIAMUserPasswordForm;
