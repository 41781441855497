import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Skeleton = ({ width, height, style }) => (
  <div className="jampp__skeleton" style={{ width, height, ...style }} />
);

export default Skeleton;

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Skeleton.defaultProps = {
  width: '100%',
  height: '100%',
  style: {},
};
