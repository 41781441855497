import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const CreateNewRepoDetails = ({ ticket }) => (
  <SectionCard title="Repository Information">
    <TextDataField
      name="Repo name"
      value={ticket && ticket.ticket_data.new_repo} />
    <TextDataField
      name="Repo visibility"
      value={ticket && ticket.ticket_data.repo_visibility} />
  </SectionCard>
);

CreateNewRepoDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
CreateNewRepoDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
