import React, { useState } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput, Checkbox } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectGithubRepositoryDialog } from '../../../../../components/dialogs';
import { SelectGithubUserInput } from '../../../../../components/inputs';

const AddUserToRepoForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_name: null,
      repo_name: 'ansible',
    },
    onSubmit: (values) => {
      submit(values);
    },
    validationSchema: Yup.object().shape({
      user_name: Yup.string().nullable().required('Name of the user is required'),
      repo_name: Yup.string().nullable().required('Name of the repository is required'),
    }),
    validateOnChange: true,
  });

  const { values, errors } = formik;

  const selectGithubRepository = (githubRepositories) => {
    if (githubRepositories) {
      formik.setFieldValue('repo_name', githubRepositories.name);
    }
  };

  const selectGithubUser = (githubUsers) => {
    if (githubUsers) {
      formik.setFieldValue('user_name', githubUsers);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Checkbox
            label="Enter name of the repository manually"
            checked={allowEdit}
            onChange={(e) => {
              setAllowEdit(e.target.checked);
            }}
          />
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5"
          >
            <Pane>
              <Heading size={600}>Add user to a repository</Heading>
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)}>
            <Pane paddingBottom={majorScale(1)}>
              <FormField
                label="User"
                marginTop={majorScale(1)}
                maxWidth="40%"
                validationMessage={formik.errors.user_name}
              >
                <SelectGithubUserInput value={formik.values.user_name} onSelect={selectGithubUser} />
              </FormField>
              <FormField label="Repository Name" validationMessage={errors.repo_name} paddingTop={majorScale(1)}>
                <Pane display="flex" alignItems="center">
                  <TextInput
                    value={values.repo_name}
                    readOnly={!allowEdit}
                    width={120}
                    marginRight={majorScale(1)}
                    onChange={(e) => formik.setFieldValue('repo_name', e.target.value)}
                  />
                  <SelectGithubRepositoryDialog onSelect={selectGithubRepository} />
                </Pane>
              </FormField>
            </Pane>
          </Pane>
        </Card>

        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border
        >
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}
          >
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default AddUserToRepoForm;
