import React, { useState, useEffect } from 'react';
import {
  Pane,
  majorScale,
  Heading,
  toaster,
} from 'evergreen-ui';
import { useSearchParams } from 'react-router-dom';
import { api } from '../../../services/api';
import { NewJiraAccountForm, NewSilverAccountForm, SnipeCheckoutAssetForm, SnipeCheckinAssetForm, NewVPNAccountForm, OnboardingForm, OffboardingForm, NewADUser, ChangeAccessPlatformRole, InviteGithubUserForm, AddNewSshKeyForm, AddUserToTeamForm, CreateNewRepoForm, AddTeamToRepoForm, AddUserToRepoForm, NewEC2SandboxForm, ModifyEC2SandboxForm, NewIAMUserForm, NewIAMRoleForm, TemporaryAccessSSHForm, ResetIAMUserPasswordForm, CreateMySQLUserForm, GrantMySQLUserPrivilegesForm, GoogleCreateGroupForm, GoogleDeleteGroupForm, GoogleAddMemberToGroupForm, GoogleRemoveMemberFromGroupForm } from './forms';
import activeDirectoryLogo from '../../../assets/images/active-directory-logo.svg';
import awsLogo from '../../../assets/images/aws-logo.png';
import githubLogo from '../../../assets/images/github-logo.svg';
import googleLogo from '../../../assets/images/google-logo.svg';
import mysqlLogo from '../../../assets/images/mysql-logo.svg';

const ticketTypes = [
  {
    name: 'Access Platform',
    tickets: [
      {
        title: 'Role change',
        type: 'access_platform_role_change',
        icon: 'https://uploads-ssl.webflow.com/60ccf8130541d43f4836b8ef/60ccf8130541d4c34c36b940_webclip.png',
      },
    ],
  },
  {
    name: 'Active Directory',
    tickets: [
      {
        title: 'New user',
        type: 'active_directory_user',
        icon: activeDirectoryLogo,
      },
    ],
  },
  {
    name: 'Amazon Web Services',
    tickets: [
      {
        title: 'New EC2 Sandbox',
        type: 'new_sandbox_ec2',
        icon: awsLogo,
      },
      {
        title: 'Modify EC2 Sandbox',
        type: 'modify_sandbox_ec2',
        icon: awsLogo,
      },
      {
        title: 'New IAM User',
        type: 'new_iam_user',
        icon: awsLogo,
      },
      {
        title: 'New IAM Role',
        type: 'new_iam_role',
        icon: awsLogo,
      },
      {
        title: 'Temporary SSH access',
        type: 'temporary_ssh_access',
        icon: awsLogo,
      },
      {
        title: 'Reset IAM User password',
        type: 'reset_iam_user_password',
        icon: awsLogo,
      },
    ],
  },
  {
    name: 'Github',
    tickets: [
      {
        title: 'Invite to organization',
        type: 'github_invite_user',
        icon: githubLogo,
      },
      {
        title: 'Add new SSH Key',
        type: 'github_new_ssh_key',
        icon: githubLogo,
      },
      {
        title: 'Add team to repository',
        type: 'github_add_team_to_repo',
        icon: githubLogo,
      },
      {
        title: 'Add user to repository',
        type: 'github_add_user_to_repo',
        icon: githubLogo,
      },
      {
        title: 'Add user to team',
        type: 'github_add_user_to_team',
        icon: githubLogo,
      },
      {
        title: 'Create new Github repository',
        type: 'github_new_repo',
        icon: githubLogo,
      },
    ],
  },
  {
    name: 'Google',
    tickets: [
      {
        title: 'Create Group',
        type: 'google_create_group',
        icon: googleLogo,
      },
      {
        title: 'Delete Group',
        type: 'google_delete_group',
        icon: googleLogo,
      },
      {
        title: 'Add member to group',
        type: 'google_add_member_to_group',
        icon: googleLogo,
      },
      {
        title: 'Remove member from group',
        type: 'google_remove_member_from_group',
        icon: googleLogo,
      },
    ],
  },
  {
    name: 'Jira',
    tickets: [
      {
        title: 'New Jira account',
        type: 'jira_account',
        icon: 'https://cdn.worldvectorlogo.com/logos/jira-1.svg',
      },
    ],
  },
  {
    name: 'Onboarding/Offboarding',
    tickets: [
      {
        title: 'New onboarding',
        type: 'onboarding',
        icon: 'https://cdn-icons-png.flaticon.com/512/1556/1556262.png',
      },
      {
        title: 'New offboarding',
        type: 'offboarding',
        icon: 'https://cdn-icons-png.flaticon.com/512/1556/1556262.png',
      },
    ],
  },
  {
    name: 'RDS',
    tickets: [
      {
        title: 'Create new MySQL user',
        type: 'create_mysql_user',
        icon: mysqlLogo,
      },
      {
        title: 'Grant privileges to a MySQL user',
        type: 'grant_mysql_user_privileges',
        icon: mysqlLogo,
      },
    ]
  },
  {
    name: 'Ruby/Silver',
    tickets: [
      {
        title: 'New Silver account',
        type: 'silver_account',
        icon: 'https://uploads-ssl.webflow.com/60ccf8130541d43f4836b8ef/60ccf8130541d4c34c36b940_webclip.png',
      },
    ],
  },
  {
    name: 'Snipe',
    tickets: [
      {
        title: 'Checkout Asset',
        type: 'snipe_checkout',
        icon: 'https://uploads-ssl.webflow.com/60ccf8130541d43f4836b8ef/60ccf8130541d4c34c36b940_webclip.png',
      },
      {
        title: 'Checkin Asset',
        type: 'snipe_checkin',
        icon: 'https://uploads-ssl.webflow.com/60ccf8130541d43f4836b8ef/60ccf8130541d4c34c36b940_webclip.png',
      },
    ],
  },
  {
    name: 'VPN Staff',
    tickets: [
      {
        title: 'New VPN credentials',
        type: 'pritunl_account',
        icon: 'https://cdn.worldvectorlogo.com/logos/openvpn-2.svg',
      },
    ],
  },
];

const TicketsCreatePage = () => {
  const [loading, setLoading] = useState(true);
  const [enabledTickets, setEnabledTickets] = useState([]);
  const [disabledTickets, setDisabledTickets] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  let unmounted = false;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.settings.get('features');
      const features = response.data;
      if (!unmounted) {
        // eslint-disable-next-line no-underscore-dangle
        const _disabledTickets = features.filter((f) => !f.enabled).map((f) => f.name);
        setDisabledTickets(_disabledTickets);

        const result = [];
        ticketTypes.forEach((group) => {
          // eslint-disable-next-line no-underscore-dangle
          const _enabledTickets = [];
          group.tickets.forEach((ticket) => {
            if (!_disabledTickets.includes(ticket.type)) {
              _enabledTickets.push(ticket);
            }
          });
          if (_enabledTickets.length > 0) {
            result.push({
              name: group.name,
              tickets: _enabledTickets,
            });
          }
        });

        setEnabledTickets(result);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);
  
  const handleSubmit = async (values) => {
    try {
      const { data } = await api.tickets.create({
        ...values,
        type: searchParams.get('type'),
      });
      toaster.success('Ticket created successfully', {
        description:
          'You will get notified via e-mail when the process finishes',
        duration: 5,
      });
      window.location.pathname = `/tickets/${data.id}`;
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
    }
  };

  if (!searchParams.get('type')) {
    return (
      <Pane>
        {!loading && enabledTickets.map((group) => (
          <Pane key={group.name}>
            <Heading size={700} marginBottom={majorScale(2)}>{group.name}</Heading>
            <Pane display="grid" gap={majorScale(2)} marginBottom={majorScale(2)} gridTemplateColumns="repeat(5, 1fr)">
              {group.tickets.map((ticket) => (
                <TicketType
                  key={ticket.title}
                  type={ticket}
                  setType={(type) => setSearchParams({ type })}
                  isEnabled />
              ))}
            </Pane>
          </Pane>
        ))}
      </Pane>
    );
  }
  // TODO test this out
  switch (searchParams.get('type')) {
    case 'onboarding':
      if (!disabledTickets.includes('onboarding')) {
        return <OnboardingForm submit={handleSubmit} />;
      }
      break;
    case 'offboarding':
      if (!disabledTickets.includes('offboarding')) {
        return <OffboardingForm submit={handleSubmit} />;
      }
      break;
    case 'jira_account':
      if (!disabledTickets.includes('jira_account')) {
        return <NewJiraAccountForm submit={handleSubmit} />;
      }
      break;
    case 'pritunl_account':
      if (!disabledTickets.includes('pritunl_account')) {
        return <NewVPNAccountForm submit={handleSubmit} />;
      }
      break;
    case 'silver_account':
      if (!disabledTickets.includes('silver_account')) {
        return <NewSilverAccountForm submit={handleSubmit} />;
      }
      break;
    case 'snipe_checkout':
      if (!disabledTickets.includes('snipe_checkout')) {
        return <SnipeCheckoutAssetForm submit={handleSubmit} />;
      }
      break;
    case 'snipe_checkin':
      if (!disabledTickets.includes('snipe_checkin')) {
        return <SnipeCheckinAssetForm submit={handleSubmit} />;
      }
      break;
    case 'active_directory_user':
      if (!disabledTickets.includes('active_directory_user')) {
        return <NewADUser submit={handleSubmit} />;
      }
      break;
    case 'access_platform_role_change':
      if (!disabledTickets.includes('access_platform_role_change')) {
        return <ChangeAccessPlatformRole submit={handleSubmit} />;
      }
      break;
    case 'new_sandbox_ec2':
      if (!disabledTickets.includes('new_sandbox_ec2')) {
        return <NewEC2SandboxForm submit={handleSubmit} />;
      }
      break;
    case 'modify_sandbox_ec2':
      if (!disabledTickets.includes('modify_sandbox_ec2')) {
        return <ModifyEC2SandboxForm submit={handleSubmit} />;
      }
      break;
    case 'new_iam_user':
      if (!disabledTickets.includes('new_iam_user')) {
        return <NewIAMUserForm submit={handleSubmit} />;
      }
      break;
    case 'new_iam_role':
      if (!disabledTickets.includes('new_iam_role')) {
        return <NewIAMRoleForm submit={handleSubmit} />;
      }
      break;
    case 'temporary_ssh_access':
      if (!disabledTickets.includes('temporary_ssh_access')) {
        return <TemporaryAccessSSHForm submit={handleSubmit} />;
      }
      break;
    case 'reset_iam_user_password':
      if (!disabledTickets.includes('reset_iam_user_password')) {
        return <ResetIAMUserPasswordForm submit={handleSubmit} />;
      }
      break;
    case 'create_mysql_user':
      if (!disabledTickets.includes('create_mysql_user')) {
        return <CreateMySQLUserForm submit={handleSubmit} />;
      }
      break;
    case 'grant_mysql_user_privileges':
      if (!disabledTickets.includes('grant_mysql_user_privileges')) {
        return <GrantMySQLUserPrivilegesForm submit={handleSubmit} />;
      }
      break;
    case 'github_invite_user':
      if (!disabledTickets.includes('github_invite_user')) {
        return <InviteGithubUserForm submit={handleSubmit} />;
      }
      break;
    case 'github_new_repo':
      if (!disabledTickets.includes('github_new_repo')) {
        return <CreateNewRepoForm submit={handleSubmit} />;
      }
      break;
    case 'github_new_ssh_key':
      if (!disabledTickets.includes('github_new_ssh_key')) {
        return <AddNewSshKeyForm submit={handleSubmit} />;
      }
      break;
    case 'github_add_team_to_repo':
      if (!disabledTickets.includes('github_add_team_to_repo')) {
        return <AddTeamToRepoForm submit={handleSubmit} />;
      }
      break;
    case 'github_add_user_to_repo':
      if (!disabledTickets.includes('github_add_user_to_repo')) {
        return <AddUserToRepoForm submit={handleSubmit} />;
      }
      break;
    case 'github_add_user_to_team':
      if (!disabledTickets.includes('github_add_user_to_team')) {
        return <AddUserToTeamForm submit={handleSubmit} />;
      }
      break;
    case 'google_create_group':
      if (!disabledTickets.includes('google_create_group')) {
        return <GoogleCreateGroupForm submit={handleSubmit} />;
      }
      break;
    case 'google_delete_group':
      if (!disabledTickets.includes('google_delete_group')) {
        return <GoogleDeleteGroupForm submit={handleSubmit} />;
      }
      break;
    case 'google_add_member_to_group':
      if (!disabledTickets.includes('google_add_member_to_group')) {
        return <GoogleAddMemberToGroupForm submit={handleSubmit} />;
      }
      break;
    case 'google_remove_member_from_group':
      if (!disabledTickets.includes('google_remove_member_from_group')) {
        return <GoogleRemoveMemberFromGroupForm submit={handleSubmit} />;
      }
      break;
    default:
      return <Pane />;
  }
};
const TicketType = ({ type, setType, isEnabled }) => {
  
  return (
    <Pane
      opacity={isEnabled ? 1 : 0.5}
      elevation={0}
      hoverElevation={isEnabled ? 1 : null}
      activeElevation={0}
      cursor={isEnabled ? 'pointer' : 'auto'}
      textAlign="center"
      background="white"
      padding={majorScale(2)}
      borderRadius="3px"
      onClick={() => {
        if (isEnabled) {
          setType(type.type);
        }
      }}
      disabled={!isEnabled}
    >
      <img src={type.icon} alt={type.title} height={48}/>
      <div>
        <Heading size={400} marginTop={majorScale(2)}>
          {type.title}
        </Heading>
      </div>
    </Pane>
  );
};

export default TicketsCreatePage;
