import React from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  Position,
  Button,
  Pane,
  Text,
  majorScale,
} from 'evergreen-ui';

const DeletePopConfirm = ({ children, onConfirm }) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const Content = ({ close }) => (
    <Pane paddingY={majorScale(2)} paddingX={majorScale(3)}>
      <Pane>
        <Text>Are you sure you want to delete this resource?</Text>
      </Pane>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        marginTop={majorScale(2)}
      >
        <Button marginRight={majorScale(2)} intent="none" onClick={close}>
          Cancel
        </Button>
        <Button appearance="primary" intent="danger" onClick={handleConfirm}>
          Delete
        </Button>
      </Pane>
    </Pane>
  );

  Content.propTypes = {
    close: PropTypes.func.isRequired,
  };

  return (
    <Popover
      content={({ close }) => <Content close={close} />}
      position={Position.LEFT}
    >
      {children}
    </Popover>
  );
};

DeletePopConfirm.propTypes = {
  children: PropTypes.element.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeletePopConfirm;
