import React from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, Text, TextInput } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';

const OffboardingForm = ({ submit }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      manager_email: '',
      datetime: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Must be a valid email'),
      manager_email: Yup.string().required('Manager\'s email is required').email('Must be a valid email'),
      datetime: Yup.string().required('Date is required'),
    }),
    validateOnChange: false,
  });

  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user, field) => {
    if (user) {
      formik.setFieldValue(field, user.email);
    }
  };
  

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>User Data</Heading>
            </Pane>
          </Pane>

          <Pane marginTop={majorScale(1)}>
            <FormField label="Employee email" validationMessage={errors.email}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextInput
                  data-testid="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  isInvalid={errors.email && true}
                />
                <div style={{ marginLeft: '10px' }}>
                  <LoadUserFromGoogleDialog onSelect={(user) => loadGoogleUser(user, 'email')} />
                </div>

              </div>
            </FormField>
            <FormField
              marginTop={majorScale(2)}
              paddingBottom={majorScale(1)}
              label="Manager Email"
              validationMessage={errors.manager_email}
              hint="This form will try to find all the accounts associated with the email entered above. Once the ticket is approved, it will deactivate them.">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextInput
                  data-testid="manager_email"
                  name="manager_email"
                  onChange={handleChange}
                  value={values.manager_email}
                  isInvalid={errors.manager_email && true}
                />
                <div style={{ marginLeft: '10px' }}>
                  <LoadUserFromGoogleDialog onSelect={(user) => loadGoogleUser(user, 'manager_email')} />
                </div>

              </div>
            </FormField>

            <FormField
              marginTop={majorScale(2)}
              paddingBottom={majorScale(1)}
              label="Offboarding Date & Time"
              validationMessage={errors.datetime}
              hint="Please select the date and time when the offboarding should be run.">
              <TextInput
                data-testid="datetime"
                name="datetime"
                type="datetime-local"
                onChange={handleChange}
                value={values.datetime}
                isInvalid={errors.datetime && true}
              />
            </FormField>
          </Pane>
        </Card>

        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default OffboardingForm;
