import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const NewIAMUserDetails = ({ ticket }) => (
  <SectionCard title="User information">
    <TextDataField
      name="Name"
      value={ticket && ticket.ticket_data.user_name} />
    <TextDataField
      name="Email"
      value={ticket && ticket.ticket_data.user_email} />
  </SectionCard>
);

NewIAMUserDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
NewIAMUserDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
