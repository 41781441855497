import React from 'react';
import PropTypes from 'prop-types';
import { Pane, majorScale, Card, Text } from 'evergreen-ui';

const AccountDataField = ({ image, name }) => (
  <Card
    marginY={majorScale(2)}
    padding={majorScale(2)}
    backgroundColor="#FFF"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    border="default"
  >
    <Pane display="flex" alignItems="center">
      <img
        style={{ maxWidth: '72px', maxHeight: '32px' }}
        src={image}
        alt={name}
      />
      <Pane marginLeft={majorScale(2)}>
        <Pane>
          <Text>{name}</Text>
        </Pane>
      </Pane>
    </Pane>
  </Card>
);

export default AccountDataField;

AccountDataField.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
