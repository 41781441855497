import React, { useState } from 'react';
import { TagInput } from 'evergreen-ui';
import PropTypes from 'prop-types';

const CustomTagInput = ({ disabled, onChange, defaultValue }) => {
  const [values, setValues] = useState(defaultValue);

  const handleChange = (v) => {
    setValues(v);
    if (onChange) {
      onChange(v);
    }
  };

  return (
    <TagInput
      values={values}
      separator=","
      disabled={disabled}
      width="100%"
      tagSubmitKey="space"
      addOnBlur
      onChange={handleChange} />
  );
};

CustomTagInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
};

CustomTagInput.defaultProps = {
  disabled: false,
  onChange: null,
  defaultValue: [],
};

export default CustomTagInput;
