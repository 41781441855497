/* eslint-disable no-nested-ternary */
import React from 'react';
import { Pane, majorScale, Heading, SideSheet, Card } from 'evergreen-ui';
import PropTypes from 'prop-types';
import Skeleton from '../../skeleton';
import appTypes from '../../../constants/types';
import { DatesDataField, UserDataField } from '../../data-fields';

const Details = ({
  visible,
  close,
  title,
  children,
  extraCards,
  loading,
  creator,
  created,
  updated,
}) => (
  <SideSheet
    isShown={visible}
    onCloseComplete={close}
    containerProps={{
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    }}
  >
    <Pane padding={16}>
      {loading ? (
        <Skeleton width="200px" height="24px" />
      ) : (
        <Heading size={600}>{title}</Heading>
      )}
    </Pane>

    <Pane
      flex="1"
      overflowY="scroll"
      background="tint1"
      height="100%"
      padding={16}>
      {/* TODO: Pass all content using "children" or interate over a param named "cards" */}
      <Card backgroundColor="white" elevation={0} padding={majorScale(2)}>
        {children}
      </Card>

      {extraCards}

      {/* Creator and dates */}
      {(creator || (created && updated))
        && (
          <Card
            backgroundColor="white"
            elevation={0}
            padding={majorScale(2)}
            marginTop={majorScale(2)}>
            <UserDataField
              name="Created by"
              loading={loading}
              userName={creator && creator.name} />
            <DatesDataField created={created} updated={updated} loading={loading} />
          </Card>
        )}
    </Pane>
  </SideSheet>
);

Details.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  extraCards: PropTypes.node,
  loading: PropTypes.bool,
  creator: appTypes.allowedUser.props,
  created: PropTypes.string,
  updated: PropTypes.string,
};

Details.defaultProps = {
  title: '',
  loading: false,
  creator: null,
  created: null,
  updated: null,
};

export default Details;
