import axios from 'axios';

const apiConn = axios.create({
  baseURL: '/api/v1',
  withCredentials: true,
  headers: {
    'X-Frontend-Client': true,
  },
});

export const api = {

  aws: {
    listRegions: () => apiConn.get('/services/aws/regions'),
    listRegionsWhereEC2InstancesExist: () => apiConn.get('/services/aws/regions_with_ec2_instances'),
    listEC2InstancesInRegion: (region) => apiConn.get('/services/aws/ec2_instance_in_region', { params: { region } }),
    listEC2InstanceTypes: (region = 'us-east-1') => apiConn.get(`/services/aws/ec2_instance_types?region=${region}`),
    listEC2SandboxInstances: (region = 'us-east-1') => apiConn.get(`/services/aws/ec2_sandbox_instances?region=${region}`),
    computeIAMUserAccess: (name) => apiConn.get('/services/aws/iam_user_access', { params: { name } }),
    computeIAMRoleAccess: (name) => apiConn.get('/services/aws/iam_role_access', { params: { name } }),
    listRoles: () => apiConn.get('/services/aws/roles'),
    listIAMUsers: () => apiConn.get('/services/aws/iam_users'),
    listRegionsWhereMySQLInstancesExist: () => apiConn.get('/services/aws/rds/mysql/regions_with_instances'),
    listRDSInstancesInRegion: (region) => apiConn.get('/services/aws/rds/list', { params: { region } }),
    listMySQLUsers: (db_identifier, region) => apiConn.get('/services/aws/rds/mysql/list_users', { params: { db_identifier, region } }),
    listMySQLDatabasesOnRDS: (db_identifier, region) => apiConn.get('/services/aws/rds/mysql/list_databases', { params: { db_identifier, region } }),
    listMySQLTablesOnDatabase: (db_identifier, region, database_name) => apiConn.get('/services/aws/rds/mysql/list_tables', { params: { db_identifier, region, database_name } }),
  },

  github: {
    listRepositories: () => apiConn.get('/services/github/repositories'),
    getTeams: () => apiConn.get('/services/github/teams'),
    getUsers: () => apiConn.get('/services/github/users'),
    connect: {
      accountInfo: () => apiConn.get('/services/github/connect/info'),
      connectAccount: () => apiConn.get('/services/github/connect/login'),
      removeAccount: () => apiConn.post('/services/github/connect/remove'),
    },
    computeUserAccess: () => apiConn.get('/services/github/access'),
  },

  users: {
    current: () => apiConn.get('/users/current'),
    list: (page = 1, perPage = 10, search = '') => apiConn.get(`/users/list?page=${page}&per_page=${perPage}&search=${search}`),
    enable: (id, enabled) => apiConn.put(`/users/${id}/status`, { enabled }),
    role: (id, roleID) => apiConn.put(`/users/${id}/role`, { role_id: roleID }),
    logout: () => apiConn.post('/users/logout'),
  },

  snipe: {
    listAvailableAssets: (company_id) => apiConn.get('/services/snipe/assets/list', { params: { company_id } }),
    listAvailableAssetsOnOnboarding: () => apiConn.get('/services/snipe/assets/list_on_onboarding'),
    listAssetsFromUser: (email) => apiConn.get('/services/snipe/assets/list_from_user', { params: { email } }),
    getCompanies: () => apiConn.get('/services/snipe/companies'),
  },

  google: {
    generateEmail: (firstName, lastName) => apiConn.post('/services/google/email/generate', {
      first_name: firstName,
      last_name: lastName,
    }),
    validateEmail: (email) => apiConn.post('/services/google/email/validate', { email }),
    listUsers: () => apiConn.get('/services/google/users'),
    listGroups: () => apiConn.get('/services/google/groups/list'),
    listOrgUnits: () => apiConn.get('/services/google/orgunits/list'),
  },

  // Onboardings
  onboardings: {
    list: () => apiConn.get('/services/onboarding/list'),
    details: (id) => apiConn.get(`/services/onboarding/${id}/details`),
    create: (data) => apiConn.post('/services/onboarding/create', data),
    run: (id) => apiConn.post('/services/onboarding/run', { id }),
  },

  // Teams
  teams: {
    list: (page = 1, perPage = 10, search = '') => apiConn.get(`/teams/list?page=${page}&per_page=${perPage}&search=${search}`),
    get: (id) => apiConn.get(`/teams/${id}/details`),
    create: (team) => apiConn.post('/teams/create', team),
    update: (id, data) => apiConn.put(`/teams/${id}/update`, data),
    delete: (id) => apiConn.delete(`/teams/${id}/delete`),
  },

  // Locations
  locations: {
    list: (page = 1, perPage = 10, search = '') => apiConn.get(`/locations/list?page=${page}&per_page=${perPage}&search=${search}`),
    get: (id) => apiConn.get(`/locations/${id}/details`),
    create: (location) => apiConn.post('/locations/create', location),
    update: (id, data) => apiConn.put(`/locations/${id}/update`, data),
    delete: (id) => apiConn.delete(`/locations/${id}/delete`),
  },

  // Email groups
  groups: {
    list: (page = 1, perPage = 10, search = '') => apiConn.get(`/emailgroups/list?page=${page}&per_page=${perPage}&search=${search}`),
    create: (group) => apiConn.post('/emailgroups/create', group),
    calculate: (team, location) => apiConn.post('/emailgroups/calculate', { team, location }),
  },

  roles: {
    list: (page = 1, perPage = 10, search = '') => apiConn.get(`/roles/list?page=${page}&per_page=${perPage}&search=${search}`),
    get: (id) => apiConn.get(`/roles/${id}/details`),
    getMembers: (id) => apiConn.get(`/roles/${id}/members`),
  },

  // Features
  settings: {
    get: (setting) => apiConn.get(`/settings/${setting}`),
    update: (setting, value) => apiConn.put(`/settings/${setting}`, { value }),
  },

  // Tickets
  tickets: {
    list: (status = 'all', page = 1, searchTerm = '') => apiConn.get(`/tickets?status=${status}&page=${page}&search=${searchTerm}`),
    listMyTickets: (status = 'all', page = 1, searchTerm = '') => apiConn.get(`/tickets?status=${status}&current_user=true&page=${page}&search=${searchTerm}`),
    details: (id) => apiConn.get(`/tickets/${id}`),
    create: (values) => apiConn.post('/tickets', values),
    review: (id, data) => apiConn.put(`/tickets/${id}/review`, data),
    viewLogs: (id) => apiConn.get(`/tickets/${id}/logs`),
    export: (params) => apiConn.post('/tickets/export', params, { responseType: 'blob' }),
    updateStatus: (id, status, reason) => apiConn.put(`/tickets/${id}/status`, { status, status_reason: reason }),
    retry: (id) => apiConn.post(`/tickets/${id}/retry`),
  },

  email: {
    // Email templates
    templates: {
      list: () => apiConn.get('/email/templates'),
      create: (values) => apiConn.post('/email/templates', values),
      details: (id) => apiConn.get(`/email/templates/${id}`),
      update: (id, data) => apiConn.put(`/email/templates/${id}`, data),
      delete: (id) => apiConn.delete(`/email/templates/${id}`),
    },
    // Predefined emails
    predefined: {
      list: () => apiConn.get('/email/predefined'),
      create: (values) => apiConn.post('/email/predefined', values),
      details: (id) => apiConn.get(`/email/predefined/${id}`),
      delete: (id) => apiConn.delete(`/email/predefined/${id}`),
    },
  },

  metrics: {
    createdTickets: (daysAgo) => apiConn.get(`/metrics/created_tickets?days=${daysAgo}`),
    resolvedTickets: (daysAgo) => apiConn.get(`/metrics/resolved_tickets?days=${daysAgo}`),
    totalTickets: (status = 'all') => apiConn.get(`/metrics/total_tickets?status=${status}`),
    totalRegisteredUsers: () => apiConn.get('/metrics/total_users'),
    averageResolutionTime: () => apiConn.get('/metrics/average_resolution_time'),
  },
};
