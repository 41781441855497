import React, { useState } from 'react';
import {
  Pane, FormField, Switch, Select, majorScale, TextInput,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { api } from '../../../../services/api';
import BaseSheet from '../../../../components/sidesheets/base';
import formHelp from '../../../../constants/help';
import appTypes from '../../../../constants/types';

const EditUserSheet = ({ visible, user, roles, close }) => {
  const [editedUser, setEditedUser] = useState(user);
  const [roleEdited, setRoleEdited] = useState(false);
  const [statusEdited, setStatusEdited] = useState(false);

  const handleChange = (value, key) => {
    if (key === 'role_id') {
      setEditedUser({ ...editedUser, role: { ...editedUser.role, id: value } });
      setRoleEdited(true);
    }
    if (key === 'enabled') {
      setEditedUser({ ...editedUser, enabled: value });
      setStatusEdited(true);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (statusEdited) await api.users.enable(user.id, editedUser.enabled);
    if (roleEdited) await api.users.role(user.id, editedUser.role.id);
  };

  const form = (
    <Pane>
      <Pane display="flex">
        <FormField label="Name" labelFor="name" marginRight={majorScale(2)} flexGrow={1}>
          <TextInput name="name" disabled value={editedUser.name} width="100%" />
        </FormField>
        <FormField label="Email" labelFor="email" flexGrow={1}>
          <TextInput name="email" disabled value={editedUser.email} width="100%" />
        </FormField>
      </Pane>
      <FormField label="Role" marginTop={majorScale(4)}>
        <Select width="100%" defaultValue={editedUser.role.id} onChange={(e) => handleChange(e.target.value, 'role_id')}>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField label="Enabled" display="flex" justifyContent="space-between" marginTop={majorScale(4)} onChange={(e) => handleChange(e.target.checked, 'enabled')}>
        <Switch height={20} checked={editedUser.enabled} />
      </FormField>
    </Pane>
  );

  return (
    <BaseSheet.Create
      form={form}
      close={close}
      help={formHelp.user}
      visible={visible}
      submitLoading={false}
      title={`Edit user "${user.name}"`}
      okText="Save"
      onSubmit={submitForm} />
  );
};

EditUserSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  user: appTypes.allowedUser.props.isRequired,
  roles: PropTypes.objectOf(PropTypes.object()),
  close: PropTypes.func.isRequired,
};

export default EditUserSheet;
