import React, { useState } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput, Checkbox } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';

const NewIAMUserForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_name: '',
      user_email: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      user_name: Yup.string().required('Name of the new user is required'),
      user_email: Yup.string().required('Email is required').email('Must be a valid email'),
    }),
    validateOnChange: false,
  });

  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('user_name', user.full_name);
      formik.setFieldValue('user_email', user.email);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Create a new IAM User</Heading>
            <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
          </Pane>

          <Pane paddingY={majorScale(1)}>
            <Checkbox
              label="Enter account manually"
              checked={allowEdit}
              onChange={(e) => setAllowEdit(e.target.checked)}
              marginBottom={majorScale(2)}
            />
            <FormField label="Full Name" validationMessage={errors.user_name} marginBottom={majorScale(2)}>
              <TextInput
                data-testid="user_name"
                name="user_name"
                onChange={handleChange}
                value={values.user_name}
                readOnly={!allowEdit}
                placeholder={allowEdit ? "Example: John Doe, Jane Roe" : ""}
                isInvalid={errors.user_name && true}
              />
            </FormField>
            <FormField label="Email" validationMessage={errors.user_email} marginBottom={majorScale(2)}>
              <TextInput
                data-testid="user_email"
                name="user_email"
                onChange={handleChange}
                value={values.user_email}
                placeholder={allowEdit ? "Email that will receive the IAM credentials" : ""}
                readOnly={!allowEdit}
                isInvalid={errors.user_email && true}
              />
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border="default"
          marginTop={majorScale(2)}>
          <Button type="button" disabled={formik.isSubmitting} marginRight={majorScale(2)}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default NewIAMUserForm;
