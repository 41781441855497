import React from 'react';
import { Select, Pane, Text, majorScale, Pagination } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { searchParams } from '../../services/history';

const PaginationMenu = ({
  pageMetadata,
  onPreviousPage,
  onNextPage,
  onChangePage,
  onItemsPerPageChange,
}) => (
  <Pane
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    padding={majorScale(2)}
    marginBottom={majorScale(4)}
    elevation={0}
    backgroundColor="white">
    <Pane />
    <Pagination
      page={pageMetadata.page}
      totalPages={pageMetadata.page_count}
      onPageChange={(p) => {
        searchParams.set('page', p);
        if (onChangePage) {
          onChangePage();
        }
      }}
      onNextPage={() => {
        searchParams.set('page', pageMetadata.page + 1);
        if (onNextPage) {
          onNextPage();
        }
      }}
      onPreviousPage={() => {
        searchParams.set('page', pageMetadata.page - 1);
        if (onPreviousPage) {
          onPreviousPage();
        }
      }} />
    <Pane display="flex" alignItems="center">
      <Text>Items per page:</Text>
      <Select
        marginLeft={majorScale(1)}
        defaultValue={searchParams.get('per_page') || 10}
        width="100%"
        onChange={(e) => {
          searchParams.set('per_page', e.target.value);
          if (onItemsPerPageChange) {
            onItemsPerPageChange();
          }
        }}>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </Select>
    </Pane>
  </Pane>
);

PaginationMenu.propTypes = {
  pageMetadata: {
    page: PropTypes.number,
    per_page: PropTypes.number,
    item_count: PropTypes.number,
    page_count: PropTypes.number,
    has_previous_page: PropTypes.bool,
    has_next_page: PropTypes.bool,
  },
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onChangePage: PropTypes.func,
  onItemsPerPageChange: PropTypes.func,
};

PaginationMenu.defaultProps = {
  pageMetadata: {
    page: 1,
    per_page: 10,
    item_count: 0,
    page_count: 1,
    has_previous_page: false,
    has_next_page: false,
  },
  onPreviousPage: null,
  onNextPage: null,
  onChangePage: null,
  onItemsPerPageChange: null,
};

export default PaginationMenu;




// import React from 'react';
// import { Select, Pane, Text, majorScale } from 'evergreen-ui';
// import PropTypes from 'prop-types';
// import { searchParams } from '../../services/history';

// const PaginationMenu = ({ afterChange }) => (
//   <Pane display="flex" alignItems="center">
//     <Text>Items per page:</Text>
//     <Select
//       marginLeft={majorScale(1)}
//       defaultValue={searchParams.get('per_page') || 10}
//       width="100%"
//       onChange={(e) => {
//         searchParams.set('per_page', e.target.value);
//         if (afterChange) {
//           afterChange();
//         }
//       }}>
//       <option value="10">10</option>
//       <option value="20">20</option>
//       <option value="50">50</option>
//       <option value="100">100</option>
//     </Select>
//   </Pane>
// );

// PaginationMenu.propTypes = {
//   afterChange: PropTypes.func,
// };

// PaginationMenu.defaultProps = {
//   afterChange: null,
// };

// export default PaginationMenu;
