import React from 'react';
import {
  Pane,
} from 'evergreen-ui';
import GithubAccess from './components/github';
import AWSIAMUserAccess from './components/aws-iam-user';
import AWSIAMRoleAccess from './components/aws-iam-role';
import { UserAccessProvider } from '../../contexts/user-access-content';

const MyAccountsPage = () => (
  <Pane>
    <GithubAccess />
    <UserAccessProvider>
      <AWSIAMUserAccess />
      <AWSIAMRoleAccess />
    </UserAccessProvider>
  </Pane>
);

export default MyAccountsPage;
