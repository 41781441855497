import React, { useState, useEffect } from 'react';
import { Button, Card, SelectField, FormField, Heading, majorScale, Pane, Text, TextareaField, TextInput, toaster } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectAWSEC2InstanceType } from '../../../../../components/dialogs';
import { api } from '../../../../../services/api';

const NewEC2SandboxForm = ({ submit }) => {
  const currentUser = useSelector((state) => state.authentication.user);

  const formik = useFormik({
    initialValues: {
      owner_name: currentUser.name,
      owner_email: currentUser.email,
      owner_ssh_key: '',
      aws_region: 'us-east-1',
      instance_type: 't4g.micro',
      ebs_disk: 0,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      owner_name: Yup.string().required('Name is required'),
      owner_email: Yup.string().required('Email is required').email('Must be a valid email'),
      owner_ssh_key: Yup.string().required('SSH key is required'),
      aws_region: Yup.string().required('Region is required'),
      instance_type: Yup.string().required('Instance type is required'),
      ebs_disk: Yup.number(),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  const selectInstanceType = (instanceType) => {
    if (instanceType) {
      formik.setFieldValue('instance_type', instanceType.name);
    }
  };

  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState([]);
  let unmounted = false;

  async function fetchData() {
    setRegionsLoading(true);
    try {
      const { data } = await api.aws.listRegions();
      if (!unmounted) {
        setRegions(data);
        setRegionsLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS regions');
      setRegionsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Request a new EC2 Sandbox instance</Heading>
            </Pane>
          </Pane>

          <Pane paddingY={majorScale(2)}>
            <Pane display="flex">
              <FormField label="Name">
                <Text>{values.owner_name}</Text>
              </FormField>
              <FormField
                label="Email"
                maxWidth="50%"
                marginLeft={majorScale(2)}>
                <Text>{values.owner_email}</Text>
              </FormField>
            </Pane>

            <Pane display="flex" marginTop={majorScale(2)} width="50%">
              <TextareaField
                label="SSH Public key"
                name="owner_ssh_key"
                data-testid="owner_ssh_key"
                value={values.owner_ssh_key}
                onChange={handleChange}
                validationMessage={errors.owner_ssh_key}
                isInvalid={errors.owner_ssh_key && true}
                placeholder="Paste your public SSH key here"
                width="100%" />
            </Pane>

            <Pane display="flex">
              <SelectField
                label="Region"
                validationMessage={errors.aws_region}
                data-testid="aws_region"
                name="aws_region"
                disabled={regionsLoading}
                onChange={handleChange}
                value={values.aws_region}
                isInvalid={errors.aws_region && true}>
                {!regionsLoading && regions.length > 0
                  && regions.map((region) => (
                    <option key={region} value={region}>{region}</option>
                  ))}
              </SelectField>
            </Pane>

            <Pane display="flex">
              <FormField label="Instance type">
                <Pane display="flex">
                  <TextInput value={values.instance_type} readOnly width="50%" marginRight={majorScale(1)} />
                  <SelectAWSEC2InstanceType
                    region={values.aws_region}
                    onSelect={selectInstanceType} />
                </Pane>
              </FormField>
            </Pane>

            <FormField paddingTop={majorScale(2)} label="EBS disk" validationMessage={errors.ebs_disk} hint="The amount of GiB you need (apart from root device)">
              <TextInput
                data-testid="ebs_disk"
                name="ebs_disk"
                type="number"
                onChange={handleChange}
                value={values.ebs_disk}
                isInvalid={errors.ebs_disk && true} />
            </FormField>

          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default NewEC2SandboxForm;
