import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const GoogleDeleteGroupDetails = ({ ticket }) => (
  <SectionCard title="Group Details">
    <TextDataField
      name="Group Email"
      value={ticket && ticket.ticket_data.group_email} />
  </SectionCard>
);

GoogleDeleteGroupDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
GoogleDeleteGroupDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
