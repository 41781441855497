import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const NewIAMRoleDetails = ({ ticket }) => (
  <SectionCard title="Role information">
    <TextDataField
      name="Name"
      value={ticket && ticket.ticket_data.role_name} />
  </SectionCard>
);

NewIAMRoleDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
NewIAMRoleDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
