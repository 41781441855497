export default function getStatusColor(status) {
  switch (status.toLowerCase()) {
    case 'approved':
      return 'green';
    case 'declined':
      return 'red';
    default:
      return 'neutral';
  }
}
