import React, { useState, useEffect } from 'react';
import {
  Pane,
  Tab,
  TabNavigation,
  toaster,
  Table,
  Link,
  IconButton,
  ChevronRightIcon,
  majorScale,
  Pagination,
  Text,
} from 'evergreen-ui';
import { Link as ReactRouterLink, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import './index.scss';
import { api } from '../../services/api';
import { ExportTicketsDialog } from '../../components/dialogs';

const RequestsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tickets, setTickets] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  async function fetchData() {
    try {
      const { data } = await api.tickets.list(searchParams.get('tab') || 'pending', searchParams.get('page') || 1, searchTerm);
      setTickets(data.data);
      setMetadata(data.metadata);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
    }
  }


  const handleNextPage = () => {
    const nextPage = metadata.page + 1;
    if (nextPage <= metadata.page_count) {
      searchParams.set('page', nextPage);
      setSearchParams(searchParams);
    }
  };

  const handlePreviousPage = () => {
    const previousPage = metadata.page - 1;
    if (previousPage >= 1) {
      searchParams.set('page', previousPage);
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = (pageNumber) => {
    const nextPage = parseInt(pageNumber, 10);
    if (nextPage >= 1 && nextPage <= metadata.page_count) {
      searchParams.set('page', nextPage);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const event = setTimeout(() => {
      fetchData();
    }, 500)
    return () => clearTimeout(event);
  }, [searchTerm]);

  const tabs = ['Pending', 'Approved', 'Declined'];

  // eslint-disable-next-line no-restricted-globals
  const currentTab = () => {
    const tab = searchParams.get('tab');
    if (!tab){
      return 'pending';
    }
    return tab;
  };
  return (
    <Pane className="requests-page">
      <TabNavigation display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Pane />
        <Pane>
          {tabs.map((item) => (
            <Tab
              onSelect={() => setSearchParams(`tab=${item.toLowerCase()}`)}
              key={item}
              isSelected={currentTab() === item.toLowerCase()}>
              {item}
            </Tab>
          ))}
        </Pane>
        <Pane>
          <ExportTicketsDialog />
        </Pane>
      </TabNavigation>
      <Pane className="requests-tables">
        <Table className="requests-table">
          <Table.Head>
            <Table.SearchHeaderCell onChange={(e) => {
              setSearchTerm(e);
            }} />
            <Table.TextHeaderCell>Service</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created by</Table.TextHeaderCell>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            {currentTab() !== 'pending'
              && <Table.TextHeaderCell>Resolved by</Table.TextHeaderCell>}
            <Table.TextHeaderCell />
          </Table.Head>
          <Table.Body>
            {
              tickets.length < 1 ? 
                (<Text>No tickets found.</Text>)
                : (tickets.map((ticket) => {
                  return (
                    <Table.Row key={ticket.id}>
                      <Table.TextCell>{ticket.title}</Table.TextCell>
                      <Table.TextCell>{ticket.friendly_type}</Table.TextCell>
                      <Table.TextCell><b>{ticket.created_by.name}</b></Table.TextCell>
                      <Table.TextCell>{dayjs(ticket.updated_at).format('DD-MM-YYYY')}</Table.TextCell>
                      {currentTab() !== 'pending' && (
                      <Table.TextCell><b>{ticket.updated_by ? ticket.updated_by.name : "None" }</b></Table.TextCell>
                      )}
                      <Table.TextCell className="actions-cell" display="grid" justifyContent="end">
                        <Link to={`/tickets/${ticket.id}`} is={ReactRouterLink}>
                          <IconButton
                            icon={ChevronRightIcon}
                            marginRight={majorScale(2)} />
                        </Link>
                      </Table.TextCell>
                    </Table.Row>
                  );
                }))
            }
          </Table.Body>
        </Table>
        <Pagination page={metadata.page} totalPages={metadata.page_count} onPageChange={handlePageChange} onNextPage={handleNextPage} onPreviousPage={handlePreviousPage} className="pagination"></Pagination>
      </Pane>
    </Pane>
  );
};

export default RequestsPage;
