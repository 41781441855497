import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Dialog,
  Heading,
  majorScale,
  minorScale, Pane,
  SearchInput,
  Text,
  toaster,
} from 'evergreen-ui';
import { api } from '../../services/api';
import filter from '../../assets/scripts/filter';
import Skeleton from '../skeleton';

const LoadUserFromGoogleDialog = ({ onSelect }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  let unmounted = false;

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.google.listUsers();
      if (!unmounted) {
        setUsers(data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (dialogOpened && users.length < 1) {
      fetchData();
    }
    return () => {
      unmounted = true;
    };
  }, [dialogOpened]);

  const handleConfirm = (close) => {
    if (onSelect) {
      onSelect(selectedUser);
    }
    close();
  };

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setDialogOpened(true);
        }}
        appearance='primary'>
        Load user from Google
      </Button>

      <Dialog
        isShown={dialogOpened}
        title="Select user from Google"
        onCloseComplete={() => setDialogOpened(false)}
        onConfirm={(close) => handleConfirm(close)}
        onCancel={(close) => onCancel(close)}
        isConfirmLoading={loading}
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <Pane>
          <Pane
            position="sticky"
            top="0px"
            backgroundColor="white"
            paddingBottom={majorScale(2)}
            marginX="-2px"
            borderBottom="muted"
            zIndex="1">
            <SearchInput
              width="100%"
              placeholder="Filter by name or email"
              disabled={loading}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </Pane>

          <Pane>
            {!loading && users.length > 0
              ? filter(searchTerm, users, ['full_name', 'email']).map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  select={setSelectedUser}
                  isSelected={selectedUser.id === user.id} />
              ))
              : (
                <Pane>
                  {[1, 2, 3, 4].map((key) => (
                    <Skeleton
                      key={key}
                      width="100%"
                      height="64px"
                      style={{
                        marginTop: majorScale(2),
                        marginBottom: majorScale(2),
                        borderRadius: '3px',
                      }} />
                  ))}
                </Pane>
              )}
          </Pane>
        </Pane>

      </Dialog>
    </>
  );
};

const UserRow = ({ user, select, isSelected }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    background="white"
    marginY={majorScale(2)}
    padding={minorScale(3)}
    borderRadius="3px"
    hoverElevation={1}
    activeElevation={0}
    cursor="pointer"
    onClick={() => select(user)}
    border={isSelected ? '2px solid #3366FF' : 'default'}>

    <Pane display="flex" alignItems="center">
      <Avatar src={user.profile_image} name={user.full_name} size={32} />
      <Pane marginLeft={majorScale(2)}>
        <Pane>
          <Heading size={400}>{user.full_name}</Heading>
        </Pane>
        <Pane>
          <Text>{user.email}</Text>
        </Pane>
      </Pane>
    </Pane>
  </Pane>
);

export default LoadUserFromGoogleDialog;

LoadUserFromGoogleDialog.propTypes = {
  onSelect: PropTypes.func,
};

LoadUserFromGoogleDialog.defaultProps = {
  onSelect: null,
};

UserRow.propTypes = {
  user: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    aliases: PropTypes.arrayOf(PropTypes.string),
    profile_image: PropTypes.string,
  }),
  select: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

UserRow.defaultProps = {
  user: null,
  isSelected: false,
};
