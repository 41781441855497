import React, { useState, useEffect } from 'react';
import { Text } from 'evergreen-ui';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { AccountDataField, BadgesDataField, LocationDataField, TextDataField } from '../../../../components/data-fields';
import accounts from '../../../../constants/accounts';
import SnipeAsset from '../../../../components/snipe-asset';
import { api } from '../../../../services/api';

export const OnboardingDetails = ({ ticket }) => {
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const { data } = await api.settings.get('onboarding_ticket');
        setCustomFields(data.custom_fields);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, []);

  return (
  <>
    <SectionCard title="Personal information">
      <TextDataField
        name="First name"
        value={ticket && ticket.ticket_data.first_name} />
      <TextDataField
        name="Last name"
        value={ticket && ticket.ticket_data.last_name} />
      <TextDataField
        name="Personal email"
        value={ticket && ticket.ticket_data.personal_email} />
      <TextDataField
        name="Team"
        value={ticket && ticket.ticket_data.team.name} />
      <LocationDataField
        name="Location"
        countryCode={ticket && ticket.ticket_data.location.country_code}
        countryName={ticket && ticket.ticket_data.location.name} />
      {customFields.includes('mobile_number') && (
          <TextDataField
            name="Mobile number"
            value={ticket && ticket.ticket_data.mobile_number}
          />
        )}
        {customFields.includes('join_date') && (
          <TextDataField
            name="Join date"
            value={ticket && ticket.ticket_data.join_date}
          />
        )}
        {customFields.includes('experience') && (
          <TextDataField
            name="Experience"
            value={ticket && ticket.ticket_data.experience}
          />
        )}
        {customFields.includes('job_title') && (
          <TextDataField
            name="Job title"
            value={ticket && ticket.ticket_data.job_title}
          />
        )}
        {customFields.includes('business_unit') && (
          <TextDataField
            name="Business unit"
            value={ticket && ticket.ticket_data.business_unit}
          />
        )}
        {customFields.includes('department') && (
          <TextDataField
            name="Department"
            value={ticket && ticket.ticket_data.team.name}
          />
        )}
        {customFields.includes('reporting_manager') && (
          <TextDataField
            name="Reporting manager"
            value={ticket && ticket.ticket_data.reporting_manager}
          />
        )}
        {customFields.includes('address') && (
          <TextDataField
            name="Address"
            value={ticket && ticket.ticket_data.address}
          />
        )}
    </SectionCard>
    <SectionCard title="Email">
      <TextDataField
        name="Employee email"
        value={ticket && ticket.ticket_data.jampp_email} />
      <TextDataField
        name="Organizational unit"
        value={ticket && ticket.ticket_data.google_org_unit} />
      <BadgesDataField
        name="Email groups"
        items={ticket && ticket.ticket_data.email_groups}
        emptyMessage="No email groups" />
      <BadgesDataField
        name="Predefined emails"
        items={ticket && ticket.ticket_data.predefined_emails && ticket.ticket_data.predefined_emails.map((pe) => pe.name)}
        emptyMessage="No predefined emails will be sent" />
    </SectionCard>
    <SectionCard title="Accounts">
      {ticket.ticket_data.accounts.length < 1
        ? <Text color="muted">No accounts</Text>
        : accounts.map((account) => {
          if (ticket.ticket_data.accounts.includes(account.key)) {
            return (
              <AccountDataField
                key={account.key}
                image={account.image}
                name={account.name} />
            );
          }
          return null;
        })}
    </SectionCard>
    <SectionCard title="Assets">
      {ticket.ticket_data.assets.length < 1
        ? <Text color="muted">No assets</Text>
        : ticket.ticket_data.assets.map((asset) => (
          <SnipeAsset key={asset.id} asset={asset} isSelectable={false} />
        ))}
    </SectionCard>
  </>
  );
}

OnboardingDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
OnboardingDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
