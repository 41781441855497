import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField, BadgesDataField } from '../../../../components/data-fields';

export const GrantMySQLUserPrivilegesDetails = ({ ticket }) => (
  <SectionCard title="MySQL user information">
    <TextDataField
      name="Region"
      value={ticket && ticket.ticket_data.region} />
    <TextDataField
      name="DB Name"
      value={ticket && ticket.ticket_data.db_identifier} />
    <BadgesDataField
      name="Privileges"
      items={ticket && ticket.ticket_data.privileges} />
    <TextDataField
      name="Database"
      value={ticket && ticket.ticket_data.database_name} />
    <BadgesDataField
      name="Tables"
      items={ticket && ticket.ticket_data.tables} />
    <TextDataField
      name="Username"
      value={ticket && ticket.ticket_data.username} />
  </SectionCard>
);

GrantMySQLUserPrivilegesDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
GrantMySQLUserPrivilegesDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
