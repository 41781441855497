import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  Button,
  Alert,
  Tooltip,
  PlusIcon,
  EyeOpenIcon,
  TrashIcon,
  majorScale,
  IconButton,
  Pagination,
  toaster,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { api } from '../../../../../services/api';
import appTypes from '../../../../../constants/types';
import { searchParams } from '../../../../../services/history';
import Skeleton from '../../../../../components/skeleton';
import NoResults from '../../../../../components/no-results';
import DeletePopConfirm from '../../../../../components/popconfirm/delete';
import SectionCard from '../../../../../components/section-card';
import { AuthorizedWrapper } from '../../../../../components/authorized-wrapper';
import PredefinedEmailDetailsSheet from './sidesheets/details';
import CreatePredefinedEmailSheet from './sidesheets/create';

const itemsPerPage = 5;

const SettingsEmailPredefined = () => {
  // Flags
  const [loading, setLoading] = useState(true);
  const [newOpen, setNewOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const openDetails = (id) => {
    searchParams.set('selected', id);
    setDetailsOpen(true);
  };

  let unmounted = false;
  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.email.predefined.list();
      if (!unmounted) {
        setEmails(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        setError('There was an error while proccessing your request');
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();

    return () => {
      unmounted = true;
    };
  }, []);

  const deleteEmail = async (id) => {
    try {
      await api.email.predefined.delete(id);
      fetchData();
    } catch {
      toaster.danger('There was an error while proccessing your request');
    }
  };

  return (
    <>
      <SectionCard
        title="Predefined emails"
        button={(
          <AuthorizedWrapper resource="predefined_emails" action="create">
            <Button iconBefore={PlusIcon} marginY={-6} appearance="primary" onClick={() => setNewOpen(true)}>
              New predefined email
            </Button>
          </AuthorizedWrapper>
        )}>

        {loading && !error && emails.length < 1 && [...Array(3)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={i} width="100%" height="64px" style={{ borderRadius: '3px', marginTop: '16px' }} />
        ))}

        {!loading && !error && emails.length < 1 && (
          <NoResults buttonText="Create a new predefined email" onClick={() => setNewOpen(true)} />
        )}

        {!loading && error && (
          <Alert intent="danger" title={error} />
        )}

        {!loading && !error && emails.length > 0 && (
          <>
            {emails.slice(
              (currentPage - 1) * itemsPerPage,
              (currentPage - 1) * itemsPerPage + itemsPerPage,
            ).map((email) => (
              <PredefinedEmail
                key={email.id}
                email={email}
                openDetails={openDetails}
                deleteEmail={deleteEmail} />
            ))}

            <Pane display="flex" justifyContent="flex-end">
              <Pagination
                page={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
                onPreviousPage={() => setCurrentPage(currentPage - 1)}
                onNextPage={() => setCurrentPage(currentPage + 1)} />
            </Pane>
          </>
        )}
      </SectionCard>

      {detailsOpen && (
        <PredefinedEmailDetailsSheet
          visible={detailsOpen}
          close={() => {
            setDetailsOpen(false);
            searchParams.remove('selected');
          }} />
      )}

      {newOpen && (
        <CreatePredefinedEmailSheet
          visible={newOpen}
          close={() => {
            setNewOpen(false);
            fetchData();
          }} />
      )}
    </>
  );
};

const PredefinedEmail = ({ email, openDetails, deleteEmail }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    backgroundColor="white"
    justifyContent="space-between"
    marginY={majorScale(2)}
    padding={majorScale(1)}
    borderRadius="3px">
    <Text>{email.name}</Text>

    <Pane display="flex" alignItems="center">
      <AuthorizedWrapper resource="predefined_emails" action="details">
        <Tooltip content="Open details">
          <IconButton icon={EyeOpenIcon} onClick={() => openDetails(email.id)} />
        </Tooltip>
      </AuthorizedWrapper>
      <AuthorizedWrapper resource="predefined_emails" action="delete">
        <DeletePopConfirm onConfirm={() => deleteEmail(email.id)}>
          <Tooltip content="Delete template">
            <IconButton marginLeft={majorScale(1)} icon={TrashIcon} intent="danger" />
          </Tooltip>
        </DeletePopConfirm>
      </AuthorizedWrapper>
    </Pane>
  </Pane>
);

PredefinedEmail.propTypes = {
  email: PropTypes.shape(appTypes.emailTemplate.props).isRequired,
  openDetails: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
};

export default SettingsEmailPredefined;
