import validator from 'validator';

const messages = {
  required: 'This field is required',
  invalidEmail: 'This email is invalid',
  invalidName: 'Name can only contain letters and spaces',
  noJamppEmail: 'This email must be the personal email',
  invalidExperience: 'The experience must be typed in years (numbers)',
};

// TODO: Move to config in the future
const emailDomains = [
  'affle.com',
  'affle.in',
  'afflemedialab.com',
  'appnext.com',
  'appstudioz.com',
  'convergeai.co',
  'dc289.com',
  'jampp.com',
  'maasplatform.com',
  'mediasmart.es',
  'mediasmart.io',
  'mfaas.com',
  'mtraction.com',
  'notifications.youappi.com',
  'revx.io',
  'ripple.ad',
  'shoffr.com',
  'shoffr.io',
  'ultraplatform.io',
  'vizury.com',
  'yagatech.com',
  'youappi.com',
];

export const validateFields = (values, step, customFields) => {
  const errors = {};

  const isFieldActive = (fieldName) => customFields.includes(fieldName);

  switch (step) {
    case 0:
      if (!values.first_name) {
        errors.first_name = messages.required;
      } else if (/\d/.test(values.first_name)) {
        errors.first_name = messages.invalidName;
      }

      if (!values.last_name) {
        errors.last_name = messages.required;
      } else if (/\d/.test(values.first_name)) {
        errors.first_name = messages.invalidName;
      }

      if (!values.personal_email) {
        errors.personal_email = messages.required;
      } else if (!validator.isEmail(values.personal_email)) {
        errors.personal_email = messages.invalidEmail;
      } else if (new RegExp(emailDomains.join('|')).test(values.personal_email)) {
        errors.personal_email = messages.noJamppEmail;
      }

      if (!values.team) {
        errors.team = messages.required;
      }

      if (!values.location) {
        errors.location = messages.required;
      }

      if (isFieldActive('mobile_number')) {
        if (!values.mobile_number) {
          errors.mobile_number = messages.required;
        }
      }

      if (isFieldActive('join_date')) {
        if (!values.join_date) {
          errors.join_date = messages.required;
        } else if (!validator.isDate(values.join_date)) {
          errors.join_date = messages.invalidDate;
        }
      }
      
      if (isFieldActive('experience')) {
        if (!values.experience) {
          errors.experience = messages.required;
        } else if (!validator.isNumeric(values.experience)) {
          errors.experience = messages.invalidExperience;
        }
      }

      if (isFieldActive('job_title')) {
        if (!values.job_title) {
          errors.job_title = messages.required;
        }
      }

      if (isFieldActive('business_unit')) {
        if (!values.business_unit) {
          errors.business_unit = messages.required;
        }
      }

      if (isFieldActive('department')) {
        if (!values.department) {
          errors.department = messages.required;
        }
      }

      if (isFieldActive('reporting_manager')) {
        if (!values.reporting_manager) {
          errors.reporting_manager = messages.required;
        }
      }

      if (isFieldActive('address')) {
        if (!values.address) {
          errors.address = messages.required;
        }
      }

      break;

    case 1:
      if (!values.google_org_unit) {
        errors.google_org_unit = messages.required;
      }

      break;

    case 4:
      if (!values.jampp_email) {
        errors.jampp_email = messages.required;
      }
      break;

    default:
      break;
  }
  return errors;
};
