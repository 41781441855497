import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, Text, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateNewRepoForm = ({ submit }) => {
  // const [allowEdit, setAllowEdit] = useState(false);
  const currentUser = useSelector((state) => state.authentication.user);

  const formik = useFormik({
    initialValues: {
      new_repo: '',
      repo_visibility: false,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      new_repo: Yup.string().required('Name of the new repo is required'),
    }),
    validateOnChange: true,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const value = checked;
    handleChange({ target: { name, value } });
  };

  const { values, errors, handleChange } = formik;

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Create a new Github repository</Heading>
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
            <FormField label="Name" validationMessage={errors.new_repo}>
              <TextInput
                data-testid="new_repo"
                name="new_repo"
                onChange={handleChange}
                value={values.new_repo}
                //readOnly={!allowEdit}
                isInvalid={errors.new_repo && true} />
            </FormField>
              <Checkbox
                label="This repository is private by default. Check the box for a public repository"
                name="repo_visibility"
                checked={values.repo_visibility}
                onChange={handleCheckboxChange} >
              </Checkbox>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default CreateNewRepoForm;
