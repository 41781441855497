import React from 'react';
import { majorScale, Pane, Heading, Card } from 'evergreen-ui';
import PropTypes from 'prop-types';

const SectionCard = ({ title, children, button }) => (
  <Card border="default" padding={majorScale(2)} marginBottom={majorScale(2)} background="white">
    <Pane
      borderBottom="1px solid #edeff5"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom={majorScale(2)}
      marginBottom={majorScale(2)}>
      <Heading>{title}</Heading>
      {button}
    </Pane>
    {children}
  </Card>
);

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  button: PropTypes.node,
};

SectionCard.defaultProps = {
  children: null,
  button: null,
};

export default SectionCard;
