import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const InviteGithubUserDetails = ({ ticket }) => (
  <SectionCard title="Personal Information">
    <TextDataField
      name="Github name"
      value={ticket && ticket.ticket_data.github_name} />
  </SectionCard>
);

InviteGithubUserDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
InviteGithubUserDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
