import React, { useState } from 'react';
import {
  Pane, TextInputField, SelectField,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseSheet from '../../../../../../components/sidesheets/base';
import formHelp from '../../../../../../constants/help';
import { api } from '../../../../../../services/api';

const UpdateProviderSheeet = ({ visible, config, close }) => {
  const settingName = 'email_provider';
  const [provider, setProvider] = useState(config.provider);
  const [vaultSecretPath, setVaultSecretPath] = useState(provider === 'vault' ? config.vault_secret_path : null);

  const submit = async () => {
    const newConfig = {
      provider,
    };

    if (provider === 'vault') {
      newConfig.vault_secret_path = vaultSecretPath;
    }

    try {
      await api.settings.update(settingName, newConfig);
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const form = (
    <Pane>

      <SelectField
        label="Provider"
        defaultValue={provider}
        onChange={(e) => {
          setProvider(e.target.value);
          setVaultSecretPath(null);
        }}>
        <option value="environment">Environment variables</option>
        <option value="vault">Vault</option>
      </SelectField>

      {provider === 'vault' && (
        <TextInputField
          label="Secret path"
          description="The path to the secret containing SMTP credentials"
          defaultValue={vaultSecretPath}
          onChange={(e) => setVaultSecretPath(e.target.value)} />
      )}
    </Pane>
  );

  return (
    <BaseSheet.Edit
      form={form}
      close={close}
      help={formHelp.emailProvider}
      visible={visible}
      submitLoading={false}
      title="provider configuration"
      okText="Save"
      onSubmit={submit} />
  );
};

UpdateProviderSheeet.propTypes = {
  visible: PropTypes.bool.isRequired,
  config: PropTypes.shape.isRequired,
  close: PropTypes.func.isRequired,
};

export default UpdateProviderSheeet;
