import React from 'react';
import { Button, majorScale, Pane, Paragraph, ShareIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import noResults from '../../assets/images/no-results.png';

const NoResults = ({ message, buttonAction, buttonText }) => (
  <Pane display="flex" alignItems="center" justifyContent="center">
    <Pane textAlign="center">
      <img src={noResults} alt="No tickets" height="72px" width="auto" />
      <Paragraph marginTop={majorScale(1)}>
        {message}
      </Paragraph>

      {buttonAction && buttonText && (
        <Button iconAfter={ShareIcon} marginTop={majorScale(1)} size="small" appearance="minimal" onClick={buttonAction}>
          {buttonText}
        </Button>
      )}
    </Pane>
  </Pane>
);

NoResults.propTypes = {
  message: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonText: PropTypes.string,
};

NoResults.defaultProps = {
  message: 'There are no results to show',
  buttonAction: null,
  buttonText: null,
};

export default NoResults;
