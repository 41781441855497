import React, { useEffect, useState } from 'react';
import { CogIcon, GraphIcon, ConfirmIcon, Heading, Menu, Pane, ListIcon, majorScale, Button, minorScale, PlusIcon, Link, UnlockIcon } from 'evergreen-ui';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { api } from '../../services/api';
import './index.scss';

const Drawer = ({ opened }) => {
  const role = useSelector((state) => state.authentication.user.role);
  const [logoUrl, setLogoUrl] = useState(null);
  let unmounted = false;

  useEffect(async () => {
    const { data } = await api.settings.get('logo_url');
    if (!unmounted) {
      setLogoUrl(data);
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const menuItems = [
    {
      name: 'My tickets',
      icon: ListIcon,
      link: '/tickets',
    },
    {
      name: 'Accounts & Access',
      icon: UnlockIcon,
      link: '/my-accounts',
    },
  ];

  const bottomMenuItems = [
    {
      name: 'Requests',
      icon: ConfirmIcon,
      link: '/requests',
    },
  ];

  if (['administrator'].includes(role.uname)) {
    bottomMenuItems.push({
      name: 'Metrics',
      icon: GraphIcon,
      link: '/metrics',
    });
    bottomMenuItems.push({
      name: 'Settings',
      icon: CogIcon,
      link: '/settings',
    });
  }

  const location = useLocation();

  return (
    <Pane className={['drawer', opened ? '--opened' : null]}>
      <a href="/" className="logo-container">
        <img
          src={logoUrl}
          width="auto"
          height="48px"
          alt="Logo" />
      </a>
      <Menu>
        <Link to="/tickets/create" is={ReactRouterLink} width="100%" marginBottom={majorScale(2)}>
          <Button
            appearance="primary"
            height={36}
            width="100%"
            iconBefore={PlusIcon}>
            New ticket
          </Button>
        </Link>
        {/* <Heading size={100}>SERVICES</Heading> */}
        {menuItems.map(
          (item) => (
            <Menu.Item
              is={ReactRouterLink}
              to={item.link}
              icon={item.icon}
              key={item.name}
              marginY={minorScale(1)}
              className={`drawer-item ${location.pathname.startsWith(item.link) ? '--active' : ''}`}>
              <span>{item.name}</span>
            </Menu.Item>
          ),
        )}
        <section className="bottom-menu-items">
          <Menu.Divider />
          <Heading size={100} marginTop={majorScale(1)}>ADMINISTRATION</Heading>
          <Menu.Group>
            {bottomMenuItems.map(
              (item) => (
                <Menu.Item
                  is={ReactRouterLink}
                  to={item.link}
                  key={item.name}
                  icon={item.icon}
                  marginY={minorScale(1)}
                  className={`drawer-item ${location.pathname.startsWith(
                    item.link.split('#')[0],
                  ) ? '--active' : ''}`}>
                  {item.name}
                </Menu.Item>
              ),
            )}
          </Menu.Group>
        </section>
      </Menu>
    </Pane>
  );
};

Drawer.propTypes = {
  opened: PropTypes.bool.isRequired,
};

export default Drawer;
