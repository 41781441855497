import React from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const NewIAMRoleForm = ({ submit }) => {
  const formik = useFormik({
    initialValues: {
      role_name: '',
    },
    onSubmit: (values) => {
      values.role_name = values.role_name.toLowerCase();
      submit(values);
    },
    validationSchema: Yup.object().shape({
      role_name: Yup.string().required('Name of the new user is required'),
    }),
    validateOnChange: true,
  });

  const { values, errors, handleChange } = formik;

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Create a new IAM Role</Heading>
            </Pane>
          </Pane>

          <Pane paddingY={majorScale(1)}>
            <FormField 
              label="Name" 
              validationMessage={errors.role_name}
              hint="The name for the new role.">
              <TextInput
                data-testid="role_name"
                name="role_name"
                onChange={handleChange}
                value={values.role_name}
                isInvalid={errors.role_name && true} />
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default NewIAMRoleForm;
