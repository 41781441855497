import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  Dialog,
  Pane,
  toaster,
} from 'evergreen-ui';
import { api } from '../../../../../services/api';
import appTypes from '../../../../../constants/types';

const UpdateEmailTemplateName = ({ isOpen, onCloseComplete, template }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(template.name);

  const handleSave = async (close) => {
    setLoading(true);
    try {
      await api.email.templates.update(template.id, { name: value });
      setLoading(false);
      close();
    } catch (e) {
      toaster.danger('An error occurred while processing your request');
      setLoading(false);
    }
  };

  return (
    <Dialog
      isShown={isOpen}
      title="Rename template"
      onConfirm={(close) => handleSave(close)}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      isConfirmDisabled={loading || value.length < 1}
      containerProps={{
        borderRadius: '3px',
      }}
      contentContainerProps={{
        paddingY: '0px',
        minHeight: '0px',
      }}
      confirmLabel="Save"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}>

      <Pane>
        <TextInput width="100%" onChange={(e) => setValue(e.target.value)} value={value} />
      </Pane>

    </Dialog>
  );
};

export default UpdateEmailTemplateName;

UpdateEmailTemplateName.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseComplete: PropTypes.func.isRequired,
  template: appTypes.emailTemplate.props.isRequired,
};
