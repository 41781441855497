import React, { useState, useEffect } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput, SelectField, toaster, InlineAlert, Combobox } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';
import { api } from '../../../../../services/api';

const CreateMySQLUserForm = ({ submit }) => {
  const currentUser = useSelector((state) => state.authentication.user);
  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState([]);
  const [instancesLoading, setInstancesLoading] = useState([]);
  const [instances, setInstances] = useState([]);
  
  const formik = useFormik({
    initialValues: {
      db_identifier: '',
      region: '',
      username: '',
      user_email: currentUser.email,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      db_identifier: Yup.string().required('Database name is required'),
      region: Yup.string().required('Region is required'),
      username: Yup.string().required("Username is required"),
      user_email: Yup.string().required("Email of the user who will receive the credentials is required"),
    }),
    validateOnChange: false,
  });

  let unmounted = false;

  async function fetchData() {
    setRegionsLoading(true);
    try {
      const { data } = await api.aws.listRegionsWhereMySQLInstancesExist();
      if (!unmounted) {
        setRegions(data);
        setRegionsLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS regions.');
      setRegionsLoading(false);
    } 
  }

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);


  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('user_email', user.email);
    }
  };

  const listAvailableInstances = async (region) => {
    try {
        setInstancesLoading(true);
        const response = await api.aws.listRDSInstancesInRegion(region);
        console.log("Response is: ", response.data);
        setInstances(response.data);
    } catch (error) {
        toaster.danger('There was an error fetching the instances.');
        console.error(error);
    } finally {
        setInstancesLoading(false);
    }
};

  useEffect(() => {
    if (!regionsLoading && values.region) {
      listAvailableInstances(values.region);
    }
  }, [values.region, regionsLoading]);

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Create new MySQL user</Heading>
            <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
          </Pane>
          {regionsLoading && (
                <InlineAlert intent="none" marginBottom={16} marginTop={20}>
                  We are computing the regions where RDS instances are available.
                  This usually takes a few seconds, please do not close the window.
                </InlineAlert>
              )}
              <Pane display="flex" marginTop={10}>
                <SelectField
                  label="Region"
                  validationMessage={errors.region}
                  data-testid="region"
                  name="region"
                  disabled={regionsLoading}
                  onChange={handleChange}
                  value={values.region}>
                  <option value="" disabled={!values.region}>
                    Select a region
                  </option>
                  {!regionsLoading && regions.length > 0 &&
                    regions.map((region) => (
                      <option key={region} value={region}>{region}</option>
                    ))}
                </SelectField>
              </Pane>
              {values.region && (
                <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                  {instancesLoading && (
                    <InlineAlert intent="none" marginBottom={16}>
                      We are computing the available RDS instances in this region.
                      This usually takes a few seconds, please do not close the window.
                    </InlineAlert>
                  )}
                  {!instancesLoading && instances?.length > 0 && (
                    <FormField
                      validationMessage={errors.db_identifier}
                      width="100%"
                      label="Instances">
                      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
                      <Combobox
                        items={instances}
                        selectedItem={values.db_identifier}
                        onChange={(selected) => formik.setFieldValue('db_identifier', selected)}
                        width="25%"
                        openOnFocus
                        data-testid="db_identifier"
                    />
                      </Pane>
                    </FormField>
                  )}
                  {values.db_identifier && (
                    <>
                      <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                        <FormField
                          label="Username"
                          validationMessage={errors.username}
                          width="100%"
                          hint='Username of the new MySQL user'>
                          <TextInput
                            data-testid="username"
                            name="username"
                            width="100%"
                            onChange={handleChange}
                            value={values.username}
                            isInvalid={errors.username && true}/>
                        </FormField>
                    </Pane>
                    <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                        <FormField
                          label="Email"
                          validationMessage={errors.user_email}
                          width="100%"
                          hint="Email of the user that will receive the credentials. If you want to change it, click the 'Load user from Google' button">
                          <TextInput
                            data-testid="user_email"
                            name="user_email"
                            width="100%"
                            onChange={handleChange}
                            value={values.user_email}
                            isInvalid={errors.user_email && true}/>
                        </FormField>
                    </Pane>
                    </>
                  )}
                </Pane>
              )}
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border="default"
          marginTop={majorScale(2)}>
          <Button type="button" disabled={formik.isSubmitting} marginRight={majorScale(2)}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
}  

export default CreateMySQLUserForm;
