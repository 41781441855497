import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const GoogleCreateGroupDetails = ({ ticket }) => (
  <SectionCard title="Group Details">
    <TextDataField
      name="Group Name"
      value={ticket && ticket.ticket_data.group_name} />
    <TextDataField
      name="Group Email"
      value={ticket && ticket.ticket_data.group_email} />
    <TextDataField
      name="Group Description"
      value={ticket && ticket.ticket_data.group_description} />
    <TextDataField
      name="Owner Email"
      value={ticket && ticket.ticket_data.owner_email} />
  </SectionCard>
);

GoogleCreateGroupDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
GoogleCreateGroupDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
