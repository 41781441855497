import React, { useState } from 'react';
import {
  Pane,
  Button,
  Dialog,
  majorScale,
  ExportIcon,
  SelectField,
  TextInputField,
  FormField,
} from 'evergreen-ui';
import { useFormik } from 'formik';
import { SelectUserInput } from '../inputs';
import { api } from '../../services/api';

const ExportTicketsDialog = () => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  const submit = async (values) => {
    setLoading(true);
    // eslint-disable-next-line no-underscore-dangle
    const _values = values;
    _values.ticket_created = values.ticket_created_start && values.ticket_created_end ? `${values.ticket_created_start}:${values.ticket_created_end}` : null;
    _values.ticket_updated = values.ticket_updated_start && values.ticket_updated_end ? `${values.ticket_updated_start}:${values.ticket_updated_end}` : null;
    delete _values.ticket_created_start;
    delete _values.ticket_created_end;
    delete _values.ticket_updated_start;
    delete _values.ticket_updated_end;
    
    try {
      const response = await api.tickets.export(values);
      const a = document.createElement('a');
      a.download = `result.${_values.export_type}`;
      a.href = URL.createObjectURL(new Blob([response.data]));
      a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
      });
      a.click();
      setLoading(false);
      setDialogOpened(false);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      export_type: 'csv',
      ticket_status: null,
      ticket_type: null,
      ticket_creator: null,
      ticket_updater: null,
      ticket_created_start: null,
      ticket_created_end: null,
      ticket_updated_start: null,
      ticket_updated_end: null,
    },
    onSubmit: (values) => submit(values),
    validateOnChange: true,
  });
  const { values, handleChange } = formik;

  return (
    <>
      <Button
        iconAfter={ExportIcon}
        onClick={() => {
          setDialogOpened(true);
        }}>
        Create an export
      </Button>

      <Dialog
        isShown={dialogOpened}
        title="Export tickets"
        onCloseComplete={() => setDialogOpened(false)}
        onCancel={(close) => onCancel(close)}
        onConfirm={(close) => formik.submitForm(close)}
        width="75%"
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
          paddingBottom: majorScale(4),
        }}
        confirmLabel={loading ? 'Loading...' : 'Export'}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <form onSubmit={formik.handleSubmit}>

          <SelectField
            label="Ticket status"
            description="Filter by ticket status"
            width="50%"
            name="ticket_status"
            onChange={handleChange}
            value={values.ticket_status}
            disabled={loading}>
            <option value="all" selected>All types</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="declined">Declined</option>
          </SelectField>

          <TextInputField
            label="Ticket type"
            name="ticket_type"
            onChange={handleChange}
            value={values.ticket_type}
            disabled={loading}
            description="Filter by ticket type"
            hint="You can get the value from the 'type' url param in the ticket creation page"
            width="50%" />

          <Pane display="flex" alignItems="center" width="100%">
            <FormField
              label="Creator"
              name="ticket_creator"
              description="Filter by the user who created the ticket"
              width="50%">
              <SelectUserInput onChange={(value) => formik.setFieldValue('ticket_creator', value.id)} disabled={loading} />
            </FormField>

            <FormField
              label="Updater"
              name="ticket_updater"
              description="Filter by the user who updated the ticket"
              width="50%"
              marginLeft={majorScale(2)}>
              <SelectUserInput onChange={(value) => formik.setFieldValue('ticket_updater', value.id)} disabled={loading} />
            </FormField>
          </Pane>

          <Pane width="50%" marginTop={majorScale(3)}>
            <FormField
              label="Ticket creation"
              description="Select the date range to filter by ticket creation date">
              <Pane display="flex" alignItems="center">
                <TextInputField
                  width="50%"
                  type="date"
                  label=""
                  name="ticket_created_start"
                  onChange={handleChange}
                  value={values.ticket_created_start}
                  disabled={loading} />
                <TextInputField
                  width="50%"
                  type="date"
                  label=""
                  name="ticket_created_end"
                  onChange={handleChange}
                  value={values.ticket_created_end}
                  disabled={loading} />
              </Pane>
            </FormField>
          </Pane>

          <Pane width="50%">
            <FormField
              label="Last update"
              description="Select the date range to filter by ticket last update date">
              <Pane display="flex" alignItems="center">
                <TextInputField
                  width="50%"
                  type="date"
                  label=""
                  name="ticket_updated_start"
                  onChange={handleChange}
                  value={values.ticket_updated_start}
                  disabled={loading} />
                <TextInputField
                  width="50%"
                  type="date"
                  label=""
                  name="ticket_updated_end"
                  onChange={handleChange}
                  value={values.ticket_updated_end}
                  disabled={loading} />
              </Pane>
            </FormField>
          </Pane>

          <SelectField
            label="Export format"
            name="export_type"
            onChange={handleChange}
            value={values.export_type}
            description="Select the format you want this search to be exported"
            maxWidth="50%"
            disabled={loading}>
            <option value="csv" selected>CSV</option>
            <option value="pdf">PDF</option>
          </SelectField>
        </form>

      </Dialog>
    </>
  );
};

export default ExportTicketsDialog;
