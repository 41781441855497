import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const AddUserToRepoDetails = ({ ticket }) => (
  <SectionCard title="Request Information">
    <TextDataField
      name="User name"
      value={ticket && ticket.ticket_data.user_name} />
    <TextDataField
      name="Repo name"
      value={ticket && ticket.ticket_data.repo_name} />
  </SectionCard>
);

AddUserToRepoDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
AddUserToRepoDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
