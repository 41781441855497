import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  Card,
  Badge,
  majorScale,
  Pagination,
  toaster,
  InlineAlert,
  Link,
  ShareIcon,
  minorScale,
  Tooltip,
  Pill,
} from 'evergreen-ui';
import { api } from '../../../services/api';
import SectionCard from '../../../components/section-card';
import { TextDataField, BadgesDataField, BaseDataField } from '../../../components/data-fields';

const GithubAccess = () => {
  const [loading, setLoading] = useState(true);
  const [orgRole, setOrgRole] = useState(null);
  const [teams, setTeams] = useState([]);
  const [repos, setRepos] = useState([]);
  const [totalReposPages, setTotalReposPages] = useState(0);
  const [currentReposPage, setCurrentReposPage] = useState(1);

  let unmounted = false;
  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await api.github.computeUserAccess();
      if (!unmounted) {
        setOrgRole(data.org_role);
        setTeams(data.teams);
        setRepos(data.repositories);
        setTotalReposPages(repos.length / 25);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger(error.response.data.message);
      if (!unmounted) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <SectionCard title="Github">
      <Pane>
        {loading && (
          <InlineAlert intent="none" marginBottom={16}>
            We are computing your GitHub access.
            This may take some minutes, please do not close the window.
          </InlineAlert>
        )}
        <TextDataField
          name="Organization role"
          loading={loading}
          value={orgRole} />

        <BadgesDataField
          name="My Teams"
          loading={loading}
          items={teams} />

        <BaseDataField name="Repository access" loading={loading}>
          <Text marginTop={minorScale(2)}>
            You have access to
            {' '}
            <Pill display="inline-flex">{repos.length}</Pill>
            {' '}
            repositories
          </Text>
          {repos.sort((repoA, repoB) => {
            if (repoA.name.toLowerCase() < repoB.name.toLowerCase()) return -1;
            if (repoA.name.toLowerCase() > repoB.name.toLowerCase()) return 1;
            return 0;
          }).slice(
            (currentReposPage - 1) * 25,
            (currentReposPage - 1) * 25 + 25,
          ).map((repo) => (
            <Card
              key={repo.name}
              marginY={majorScale(2)}
              padding={majorScale(2)}
              backgroundColor="#FFF"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              border="default">
              <Pane display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Text>
                  {repo.name}
                  {' '}
                  <Tooltip content="Open repository in a new tab">
                    <Link href={repo.url} target="_blank" marginLeft={minorScale(1)}><ShareIcon size="12px" /></Link>
                  </Tooltip>
                </Text>
                <Badge color={repo.permission.toLowerCase() === 'write' ? 'blue' : 'neutral'} marginRight={8}>
                  {repo.permission.toUpperCase()}
                </Badge>
              </Pane>
            </Card>
          ))}

          {repos.length > 25
            && (
            <Pagination
              page={currentReposPage}
              totalPages={totalReposPages}
              onPreviousPage={() => setCurrentReposPage(currentReposPage - 1)}
              onNextPage={() => setCurrentReposPage(currentReposPage + 1)} />
            )}
        </BaseDataField>
      </Pane>
    </SectionCard>
  );
};

export default GithubAccess;
