import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, SelectField, Text, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';

const NewVPNAccountForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);
  const currentUser = useSelector((state) => state.authentication.user);

  const formik = useFormik({
    initialValues: {
      email: currentUser.email,
      organization: 'BI',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required'),
      organization: Yup.string().required('Organization is required'),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('email', user.email);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Request for new VPN credentials</Heading>
            </Pane>
            <Pane>
              <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)}>
            <Checkbox
              label="This account is not for me and the user does not exist in Google"
              checked={allowEdit}
              onChange={(e) => setAllowEdit(e.target.checked)} />
            <FormField
              label="Email"
              marginTop={majorScale(2)}
              maxWidth="50%"
              validationMessage={errors.email}>
              <TextInput
                data-testid="email"
                name="email"
                width="100%"
                onChange={handleChange}
                value={values.email}
                readOnly={!allowEdit}
                isInvalid={errors.email && true} />
            </FormField>
            <SelectField
              label="Organization"
              hint="Access level depends on the organization you select"
              validationMessage={errors.organization}
              data-testid="organization"
              name="organization"
              maxWidth="50%"
              marginTop={majorScale(2)}
              onChange={handleChange}
              value={values.organization}
              isInvalid={errors.organization && true}>
              <option value="BI">Business Intelligence</option>
              <option value="Customer_Solutions">Customer Solutions</option>
              <option value="External">External</option>
              <option value="Finance">Finance</option>
              <option value="Internet">Internet</option>
              <option value="Marketing">Marketing</option>
              <option value="Partners">Partners</option>
              <option value="People">People</option>
              <option value="Product">Product</option>
              <option value="Revenue">Revenue</option>
              <option value="Tech">Tech</option>
            </SelectField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default NewVPNAccountForm;
