import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import FormWizard from '../../../../../components/form-wizard';
import Steps from './steps';
import { validateFields } from './validate';
import { api } from '../../../../../services/api';

const OnboardingForm = ({ submit }) => {
  const formWizard = useRef(null);
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const { data } = await api.settings.get('onboarding_ticket');
        setCustomFields(data.custom_fields);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, []);

  const submitForm = async (values) => {
    const formData = {
      first_name: values.first_name,
      last_name: values.last_name,
      personal_email: values.personal_email,
      jampp_email: values.jampp_email,
      team: values.team.id,
      mobile_number: values.mobile_number || null,
      join_date: values.join_date || null,
      experience: values.experience || null,
      job_title: values.job_title || null,
      business_unit: values.business_unit || null,
      department: values.team.id || null,
      reporting_manager: values.reporting_manager || null,
      address: values.address || null,
      google_org_unit: values.google_org_unit.path,
      location: values.location.id,
      assets: _.values(values.assets).map((a) => a.id),
      accounts: _.keys(values.accounts),
      email_groups: values.email_groups,
      predefined_emails: _.values(values.predefined_emails).map((pe) => pe.id),
      organization: values.organization || null,
    };

    submit(formData);
  };

  const formik = useFormik({
    initialValues: {
      accounts: {
        google: true,
        snipe: true,
      },
    },
    onSubmit: submitForm,
    validate: (values) => validateFields(values, formWizard.current.getCurrentStep(), customFields),
    validateOnChange: true,
  });

  const steps = [
    Steps.UserData(formik, customFields),
    Steps.Accounts(formik),
    Steps.Emails(formik),
    Steps.Assets(formik),
    Steps.Review(formik, customFields),
  ];

  return (
    <FormWizard
      steps={steps}
      ref={formWizard}
      onSubmit={formik.handleSubmit}
      nextDisabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting} />
  );
};

export default OnboardingForm;
