import React from 'react';
import { Text } from 'evergreen-ui';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const ModifyEC2SandboxDetails = ({ ticket }) => (
  <>
    <SectionCard title="Instance information">
      <TextDataField
        name="Region"
        value={ticket && ticket.ticket_data.aws_region} />
      <TextDataField
        name="Instance id"
        value={ticket && ticket.ticket_data.instance_id} />
    </SectionCard>

    <SectionCard title="Change specifications">
      <TextDataField
        name="Instance type"
        value={
          ticket && ticket.ticket_data.instance_type.value
            ? ticket.ticket_data.instance_type.value
            : <Text color="muted">Do not change</Text>
        } />
      <TextDataField
        name="EBS disk size"
        value={
          ticket && ticket.ticket_data.ebs_disk.value
            ? `${ticket.ticket_data.ebs_disk.value} GiB`
            : <Text color="muted">Do not change</Text>
        } />
    </SectionCard>
  </>
);

ModifyEC2SandboxDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
ModifyEC2SandboxDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
