import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const AddUserToTeamDetails = ({ ticket }) => (
  <SectionCard title="Personal Information">
    <TextDataField
      name="Team name"
      value={ticket && ticket.ticket_data.team_name} />
    <TextDataField
      name="User name"
      value={ticket && ticket.ticket_data.user_name} />
  </SectionCard>
);

AddUserToTeamDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
AddUserToTeamDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
