/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Pane,
  majorScale,
  SearchInput,
  minorScale,
  toaster,
  Badge,
  Link,
  Text,
  IconButton,
  ChevronRightIcon,
  Spinner,
  Select,
  Pagination,
} from 'evergreen-ui';
import { Link as ReactRouterLink, useSearchParams, useNavigate } from 'react-router-dom';
import getStatusColor from '../../assets/scripts/getStatusColor';
import appTypes from '../../constants/types';
import { api } from '../../services/api';
import NoResults from '../../components/no-results';
import './index.scss';
import { stickyExpandOnScroll } from '../../assets/scripts/sticky';

const TicketsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tickets, setTickets] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  async function fetchData(status = 'pending') {
    setLoading(true);
    try {
      const { data } = await api.tickets.listMyTickets(status, searchParams.get('page') || 1, searchTerm);
      setTickets(data.data);
      setMetadata(data.metadata);
    } catch (error) {
      console.error('Error fetching data:', error);
      toaster.danger('There was an error while processing your request');
    } finally {
      setLoading(false);
    }
  }

  const handleNextPage = () => {
    const nextPage = metadata.page + 1;
    if (nextPage <= metadata.page_count) {
      searchParams.set('page', nextPage);
      setSearchParams(searchParams);
    }
  };

  const handlePreviousPage = () => {
    const previousPage = metadata.page - 1;
    if (previousPage >= 1) {
      searchParams.set('page', previousPage);
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = (pageNumber) => {
    const nextPage = parseInt(pageNumber, 10);
    if (nextPage >= 1 && nextPage <= metadata.page_count) {
      searchParams.set('page', nextPage);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    stickyExpandOnScroll('-24px');
  }, []);

  useEffect(() => {
    const event = setTimeout(() => {
      fetchData();
    }, 500)

    return () => clearTimeout(event);
  }, [searchTerm]);

  return (
    <Pane>
      <Pane
        id="jampp-sticky-expand"
        padding={majorScale(2)}
        marginBottom={majorScale(4)}
        elevation={0}
        position="sticky"
        top="-24px"
        backgroundColor="white"
        zIndex="9"
        marginX={-1}>
        <Pane display="flex" alignItems="center">
          <SearchInput
            placeholder="Search"
            maxWidth="50%"
            display="flex"
            alignItems="center"
            marginRight={minorScale(1)}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <div>
            <Select
              width="auto"
              height={36}
              marginX={minorScale(1)}
              defaultValue="pending"
              onChange={(e) => fetchData(e.target.value)}>
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="declined">Declined</option>
            </Select>
          </div>
        </Pane>
      </Pane>
      {loading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : tickets.length < 1 ? (
        <NoResults message="There are no tickets to show" buttonText="Create a new ticket" buttonAction={() => navigate('/tickets/create')} />
      ) : (
        <>
          {tickets.map((t) => (
            <Ticket key={t.id} ticket={t} />
          ))}
        </>
      )}
      <Pagination page={metadata.page} totalPages={metadata.page_count} onPageChange={handlePageChange} onNextPage={handleNextPage} onPreviousPage={handlePreviousPage} className="pagination"></Pagination>
    </Pane>
  );
};

const Ticket = ({ ticket }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    background="white"
    marginY={majorScale(2)}
    padding={majorScale(2)}
    borderRadius="3px">
    <Pane>
      <Badge color={getStatusColor(ticket.status)}>{ticket.status}</Badge>
      <Text marginX={majorScale(2)}>{ticket.title}</Text>
    </Pane>
    <Pane display="flex" alignItems="center">
      <Link to={`/tickets/${ticket.id}`} is={ReactRouterLink}>
        <IconButton icon={ChevronRightIcon} marginRight={majorScale(2)} />
      </Link>
    </Pane>
  </Pane>
);

Ticket.propTypes = {
  ticket: appTypes.ticket.props,
};

Ticket.defaultProps = {
  ticket: appTypes.ticket.default,
};

export default TicketsPage;
