import React, { useEffect, useState } from 'react';
import { Pane, toaster, Heading, Card, majorScale, Avatar, Text, minorScale } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseSheet from '../../../../components/sidesheets/base';
import { searchParams } from '../../../../services/history';
import { api } from '../../../../services/api';
import {
  BadgesDataField,
  TextDataField,
} from '../../../../components/data-fields';
import appTypes from '../../../../constants/types';

const DetailsSheet = ({ visible, close }) => {
  const roleId = searchParams.get('selected');
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.roles.get(roleId);
        setRole(data);
        setLoading(false);
      } catch (error) {
        toaster.danger('There was an error while proccessing your request');
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <BaseSheet.Details
      close={close}
      visible={visible}
      title={role && role.name}
      loading={loading}
      creator={role && role.created_by}
      created={role && role.created_at}
      updated={role && role.updated_at}
      extraCards={(
        <Pane>
          <RolePermissions loading={loading} permissions={role && role.permissions} />
          <UsersWithRole id={roleId} />
        </Pane>
      )}>
      <Pane>
        <TextDataField
          name="Name"
          loading={loading}
          value={role && role.name} />
        <TextDataField
          name="Active Directory Group"
          loading={loading}
          value={role && role.ad_mapping} />
      </Pane>
    </BaseSheet.Details>
  );
};

const UsersWithRole = ({ id }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.roles.getMembers(id);
        setMembers(data);
      } catch (error) {
        toaster.danger('There was an error while proccessing your request');
      }
    }

    fetchData();
  }, []);

  return (
    <Card backgroundColor="white" elevation={0} padding={majorScale(2)} marginTop={majorScale(2)}>
      <Heading size={400}>Users with this role</Heading>
      {members.map((member) => (
        <Pane key={member.id} display="flex" alignItems="center" marginTop={minorScale(3)}>
          <Avatar name={member.name} />
          <Text marginLeft={minorScale(1)}>{member.name}</Text>
        </Pane>
      ))}
    </Card>
  );
};

const RolePermissions = ({ loading, permissions }) => (
  <Card backgroundColor="white" elevation={0} padding={majorScale(2)} marginTop={majorScale(2)}>
    <Heading size={500} marginBottom={majorScale(1)}>Permissions</Heading>
    {(loading || !permissions) ? [1, 2, 3, 4].map((i) => (
      <BadgesDataField key={i} loading />
    )) : Object.keys(permissions).map((perm) => (
      <BadgesDataField key={perm} name={perm} items={permissions[perm].actions} />
    ))}
  </Card>
);

DetailsSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

RolePermissions.propTypes = {
  loading: PropTypes.bool,
  permissions: appTypes.role.props.permissions,
};

UsersWithRole.propTypes = {
  id: PropTypes.number.isRequired,
};

export default DetailsSheet;
