/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState: {
    pendingTickets: [],
    resolvedTickets: [],
  },
  reducers: {
    setTickets: (state, payload) => {
      const pendingTicketsList = [];
      const resolvedTicketsList = [];
      payload.payload.forEach((ticket) => {
        if (ticket.status === 'pending') {
          pendingTicketsList.push(ticket);
        } else {
          resolvedTicketsList.push(ticket);
        }
        state.pendingTickets = pendingTicketsList;
        state.resolvedTickets = resolvedTicketsList;
      });
    },
  },
});

export const { setTickets } = ticketSlice.actions;

export default ticketSlice.reducer;
