import React from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput, Link } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link as ReactRouterLink } from 'react-router-dom';

const NewADUser = ({ submit }) => {
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      email: Yup.string().required('Email is required').email('Must be a valid email'),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>New Active Directory user</Heading>
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)}>
            <FormField label="First name" marginTop={majorScale(2)} validationMessage={errors.name}>
              <TextInput
                data-testid="first_name"
                name="first_name"
                onChange={handleChange}
                value={values.name}
                isInvalid={errors.name && true} />
            </FormField>
            <FormField label="Last name" marginTop={majorScale(2)} validationMessage={errors.name}>
              <TextInput
                data-testid="last_name"
                name="last_name"
                onChange={handleChange}
                value={values.name}
                isInvalid={errors.name && true} />
            </FormField>
            <FormField
              label="Email"
              marginTop={majorScale(2)}
              maxWidth="50%"
              validationMessage={errors.email}>
              <TextInput
                data-testid="email"
                name="email"
                width="100%"
                onChange={handleChange}
                value={values.email}
                isInvalid={errors.email && true} />
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Link to="/tickets/create" is={ReactRouterLink}>
            <Button type="button" disabled={formik.isSubmitting}>
              Cancel
            </Button>
          </Link>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default NewADUser;
