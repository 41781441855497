import React from 'react';
import {
  Pane, majorScale, TextInput, FormField,
} from 'evergreen-ui';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  SelectTeamInput,
  SelectLocationInput,
} from '../../../../../../components/inputs';

const UserDataStep = (formik, customFields) => {
  const {
    values, errors, handleChange, setFieldValue,
  } = formik;

  return {
    title: 'User data',
    body: (
      <Pane display="grid" gridTemplateColumns="repeat(4, 1fr)" gridGap={majorScale(2)}>
        <FormField label="First name" validationMessage={errors.first_name}>
          <TextInput
            data-testid="first_name"
            name="first_name"
            onChange={handleChange}
            defaultValue={values.first_name}
            isInvalid={errors.first_name && true} />
        </FormField>

        <FormField label="Last name" validationMessage={errors.last_name}>
          <TextInput
            data-testid="last_name"
            name="last_name"
            onChange={handleChange}
            defaultValue={values.last_name}
            isInvalid={errors.last_name && true} />
        </FormField>

        <FormField label="Personal email" validationMessage={errors.personal_email}>
          <TextInput
            data-testid="personal_email"
            name="personal_email"
            onChange={handleChange}
            defaultValue={values.personal_email}
            isInvalid={errors.personal_email && true} />
        </FormField>

        <FormField label="Team" validationMessage={errors.team}>
          <SelectTeamInput
            defaultValue={values.team}
            onChange={(selected) => {
              const departmentName = selected.name;
              formik.setFieldValue('team', selected);
              formik.setFieldValue('department', departmentName);
              console.log(departmentName);
            }} />

        </FormField>

        <FormField label="Location" validationMessage={errors.location}>
          <SelectLocationInput
            defaultValue={values.location}
            onChange={(selected) => formik.setFieldValue('location', selected)} />
        </FormField>

        {customFields.includes('mobile_number') && (
          <FormField label="Mobile Number" validationMessage={errors.mobile_number}>
            <PhoneInput
              country={'in'}
              value={values.mobile_number}
              onChange={(phone) => setFieldValue('mobile_number', phone)}
              inputProps={{
                name: 'mobile_number',
                required: true,
                'data-testid': 'mobile_number',
              }}
              isValid={!errors.mobile_number}
            />
          </FormField>
        )}

        {customFields.includes('join_date') && (
          <FormField label="Date of Joining" validationMessage={errors.join_date}>
            <TextInput
              data-testid="join_date"
              name="join_date"
              type="date"
              width="40%"
              onChange={handleChange}
              defaultValue={values.join_date}
              isInvalid={errors.join_date && true} />
          </FormField>
        )}

        {customFields.includes('experience') && (
          <FormField label="Experience (In years)" validationMessage={errors.experience}>
            <TextInput
              data-testid="experience"
              name="experience"
              onChange={handleChange}
              defaultValue={values.experience}
              isInvalid={errors.experience && true} />
          </FormField>
        )}

        {customFields.includes('job_title') && (
          <FormField label="Designation (Job title)" validationMessage={errors.job_title}>
            <TextInput
              data-testid="job_title"
              name="job_title"
              onChange={handleChange}
              defaultValue={values.job_title}
              width="100%"
              isInvalid={errors.job_title && true} />
          </FormField>
        )}

        {customFields.includes('business_unit') && (
          <FormField label="Platform & BU" validationMessage={errors.business_unit}>
            <TextInput
              data-testid="business_unit"
              name="business_unit"
              onChange={handleChange}
              defaultValue={values.business_unit}
              isInvalid={errors.business_unit && true} />
          </FormField>
        )}

        {customFields.includes('department') && (
          <FormField label="Department" validationMessage={errors.department}>
            <TextInput
              data-testid="department"
              name="department"
              value={values.department}
              isDisabled={true}
              readOnly
            />
          </FormField>
        )}

        {customFields.includes('reporting_manager') && (
          <FormField label="Reporting Manager" validationMessage={errors.reporting_manager}>
            <TextInput
              data-testid="reporting_manager"
              name="reporting_manager"
              width="100%"
              onChange={handleChange}
              defaultValue={values.reporting_manager}
              isInvalid={errors.reporting_manager && true} />
          </FormField>
        )}

        {customFields.includes('address') && (
          <FormField label="Laptop Delivery Address" validationMessage={errors.address}>
            <TextInput
              data-testid="address"
              name="address"
              onChange={handleChange}
              width="100%"
              defaultValue={values.address}
              isInvalid={errors.address && true} />
          </FormField>
        )}
      </Pane>
    ),
  };
};

export default UserDataStep;
