import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, SelectField, Text, TextInput } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';

const NewSilverAccountForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);
  const currentUser = useSelector((state) => state.authentication.user);

  const formik = useFormik({
    initialValues: {
      name: currentUser.name,
      email: currentUser.email,
      role: 8,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required').email('Must be a valid email'),
      role: Yup.number().required('Role is required'),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('name', user.full_name);
      formik.setFieldValue('email', user.email);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Request a new Silver account</Heading>
            </Pane>
            <Pane>
              <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)}>
            <Checkbox
              label="This account is not for me and the user does not exist in Google"
              checked={allowEdit}
              onChange={(e) => setAllowEdit(e.target.checked)} />
            <FormField label="Name" validationMessage={errors.name}>
              <TextInput
                data-testid="name"
                name="name"
                onChange={handleChange}
                value={values.name}
                readOnly={!allowEdit}
                isInvalid={errors.name && true} />
            </FormField>
            <FormField
              label="Email"
              marginTop={majorScale(2)}
              maxWidth="50%"
              validationMessage={errors.email}>
              <TextInput
                data-testid="email"
                name="email"
                width="100%"
                onChange={handleChange}
                value={values.email}
                readOnly={!allowEdit}
                isInvalid={errors.email && true} />
            </FormField>
            <SelectField
              label="Role"
              hint="Access level depends on the role you select"
              validationMessage={errors.role}
              data-testid="role"
              name="role"
              maxWidth="50%"
              marginTop={majorScale(2)}
              onChange={handleChange}
              value={values.role}
              isInvalid={errors.role && true}>
              <option value="8">Account Manager</option>
              <option value="1">Admin</option>
              <option value="2">Advertiser</option>
              <option value="7">Advertiser Admin</option>
              <option value="9">Advertiser API</option>
              <option value="5">Campaign Manager</option>
              <option value="6">Financial Manager</option>
              <option value="4">Sales Manager</option>
              <option value="3">Source</option>
            </SelectField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default NewSilverAccountForm;
