import React, { useState } from 'react';
import {
  Pane, majorScale, TextInput, FormField, toaster,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { api } from '../../../../services/api';
import BaseSheet from '../../../../components/sidesheets/base';
import { CustomSelect, CustomTagInput } from '../../../../components/inputs';
import { countriesForSelect } from '../../../../constants/countries';
import formHelp from '../../../../constants/help';

const CreateLocationSheet = ({ visible, close }) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const validateForm = (values) => {
    const errors = {};
    if (!values.country) {
      errors.country = 'This field is required';
    }
    if (!values.name || values.name.length < 1) {
      errors.name = 'This field is required';
    }
    return errors;
  };

  const submitForm = async (values) => {
    setSubmitLoading(true);
    const data = values;
    data.country_code = data.country.value;

    try {
      await api.locations.create(data);
      setSubmitLoading(false);
      toaster.success('Location created successfully');
      close();
    } catch (error) {
      setSubmitLoading(false);
      toaster.danger('There was an error while proccessing your request');
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: submitForm,
    validate: validateForm,
    validateOnChange: true,
  });

  const form = (
    <Pane>
      <FormField
        label="Country"
        maxWidth="50%"
        validationMessage={formik.errors.country}>
        <CustomSelect
          items={countriesForSelect()}
          onChange={(selected) => formik.setFieldValue('country', selected)} />
      </FormField>
      <FormField
        label="Location name"
        marginTop={majorScale(2)}
        validationMessage={formik.errors.name}>
        <TextInput
          name="name"
          onChange={formik.handleChange}
          isInvalid={formik.errors.name && true} />
      </FormField>
      <FormField label="Location aliases" marginTop={majorScale(2)}>
        <CustomTagInput
          onChange={(values) => formik.setFieldValue('aliases', values)} />
      </FormField>
    </Pane>
  );

  return (
    <BaseSheet.Create
      form={form}
      close={close}
      help={formHelp.location}
      visible={visible}
      submitLoading={submitLoading}
      submitDisabled={Object.keys(formik.errors).length > 0}
      title="Create a new location"
      onSubmit={formik.handleSubmit} />
  );
};

CreateLocationSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CreateLocationSheet;
