import React, { useState, useEffect } from 'react';
import {
  Popover,
  Button,
  Menu,
  Position,
  ChevronDownIcon,
  ChevronUpIcon,
} from 'evergreen-ui';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import SectionCard from '../../../../components/section-card';
import { api } from '../../../../services/api';

const CreatedTicketsChart = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [metricData, setMetricData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(7);

  let unmounted = false;
  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.metrics.createdTickets(selectedOption);
      if (!unmounted) {
        setMetricData(data);
        setLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();

    return () => {
      unmounted = true;
    };
  }, [selectedOption]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const chartData = {
    labels: Object.keys(metricData),
    datasets: [
      {
        data: Object.values(metricData),
        borderColor: '#3366FF',
        pointRadius: 5,
        pointHoverRadius: 10,
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.20,
      },
    },
  };

  const optionsMenu = (
    <Menu>
      <Menu.Option
        onSelect={() => setSelectedOption(7)}
        isSelected={selectedOption === 7}>
        1 Week
      </Menu.Option>
      <Menu.Option
        onSelect={() => setSelectedOption(14)}
        isSelected={selectedOption === 14}>
        2 Weeks
      </Menu.Option>
      <Menu.Option
        onSelect={() => setSelectedOption(30)}
        isSelected={selectedOption === 30}>
        1 Month
      </Menu.Option>
      <Menu.Option
        onSelect={() => setSelectedOption(90)}
        isSelected={selectedOption === 90}>
        3 Months
      </Menu.Option>
      <Menu.Option
        onSelect={() => setSelectedOption(180)}
        isSelected={selectedOption === 180}>
        6 Months
      </Menu.Option>
    </Menu>
  );

  return (
    <SectionCard
      title="Created tickets"
      button={(
        <Popover
          position={Position.BOTTOM_RIGHT}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          content={optionsMenu}>
          <Button
            size="small"
            iconAfter={menuOpen ? ChevronUpIcon : ChevronDownIcon}
            appearance="minimal">
            1 Week
          </Button>
        </Popover>
      )}>
      <Line
        options={chartOptions}
        data={chartData} />
    </SectionCard>
  );
};

export default CreatedTicketsChart;
