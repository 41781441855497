import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  Card,
  Badge,
  Strong,
  majorScale,
  toaster,
  InlineAlert,
  Pagination,
  TextInputField,
} from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { api } from '../../../services/api';
import SectionCard from '../../../components/section-card';
import { BaseDataField, BadgesDataField } from '../../../components/data-fields';
import { useUserAccess } from '../../../contexts/user-access-content';

const AWSIAMUserAccess = () => {
  const currentUser = useSelector((state) => state.authentication.user);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [groups, setGroups] = useState([]);
  const [serviceAccessDetails, setServiceAccessDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const { setUserAccessLoading } = useUserAccess();
  const [currentPage, setCurrentPage] = useState(1);

  const transformPolicies = (attached, inline, grps) => {
    let processed = {};
    const process = (current, policies) => {
      // eslint-disable-next-line no-underscore-dangle
      const _processed = current;
      Object.entries(policies).forEach(([service, actions]) => {
        const s = service.toLowerCase();
        if (!Object.keys(_processed).includes(s)) {
          _processed[s] = [];
        }

        actions.forEach((action) => {
          const a = action !== '*' ? action.split(':')[1].toLowerCase() : '*';
          if (!_processed[s].includes(a)) {
            _processed[s].push(a);
          }
        });
      });

      return _processed;
    };

    // Process policies
    processed = process(processed, attached);
    processed = process(processed, inline);
    grps.forEach((g) => {
      processed = process(processed, g.AttachedPolicies);
      processed = process(processed, g.InlinePolicies);
    });

    return processed;
  };

  let unmounted = false;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setUserAccessLoading(true);
      try {
        const response = await api.aws.computeIAMUserAccess(currentUser.email);
        if (response && response.data) {
          const { data } = response;
          if (!unmounted) {
            setUsername(data.UserName);
            setGroups(data.GroupPolicies.map((group) => group.GroupName));

            setServiceAccessDetails(
              transformPolicies(data.AttachedPolicies, data.InlinePolicies, data.GroupPolicies)
            );
          }
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An unexpected error occurred';
        toaster.danger(errorMessage);
      } finally {
        if (!unmounted) {
          setLoading(false);
          setUserAccessLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);

  const filteredResults = (term) => Object.keys(serviceAccessDetails).filter((service) => (
    service.includes(term)
    || (serviceAccessDetails[service].filter((action) => action.includes(term))).length > 0
  ));

  return (
    <SectionCard title="AWS Users">
      <Pane>
        {loading && (
          <InlineAlert intent="none" marginBottom={16}>
            We are computing your AWS IAM access.
            This may take some minutes, please do not close the window.
          </InlineAlert>
        )}

        <BadgesDataField
          name="My Teams"
          loading={loading}
          items={groups} />

        <BaseDataField name="AWS Services Access" loading={loading}>
          <Pane>
            {Object.keys(serviceAccessDetails).length > 0 && (
              <TextInputField
                label=""
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                placeholder="Search for permissions..."
                marginBottom={majorScale(3)} />
            )}

            {Object.keys(serviceAccessDetails).length === 0 ? (
              <Text>
                No access to AWS services for user:
                {username}
              </Text>
            ) : (
              filteredResults(searchQuery).sort((serviceA, serviceB) => {
                if (serviceA < serviceB) return -1;
                if (serviceA > serviceB) return 1;
                return 0;
              }).slice(
                (currentPage - 1) * 15,
                (currentPage - 1) * 15 + 15,
              ).map((service) => (
                <Card
                  key={service}
                  marginY={majorScale(2)}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
                  backgroundColor="#FFF"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="default">
                  <Pane width="100%">
                    <Pane><Strong size={400}>{service}</Strong></Pane>
                    <Pane display="flex" flexWrap="wrap" marginTop={majorScale(1)} maxWidth="100%">
                      {serviceAccessDetails[service].map((action) => (
                        <Badge
                          key={action}
                          color={searchQuery.length > 0 && action.includes(searchQuery) ? 'yellow' : 'neutral'}
                          marginRight={majorScale(1)}
                          marginBottom={majorScale(1)}>
                          {action}
                        </Badge>
                      ))}
                    </Pane>
                  </Pane>
                </Card>
              ))
            )}
          </Pane>
          {Object.keys(serviceAccessDetails).length > 15
            && (
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(Object.keys(serviceAccessDetails).length / 15)}
                onPreviousPage={() => setCurrentPage(currentPage - 1)}
                onNextPage={() => setCurrentPage(currentPage + 1)} />
            )}
        </BaseDataField>
      </Pane>
    </SectionCard>
  );
};

export default AWSIAMUserAccess;
