import React, { useState, useEffect } from 'react';
import { toaster } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { api } from '../../../../services/api';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const APRoleChangeDetails = ({ ticket }) => {
  const currentUser = useSelector((state) => state.authentication.user);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('Unable to load');

  const fetchData = async () => {
    try {
      const { data } = await api.roles.list(1, 1000);
      data.data.forEach((r) => {
        if (r.id === ticket.ticket_data.role_id) {
          setRole(`${currentUser.role.name} -> ${r.name}`);
        }
      });
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SectionCard title="Account information">
      <TextDataField
        name="Email"
        loading={loading}
        value={ticket && ticket.ticket_data.email} />
      <TextDataField
        name="Role"
        loading={loading}
        value={ticket && role} />
    </SectionCard>
  );
};

APRoleChangeDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
APRoleChangeDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
