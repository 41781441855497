import React, { useEffect, useState } from 'react';
import { Pane, toaster } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseSheet from '../../../../components/sidesheets/base';
import { searchParams } from '../../../../services/history';
import { api } from '../../../../services/api';
import {
  BadgesDataField,
  TextDataField,
} from '../../../../components/data-fields';

const DetailsSheet = ({ visible, close }) => {
  const teamId = searchParams.get('selected');
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.teams.get(teamId);
        setTeam(data);
        setLoading(false);
      } catch (error) {
        toaster.danger('There was an error while proccessing your request');
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <BaseSheet.Details
      close={close}
      visible={visible}
      title={team && team.name}
      loading={loading}
      creator={team && team.created_by}
      created={team && team.created_at}
      updated={team && team.updated_at}>
      <Pane>
        <TextDataField
          name="Name"
          loading={loading}
          value={team && team.name} />
        <BadgesDataField
          name="Aliases"
          loading={loading}
          items={team && team.aliases} />
      </Pane>
    </BaseSheet.Details>
  );
};

DetailsSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default DetailsSheet;
