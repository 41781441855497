import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const OffboardingDetails = ({ ticket }) => (
  <>
    <SectionCard title="User Data">
      <TextDataField
        name="Jampper Email"
        value={ticket && ticket.ticket_data.email} />
      <TextDataField
        name="Manager Email"
        value={ticket && ticket.ticket_data.manager_email} />
      <TextDataField
        name="Date & Time"
        value={ticket && ticket.ticket_data.datetime} />
    </SectionCard>
    
  </>
);

OffboardingDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
OffboardingDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
