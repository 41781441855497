import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CrossIcon,
  Dialog,
  Label,
  majorScale,
  minorScale, Pane,
  Text,
  Textarea,
  TickIcon,
  toaster,
} from 'evergreen-ui';
import history from '../../services/history';
import { api } from '../../services/api';
import { AuthorizedWrapper } from '../authorized-wrapper';

const UpdateTicketStatusDialog = ({ id }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogIntent, setDialogIntent] = useState('decline');
  const [loading, setLoading] = useState(false);
  const [statusReason, setStatusReason] = useState(null);

  const updateTicketStatus = async (close) => {
    setLoading(true);
    try {
      await api.tickets.review(id, {
        status: dialogIntent === 'approve' ? 'approved' : 'declined',
        status_reason: statusReason,
      });
      setTimeout(() => {
        setLoading(false);
        history.go(0);
        close();
      }, 3000);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <AuthorizedWrapper resource="tickets" action="review">
        <Button
          height={28}
          marginX={minorScale(1)}
          appearance="primary"
          intent="success"
          iconBefore={<TickIcon />}
          onClick={() => {
            setDialogIntent('approve');
            setDialogOpened(true);
          }}>
          Approve
        </Button>
        <Button
          height={28}
          marginX={minorScale(1)}
          appearance="primary"
          intent="danger"
          iconBefore={<CrossIcon />}
          onClick={() => {
            setDialogIntent('decline');
            setDialogOpened(true);
          }}>
          Decline
        </Button>
      </AuthorizedWrapper>

      <Dialog
        isShown={dialogOpened}
        title={dialogIntent === 'approve' ? 'Approve ticket' : 'Decline ticket'}
        intent={dialogIntent === 'approve' ? 'success' : 'danger'}
        onCloseComplete={() => setDialogOpened(false)}
        onConfirm={(close) => updateTicketStatus(close)}
        onCancel={(close) => onCancel(close)}
        isConfirmLoading={loading}
        containerProps={{
          borderRadius: '3px',
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>
        <Text>
          Are you sure you want to
          {' '}
          {dialogIntent}
          {' '}
          this ticket?
        </Text>

        <Pane marginTop={majorScale(2)}>
          <Label htmlFor="reason-textarea" marginBottom={minorScale(1)} display="block">
            Reason (optional):
          </Label>
          <Textarea
            id="reason-textarea"
            disabled={loading}
            onChange={(e) => { setStatusReason(e.target.value); }}
            marginBottom={0}
            spellCheck
            grammarly />
        </Pane>
      </Dialog>
    </>
  );
};

export default UpdateTicketStatusDialog;

UpdateTicketStatusDialog.propTypes = {
  id: PropTypes.number,
};

UpdateTicketStatusDialog.defaultProps = {
  id: null,
};
