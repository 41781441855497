import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './authSlice';
import ticketReducer from './ticketSlice';

export default configureStore({
  reducer: {
    authentication: authenticationReducer,
    tickets: ticketReducer,
  },
});
