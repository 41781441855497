import React from 'react';
import {
  Pane,
  majorScale,
  Heading,
  SideSheet,
  Card,
  Button,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import Skeleton from '../../skeleton';
import SheetHelp from '../components/help';
import appTypes from '../../../constants/types';

const Edit = ({
  visible,
  close,
  title,
  form,
  onSubmit,
  help,
  loading,
  submitLoading,
  submitDisabled,
}) => (
  <SideSheet
    isShown={visible}
    onCloseComplete={close}
    containerProps={{
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    }}
  >
    <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
      <Pane padding={16}>
        {loading ? (
          <Skeleton width="200px" height="24px" />
        ) : (
          <Heading size={600}>Edit {title}</Heading>
        )}
      </Pane>
    </Pane>

    <form style={{ flex: 1, height: '100%' }} onSubmit={onSubmit}>
      <Pane
        flex="1"
        overflowY="scroll"
        background="tint1"
        height="100%"
        padding={16}
      >
        <Card backgroundColor="white" elevation={0} padding={majorScale(2)}>
          {loading ? (
            <>
              <Skeleton width="150px" height="14px" />
              <Skeleton
                width="250px"
                height="18px"
                style={{ marginTop: '4px' }}
              />
              <Skeleton
                width="150px"
                height="14px"
                style={{ marginTop: '16px' }}
              />
              <Skeleton
                width="250px"
                height="18px"
                style={{ marginTop: '4px' }}
              />
            </>
          ) : (
            form
          )}
        </Card>

        <Pane
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginY={majorScale(2)}
        >
          <Pane display="flex" justifyContent="flex-end">
            <Button
              type="button"
              onClick={close}
              disabled={submitLoading || loading}
            >
              Cancel
            </Button>
            <Button
              marginLeft={majorScale(2)}
              appearance="primary"
              type="submit"
              disabled={loading || submitDisabled}
              isLoading={submitLoading}
            >
              Save
            </Button>
          </Pane>
        </Pane>

        {help && <SheetHelp help={help} />}
      </Pane>
    </form>
  </SideSheet>
);

Edit.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  form: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  help: appTypes.help.props,
  loading: PropTypes.bool,
  submitLoading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
};

Edit.defaultProps = {
  title: '',
  help: [],
  loading: false,
  submitLoading: false,
  submitDisabled: false,
};

export default Edit;
