import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  IconButton,
  TrashIcon,
  EyeOpenIcon,
  majorScale,
  SearchInput,
  Button,
  toaster,
  Spinner,
  EditIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import SettingsNavigation from '../navigation';
import CreateTeamSheet from './sidesheets/create';
import EditSheet from './sidesheets/edit';
import DetailsSheet from './sidesheets/details';
import { searchParams } from '../../../services/history';
import { api } from '../../../services/api';
import DeletePopConfirm from '../../../components/popconfirm/delete';
import appTypes from '../../../constants/types';
import { AuthorizedWrapper } from '../../../components/authorized-wrapper';
import { stickyExpandOnScroll } from '../../../assets/scripts/sticky';
import PaginationMenu from '../../../components/pagination-menu';
import NoResults from '../../../components/no-results';

const SettingsTeamsPage = () => {
  const [loading, setLoading] = useState(true);
  const [newOpen, setNewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [teams, setTeams] = useState([]);
  const [metadata, setMetadata] = useState({});

  async function fetchData() {
    setLoading(true);
    try {
      const page = searchParams.get('page') || 1;
      const itemsPerPage = searchParams.get('per_page') || 10;
      const { data } = await api.teams.list(page, itemsPerPage, searchTerm);
      setTeams(data.data);
      setMetadata(data.metadata);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  }

  useEffect(() => {
    stickyExpandOnScroll('-24px');
    const event = setTimeout(() => {
      searchParams.set('search', searchTerm);
      fetchData();
    }, 500);

    return () => clearTimeout(event);
  }, [searchTerm, newOpen, editOpen]); // TODO: Refresh only on close

  const deleteTeam = async (id) => {
    try {
      await api.teams.delete(id);
      fetchData();
    } catch (error) {
      toaster.danger('ERROR');
    }
  };

  return (
    <Pane>
      <SettingsNavigation />
      <Pane
        id="jampp-sticky-expand"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={majorScale(2)}
        marginBottom={majorScale(4)}
        elevation={0}
        position="sticky"
        top="-24px"
        backgroundColor="white"
        zIndex="9"
        marginX={-1}>
        <SearchInput
          placeholder="Search team"
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={loading}
          value={searchTerm}
          display="flex"
          alignItems="center"
          maxWidth="50%" />

        <Pane display="flex" alignItems="center">
          <AuthorizedWrapper resource="teams" action="create">
            <Button
              appearance="primary"
              onClick={() => setNewOpen(true)}
              disabled={loading}>
              Create new team
            </Button>
          </AuthorizedWrapper>
        </Pane>
      </Pane>

      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}>
          <Spinner />
        </Pane>
      )}

      {!loading && teams.length < 1 && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={250}>
          <NoResults
            message="There are no teams to show"
            buttonText="Create a new team"
            buttonAction={() => setNewOpen(true)} />
        </Pane>
      )}

      {!loading && teams.length > 0 && (
        <>
          {teams.map((team) => (
            <Team
              key={team.id}
              team={team}
              openDetails={() => setDetailsOpen(true)}
              edit={() => setEditOpen(true)}
              deleteTeam={deleteTeam} />
          ))}
          <PaginationMenu
            pageMetadata={metadata}
            onPreviousPage={() => fetchData()}
            onNextPage={() => fetchData()}
            onChangePage={() => fetchData()}
            onItemsPerPageChange={() => fetchData()} />
        </>
      )}

      {newOpen && (
        <CreateTeamSheet visible={newOpen} close={() => setNewOpen(false)} />
      )}

      {editOpen && (
        <EditSheet
          visible={editOpen}
          close={() => {
            setEditOpen(false);
            searchParams.remove('selected');
          }} />
      )}

      {detailsOpen && (
        <DetailsSheet
          visible={detailsOpen}
          close={() => {
            setDetailsOpen(false);
            searchParams.remove('selected');
          }} />
      )}
    </Pane>
  );
};

const Team = ({
  team, openDetails, edit, deleteTeam,
}) => {
  const handleOpenDetails = () => {
    searchParams.set('selected', team.id);
    openDetails();
  };

  const handleEdit = () => {
    searchParams.set('selected', team.id);
    edit();
  };

  const handleDelete = () => {
    deleteTeam(team.id);
  };

  return (
    <Pane
      elevation={0}
      display="flex"
      alignItems="center"
      backgroundColor="white"
      justifyContent="space-between"
      marginY={majorScale(2)}
      padding={majorScale(2)}
      borderRadius="3px">
      <Text>{team.name}</Text>

      <Pane display="flex" alignItems="center">
        <AuthorizedWrapper resource="teams" action="details">
          <IconButton
            icon={EyeOpenIcon}
            onClick={handleOpenDetails}
            marginRight={majorScale(2)} />
        </AuthorizedWrapper>
        <AuthorizedWrapper resource="teams" action="update">
          <IconButton
            icon={EditIcon}
            onClick={handleEdit}
            marginRight={majorScale(2)} />
        </AuthorizedWrapper>
        <AuthorizedWrapper resource="teams" action="delete">
          <DeletePopConfirm onConfirm={handleDelete}>
            <IconButton
              icon={TrashIcon}
              intent="danger" />
          </DeletePopConfirm>
        </AuthorizedWrapper>
      </Pane>
    </Pane>
  );
};

Team.propTypes = {
  team: appTypes.team.props.isRequired,
  openDetails: PropTypes.func,
  edit: PropTypes.func,
  deleteTeam: PropTypes.func,
};

Team.defaultProps = {
  openDetails: null,
  edit: null,
  deleteTeam: null,
};

export default SettingsTeamsPage;
