import React, { useState } from 'react';
import { Combobox } from 'evergreen-ui';
import PropTypes from 'prop-types';

const CustomSelect = ({ disabled, items, onChange, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = s => {
    setSelected(s);
    if (onChange) {
      onChange(s);
    }
  };

  return (
    <Combobox
      items={items}
      initialSelectedItem={selected}
      onChange={handleChange}
      itemToString={i => (i ? i.label : '')}
      disabled={disabled}
      width="100%"
      openOnFocus
    />
  );
};

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

CustomSelect.defaultProps = {
  disabled: false,
  items: [],
  onChange: null,
  defaultValue: null,
};

export default CustomSelect;
