export const silverRoles = {
  1: 'Admin',
  2: 'Advertiser',
  3: 'Source',
  4: 'Sales Manager',
  5: 'Campaign Manager',
  6: 'Financial Manager',
  7: 'Advertiser Admin',
  8: 'Account Manager',
  9: 'Advertiser API',
}
