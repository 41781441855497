import React from 'react';
import {
  majorScale, Pane, Tab, TabNavigation,
} from 'evergreen-ui';

const SettingsNavigation = () => (
  <Pane paddingY={majorScale(2)} display="flex" justifyContent="center">
    <TabNavigation>
      <Tab
        is="a"
        href="/settings/email"
        id="email"
        isSelected={window.location.pathname === '/settings/email'}>
        Email
      </Tab>
      <Tab
        is="a"
        href="/settings/teams"
        id="teams"
        isSelected={window.location.pathname === '/settings/teams'}>
        Teams
      </Tab>
      <Tab
        is="a"
        href="/settings/locations"
        id="locations"
        isSelected={window.location.pathname === '/settings/locations'}>
        Locations
      </Tab>
      <Tab
        is="a"
        href="/settings/groups"
        id="groups"
        isSelected={window.location.pathname === '/settings/groups'}>
        Email groups
      </Tab>
      <Tab
        is="a"
        href="/settings/users"
        id="users"
        isSelected={window.location.pathname === '/settings/users'}>
        Users
      </Tab>
      <Tab
        is="a"
        href="/settings/roles"
        id="roles"
        isSelected={window.location.pathname === '/settings/roles'}>
        Roles
      </Tab>
      <Tab
        is="a"
        href="/settings/features"
        id="settings"
        isSelected={window.location.pathname === '/settings/features'}>
        Features
      </Tab>
    </TabNavigation>
  </Pane>
);

export default SettingsNavigation;
