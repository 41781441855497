import React, { useState, useEffect } from 'react';
import {
  Heading,
  majorScale,
  Pane,
  Button,
  Text,
  Popover,
  toaster,
  InfoSignIcon,
  Paragraph,
  Position,
  TextInput,
  FormField,
  Strong,
} from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import validator from 'validator';
import SnipeAsset from '../../../../../../components/snipe-asset';
import accounts from '../../../../../../constants/accounts';
import Skeleton from '../../../../../../components/skeleton';
import { api } from '../../../../../../services/api';
import {
  TextDataField,
  LocationDataField,
  BadgesDataField,
  AccountDataField,
} from '../../../../../../components/data-fields';
import SectionCard from '../../../../../../components/section-card';

const ReviewStep = (formik, customFields) => {
  const { values } = formik;

  return {
    title: 'Review',
    body: (
      <Pane>
        <SectionCard title="Personal information">
          <TextDataField name="First name" value={values.first_name} />
          <TextDataField name="Last name" value={values.last_name} />
          <TextDataField name="Personal email" value={values.personal_email} />
          <TextDataField name="Team" value={values.team && values.team.name} />
          <LocationDataField
            name="Location"
            countryCode={values.location && values.location.country_code}
            countryName={values.location && values.location.name} />
          {customFields.includes('mobile_number') && (
            <TextDataField name="Mobile Number" value={values.mobile_number} />
          )}
          {customFields.includes('join_date') && (
            <TextDataField name="Date of Joining" value={values.join_date} />
          )}
          {customFields.includes('experience') && (
            <TextDataField name="Experience (In years)" value={values.experience} />
          )}
          {customFields.includes('job_title') && (
            <TextDataField name="Designation (Job title)" value={values.job_title} />
          )}
          {customFields.includes('business_unit') && (
            <TextDataField name="Platform & BU" value={values.business_unit} />
          )}
          {customFields.includes('department') && (
            <TextDataField name="Department" value={values.team && values.team.name} />
          )}
          {customFields.includes('reporting_manager') && (
            <TextDataField name="Reporting Manager" value={values.reporting_manager} />
          )}
          {customFields.includes('address') && (
            <TextDataField name="Laptop Delivery Address" value={values.address} />
          )}
        </SectionCard>

        <SectionCard title="Accounts">
          {accounts.map((account) => {
            if (_.keys(values.accounts).includes(account.key)) {
              return (
                <AccountDataField
                  key={account.key}
                  image={account.image}
                  name={account.name} />
              );
            }
            return null;
          })}
        </SectionCard>

        <SectionCard title="Assets">
          {values.assets
            && _.values(values.assets).map((asset) => (
              <SnipeAsset key={asset.id} asset={asset} isSelectable={false} />
            ))}
        </SectionCard>

        <SectionCard title="Generated">
          <JamppEmail formik={formik} />
          <OrganizationalUnit formik={formik} />
          <EmailGroups formik={formik} />
        </SectionCard>
      </Pane>
    ),
  };
};

const OrganizationalUnit = ({ formik }) => {
  const helpPopover = (
    <Pane width={300} padding={majorScale(2)}>
      <Heading size={400}>What is Organizational Unit?</Heading>
      <Paragraph marginTop={majorScale(1)}>
        This is the organizational unit in Google Workspace where the user will be created.
      </Paragraph>
      <Heading marginTop={majorScale(2)} size={400}>Sintax of data shown</Heading>
      <Paragraph marginTop={majorScale(1)}>
        My Org Unit (/My Org Unit)
        <br />
        In this case
        {' '}
        <Strong>My Org Unit</Strong>
        <br />
        {' '}
        is the name of the organizational unit and
        {' '}
        <Strong>(/My Org Unit)</Strong>
        {' '}
        is the path of the unit in Google Workspace
      </Paragraph>
    </Pane>
  );

  return (
    <Pane marginBottom={majorScale(2)}>
      <Heading marginBottom={majorScale(1)} size={400} display="flex" alignItems="center">
        Organizational unit
        <Popover content={helpPopover} position={Position.TOP}>
          <InfoSignIcon size={14} color="muted" marginLeft={majorScale(1)} />
        </Popover>
      </Heading>
      <Text>
        {formik.values.google_org_unit.name}
        {' '}
        <Text color="muted">
          (
          {formik.values.google_org_unit.path}
          )
        </Text>
      </Text>
    </Pane>
  );
};

const JamppEmail = ({ formik }) => {
  const { values } = formik;
  const [loading, setLoading] = useState(true);
  const [emailData, setEmailData] = useState({});

  const fetchData = async () => {
    if (values.jampp_email) {
      delete values.jampp_email;
    }

    try {
      const { data } = await api.google.generateEmail(
        values.first_name,
        values.last_name,
      );
      if (data.is_available) {
        formik.setFieldValue('jampp_email', data.generated);
      }
      setEmailData(data);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setCustomEmail = (email) => {
    formik.setFieldValue('jampp_email', email);
    setEmailData({ ...emailData, generated: email, is_available: true });
  };

  const helpPopover = (
    <Pane width={300} padding={majorScale(2)}>
      <Heading size={400}>Employee email</Heading>
      <Paragraph marginTop={majorScale(1)}>
        This is the email address that will be assigned to the new employee.
        <br />
        <br />
        If the address is already in use by another employee, an
        <Text color="danger"> (already in use)</Text>
        will appear next to it and you must select one of the alternatives
        provided or enter an email address manually.
        <br />
        <br />
        If the email address is not in use, you can still choose another option.
      </Paragraph>
    </Pane>
  );

  return (
    <Pane marginBottom={majorScale(2)}>
      <Heading size={400} display="flex" alignItems="center">
        Employee email
        <Popover content={helpPopover} position={Position.TOP}>
          <InfoSignIcon size={14} color="muted" marginLeft={majorScale(1)} />
        </Popover>
      </Heading>
      {loading ? (
        <>
          <Skeleton width="150px" height="14px" />
          <Skeleton width="250px" height="16px" style={{ marginTop: '4px' }} />
          <Skeleton
            width="250px"
            height="16px"
            style={{ marginTop: '4px', marginBottom: '16px' }} />
        </>
      ) : (
        <>
          <Pane marginTop={majorScale(1)}>
            <Text>
              {emailData.generated}
              {!emailData.is_available && (
                <Text color="danger"> (already in use)</Text>
              )}
            </Text>
          </Pane>

          <Pane marginTop={majorScale(1)}>
            <Text>Alternatives: </Text>
            {emailData.alternatives.map((alt) => (
              <Button
                key={alt}
                size="small"
                type="button"
                marginRight={majorScale(1)}
                onClick={() => setCustomEmail(alt)}>
                {alt}
              </Button>
            ))}
            <Text>or</Text>

            <Popover
              position={Position.TOP}
              content={({ close }) => (
                <EnterManuallyPopover
                  close={close}
                  setCustomEmail={(email) => setCustomEmail(email)} />
              )}>
              <Button type="button" marginLeft={majorScale(1)} size="small">
                Enter manually
              </Button>
            </Popover>
          </Pane>
        </>
      )}
    </Pane>
  );
};

const EnterManuallyPopover = ({ close, setCustomEmail }) => {
  const [available, setAvailable] = useState(true);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  const validate = async () => {
    setLoading(true);
    try {
      const { data } = await api.google.validateEmail(email);
      if (data.is_available) {
        setAvailable(true);
        setValidationMessage(null);
      } else {
        setAvailable(false);
        setValidationMessage('This email is already in use');
      }
    } catch (error) {
      setAvailable(false);
      setValidationMessage('This email is invalid');
    }

    setLoading(false);
  };

  useEffect(() => {
    const event = setTimeout(() => {
      validate();
    }, 500);
    return () => clearTimeout(event);
  }, [email]);

  return (
    <Pane width={300} padding={majorScale(2)}>
      <FormField label="Email" validationMessage={validationMessage}>
        <TextInput
          width="100%"
          type="email"
          placeholder="foo@jampp.com"
          isInvalid={!available}
          onChange={(e) => setEmail(e.target.value)} />
      </FormField>
      <Pane
        marginTop={majorScale(2)}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Button size="small" onClick={close}>
          Cancel
        </Button>
        <Button
          size="small"
          appearance="primary"
          isLoading={loading}
          disabled={loading || !available || email.length < 1}
          onClick={() => {
            setCustomEmail(email);
            close();
          }}>
          {loading ? 'Validating...' : 'Use this email'}
        </Button>
      </Pane>
    </Pane>
  );
};

const EmailGroups = ({ formik }) => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState({});

  const fetchData = async () => {
    try {
      const teamId = formik.values.team.id;
      const locationId = formik.values.location.id;
      const { data } = await api.groups.calculate(teamId, locationId);
      formik.setFieldValue('email_groups', data);
      setGroups(data);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BadgesDataField
      name="Email groups"
      items={groups && groups}
      emptyMessage="No email groups"
      loading={loading} />
  );
};

export default ReviewStep;

EnterManuallyPopover.propTypes = {
  close: PropTypes.func.isRequired,
  setCustomEmail: PropTypes.func.isRequired,
};

JamppEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
};

EmailGroups.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
};
