import React, { useEffect, useState } from 'react';
import { Pane, toaster, Card, majorScale, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseSheet from '../../../../../../components/sidesheets/base';
import { searchParams } from '../../../../../../services/history';
import { api } from '../../../../../../services/api';
import {
  BadgesDataField,
  TextDataField,
  BaseDataField,
} from '../../../../../../components/data-fields';

const PredefinedEmailDetailsSheet = ({ visible, close }) => {
  const id = searchParams.get('selected');
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(null);

  async function fetchData() {
    try {
      const { data } = await api.email.predefined.details(id);
      setEmail(data);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const templateValues = () => {
    const values = [];
    Object.entries(email.template_values).forEach(([key, value]) => {
      values.push(`${key}=${value}`);
    });
    return values;
  };

  return (
    <BaseSheet.Details
      close={close}
      visible={visible}
      title={email && email.name}
      loading={loading}
      creator={email && email.created_by}
      created={email && email.created_at}
      updated={email && email.updated_at}>
      <Pane>
        <TextDataField
          name="Subject"
          loading={loading}
          value={email && email.subject} />
        <TextDataField
          name="Template"
          loading={loading}
          value={email && email.template.name} />
        <BadgesDataField
          name="Template variables"
          loading={loading}
          items={email && email.template_values && templateValues()} />

        <BaseDataField name="Attachments">
          {email && email.attachments.length < 1 && <Text color="muted">No attachments</Text>}
          {email && email.attachments.map((a) => (
            <Card
              key={a.name}
              border="default"
              elevation={0}
              padding={majorScale(1)}
              marginY={majorScale(1)}
              backgroundColor="#FFF">
              <Pane display="flex" alignItems="center" width="100%">
                <embed src={a.file} style={{ maxWidth: '48px', maxHeight: '48px', overflowY: 'hidden' }} />
                <Pane width="100%" marginLeft={majorScale(2)}>
                  <Pane>
                    <Text>{a.name}</Text>
                  </Pane>
                  <Pane>
                    <Text color="muted" size={300}>{`${(a.size / (1024 * 1024)).toFixed(2)}MB`}</Text>
                  </Pane>
                </Pane>
              </Pane>
            </Card>
          ))}
        </BaseDataField>
      </Pane>
    </BaseSheet.Details>
  );
};

PredefinedEmailDetailsSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default PredefinedEmailDetailsSheet;
