import React, { useState, useEffect } from 'react';
import { Combobox } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { api } from '../../services/api';
import appTypes from '../../constants/types';

const SelectLocationInput = ({ defaultValue, onChange, disabled }) => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.locations.list(1, 1000); // TODO: Make dynamic
        setLocations(_.sortBy(data.data, 'label'));
        setLoading(false);
        return;
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Combobox
      items={locations}
      initialSelectedItem={defaultValue}
      onChange={(selected) => onChange && onChange(selected || null)}
      itemToString={(item) => (item ? item.name : '')}
      isLoading={loading}
      disabled={disabled}
      width="75%"
      openOnFocus
      data-testid="location" />
  );
};

SelectLocationInput.propTypes = {
  defaultValue: appTypes.location.props,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectLocationInput.defaultProps = {
  defaultValue: null,
};

export default SelectLocationInput;
