import React from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectGoogleGroupInput } from '../../../../../components/inputs'

const GoogleDeleteGroupForm = ({ submit }) => {
  const formik = useFormik({
    initialValues: {
      group_email: null,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      group_email: Yup.string().nullable().required('Email of the group is required'),
    }),
    validateOnChange: true,
  });

  const selectGoogleGroup = (googleGroups) => {
    if (googleGroups) {
      formik.setFieldValue('group_email', googleGroups);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Delete Google group</Heading>
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)} paddingTop={majorScale(1)}>
            <FormField
              label="Group Email"
              marginTop={majorScale(2)}
              maxWidth="50%"
              validationMessage={formik.errors.group_email}>
              <SelectGoogleGroupInput
                value={formik.values.group_email}
                onSelect={selectGoogleGroup}
              />
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default GoogleDeleteGroupForm;
