import React from 'react';
import appTypes from '../../../../constants/types';
import SectionCard from '../../../../components/section-card';
import { TextDataField } from '../../../../components/data-fields';

export const TemporaryAccessSSHDetails = ({ ticket }) => (
  <SectionCard title="Role information">
    <TextDataField
      name="Instance IP"
      value={ticket && ticket.ticket_data.instance_ip} />
    <TextDataField
      name="Email"
      value={ticket && ticket.ticket_data.ssh_key_owner} />
    <TextDataField
      name="Duration (in hours)"
      value={ticket && ticket.ticket_data.hours} />
  </SectionCard>
);

TemporaryAccessSSHDetails.propTypes = {
  ticket: appTypes.ticket.props,
};
TemporaryAccessSSHDetails.defaultProps = {
  ticket: appTypes.ticket.default,
};
