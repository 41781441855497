import React, { useState, useEffect } from 'react';
import {
  Pane,
  Button,
  Text,
  toaster,
  Spinner,
  minorScale,
  majorScale,
} from 'evergreen-ui';
import { api } from '../../../services/api';
import SectionCard from '../../../components/section-card';
import DeletePopConfirm from '../../../components/popconfirm/delete';
import { TextDataField } from '../../../components/data-fields';

const GithubConnectSettings = () => {
  const [loading, setLoading] = useState(true);
  const [connectedAccount, setConnectedAccount] = useState(null);

  let unmounted = false;
  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await api.github.connect.accountInfo();
      if (!unmounted) {
        setConnectedAccount(data.username);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);

  const connectAccount = async () => {
    setLoading(true);
    try {
      const { data } = await api.github.connect.connectAccount();
      window.location = data.redirect;
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  const removeAccount = async () => {
    setLoading(true);
    try {
      await api.github.connect.removeAccount();
      toaster.success('Account disconnected successfully');
      fetchData();
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  return (
    <SectionCard title="Github">
      <Pane>
        <TextDataField
          loading={loading}
          name="Connected account"
          value={connectedAccount || <Text color="muted">No account connected</Text>} />
        <Button
          type="button"
          size="small"
          disabled={loading}
          onClick={connectAccount}>
          {loading && <Spinner marginRight={minorScale(2)} size={16} />}
          {connectedAccount ? 'Change account' : 'Connect an account'}
        </Button>
        <DeletePopConfirm onConfirm={removeAccount}>
          <Button
            type="button"
            size="small"
            intent="danger"
            marginLeft={majorScale(1)}
            disabled={loading || !connectedAccount}>
            Disconnect
          </Button>
        </DeletePopConfirm>
      </Pane>
    </SectionCard>
  );
};

export default GithubConnectSettings;
