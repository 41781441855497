import React, { useState } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';
import { SelectGoogleGroupInput } from '../../../../../components/inputs'

const GoogleRemoveMemberFromGroupForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      member_email: '',
      group_email: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      member_email: Yup.string().required('Email of the member is required'),
      group_email: Yup.string().required('Email of the group is required'),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('member_email', user.email);
    }
  };

  const selectGoogleGroup = (googleGroups) => {
    if (googleGroups) {
      formik.setFieldValue('group_email', googleGroups);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
      <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Remove member from a Google group</Heading>
            <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
          </Pane>
          <Pane marginTop={majorScale(2)}>
            <FormField label="Member Email" validationMessage={errors.member_email}>
                <TextInput
                  data-testid="member_email"
                  name="member_email"
                  onChange={handleChange}
                  value={values.member_email}
                  readOnly={!allowEdit}
                  isInvalid={errors.member_email && true}
                />
            </FormField>
          </Pane>
          <Pane paddingBottom={majorScale(2)}>
            <FormField
              label="Group Email"
              marginTop={majorScale(2)}
              maxWidth="50%"
              validationMessage={formik.errors.group_email}>
              <SelectGoogleGroupInput
                value={formik.values.group_email}
                onSelect={selectGoogleGroup}
              />
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default GoogleRemoveMemberFromGroupForm;
