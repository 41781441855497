import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  Heading,
  majorScale,
  minorScale, Pane,
  SearchInput,
  Text,
  toaster,
  Strong,
} from 'evergreen-ui';
import { api } from '../../services/api';
import filter from '../../assets/scripts/filter';
import Skeleton from '../skeleton';

const SelectAWSEC2InstanceTypeDialog = ({ onSelect, region }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(false);
  const [instanceTypes, setInstanceTypes] = useState([]);
  const [selectedInstanceType, setSelectedInstanceType] = useState([]);

  let unmounted = false;
  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.aws.listEC2InstanceTypes(region);
      if (!unmounted) {
        setInstanceTypes(data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (dialogOpened) {
      fetchData();
    }
    return () => {
      unmounted = true;
      setSearchTerm('');
    };
  }, [dialogOpened]);

  const handleConfirm = (close) => {
    if (onSelect) {
      onSelect(selectedInstanceType);
    }
    close();
  };

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setDialogOpened(true);
        }}>
        Select instance type
      </Button>

      <Dialog
        width="50%"
        isShown={dialogOpened}
        title="Select instance type"
        onCloseComplete={() => setDialogOpened(false)}
        onConfirm={(close) => handleConfirm(close)}
        onCancel={(close) => onCancel(close)}
        isConfirmLoading={loading}
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <Pane>
          <Pane
            position="sticky"
            top="0px"
            backgroundColor="white"
            paddingBottom={majorScale(2)}
            marginX="-2px"
            borderBottom="muted"
            zIndex="1">
            <SearchInput
              width="100%"
              placeholder="Filter by name"
              disabled={loading}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </Pane>

          <Pane>
            {!loading && instanceTypes.length > 0
              ? filter(searchTerm, instanceTypes, ['name']).map((iType) => (
                <InstanceTypeRow
                  key={iType.name}
                  instanceType={iType}
                  select={setSelectedInstanceType}
                  isSelected={selectedInstanceType.name === iType.name} />
              ))
              : (
                <Pane>
                  {[1, 2, 3, 4].map((key) => (
                    <Skeleton
                      key={key}
                      width="100%"
                      height="64px"
                      style={{
                        marginTop: majorScale(2),
                        marginBottom: majorScale(2),
                        borderRadius: '3px',
                      }} />
                  ))}
                </Pane>
              )}
          </Pane>
        </Pane>

      </Dialog>
    </>
  );
};

const InstanceTypeRow = ({ instanceType, select, isSelected }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    background="white"
    marginY={majorScale(2)}
    padding={minorScale(3)}
    borderRadius="3px"
    hoverElevation={1}
    activeElevation={0}
    cursor="pointer"
    onClick={() => select(instanceType)}
    border={isSelected ? '2px solid #3366FF' : 'default'}>

    <Pane display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Pane marginLeft={majorScale(2)}>
        <Pane>
          <Heading size={400}>{instanceType.name}</Heading>
        </Pane>
        <Pane>
          <Text color="muted">{`Arch: ${instanceType.arch}`}</Text>
        </Pane>
      </Pane>
      <Pane>
        <Pane textAlign="right">
          <Strong color="muted">VCPUs: </Strong>
          <Text color="muted">{instanceType.vcpus}</Text>
        </Pane>
        <Pane textAlign="right">
          <Strong color="muted">Memory: </Strong>
          <Text color="muted">{`${instanceType.ram} GiB`}</Text>
        </Pane>
        <Pane textAlign="right">
          <Strong color="muted">Network: </Strong>
          <Text color="muted">{instanceType.network}</Text>
        </Pane>
      </Pane>
    </Pane>
  </Pane>
);

export default SelectAWSEC2InstanceTypeDialog;

SelectAWSEC2InstanceTypeDialog.propTypes = {
  onSelect: PropTypes.func,
  region: PropTypes.string,
};

SelectAWSEC2InstanceTypeDialog.defaultProps = {
  onSelect: null,
  region: null,
};

InstanceTypeRow.propTypes = {
  instanceType: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    arch: PropTypes.string.isRequired,
    vcpus: PropTypes.number.isRequired,
    ram: PropTypes.number.isRequired,
    network: PropTypes.string.isRequired,
  }),
  select: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

InstanceTypeRow.defaultProps = {
  instanceType: null,
  isSelected: false,
};
