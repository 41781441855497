import React, { useState, useEffect } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, SelectField, toaster, InlineAlert, Combobox, SelectMenu, Icon } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../../../services/api';
import selectButtonText from '../../../../../components/select-field-delimiter';

const GrantMySQLUserPrivilegesForm = ({ submit }) => {
  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState(false);
  const [instancesLoading, setInstancesLoading] = useState(false);
  const [instances, setInstances] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [databasesLoading, setDatabasesLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const MAX_DISPLAY_ITEMS = 3;
  const privilegesList = [
    'ALL PRIVILEGES',
    'ALTER',
    'CREATE',
    'CREATE VIEW',
    'DELETE',
    'DROP',
    'INDEX',
    'INSERT',
    'SELECT',
    'UPDATE',
    'REFERENCES',
    'TRIGGER'
  ];
  

  const formik = useFormik({
    initialValues: {
      db_identifier: '',
      region: '',
      privileges: [],
      username: '',
      database_name: '',
      tables: [],
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      db_identifier: Yup.string().required('Name of the RDS is required'),
      region: Yup.string().required('Region is required'),
      privileges: Yup.array()
      .min(1, 'At least one privilege must be selected')
      .of(Yup.string().required('A privilege is required')),
      username: Yup.string().required("Username is required"),
      database_name: Yup.string().required('Database name is required'),
      tables: Yup.array()
      .min(1, 'At least one table must be selected')
      .of(Yup.string().required('A table is required')),
    }),
    validateOnChange: false,
  });

  let unmounted = false;

  async function fetchData() {
    setRegionsLoading(true);
    try {
      const { data } = await api.aws.listRegionsWhereMySQLInstancesExist();
      if (!unmounted) {
        setRegions(data);
        setRegionsLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS regions.');
      setRegionsLoading(false);
    } 
  }

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);


  const { values, errors, handleChange } = formik;

  const listAvailableInstances = async (region) => {
    try {
        setInstancesLoading(true);
        const response = await api.aws.listRDSInstancesInRegion(region);
        setInstances(response.data);
    } catch (error) {
        toaster.danger('There was an error fetching the instances.');
        console.error(error);
    } finally {
        setInstancesLoading(false);
    }
};

  useEffect(() => {
    if (!regionsLoading && values.region) {
      listAvailableInstances(values.region);
    }
  }, [values.region, regionsLoading]);

  const listAvailableDatabases = async (db_identifier, region) => {
    try {
        setDatabasesLoading(true);
        const databases_response = await api.aws.listMySQLDatabasesOnRDS(db_identifier, region);
        setDatabases(databases_response.data);
    } catch (error) {
        toaster.danger('There was an error fetching the databases.');
        console.error(error);
    } finally {
      setDatabasesLoading(false);
    }
};

  useEffect(() => {
    if (!regionsLoading && values.region && values.db_identifier) {
      listAvailableDatabases(values.db_identifier, values.region);
    }
  }, [values.db_identifier, values.region, regionsLoading]);
  
  const listAvailableTables = async (db_identifier, region, database_name) => {
    try {
        setTablesLoading(true);
        const tables_response = await api.aws.listMySQLTablesOnDatabase(db_identifier, region, database_name);
        setTables(tables_response.data);
    } catch (error) {
        toaster.danger('There was an error fetching the tables.');
        console.error(error);
    } finally {
      setTablesLoading(false);
    }
};

  const tableOptions = [{ label: 'ALL TABLES', value: 'ALL TABLES' }, ...tables.map(table => ({ label: table, value: table }))];

  useEffect(() => {
    if (!regionsLoading && values.region && values.database_name && values.db_identifier) {
      listAvailableTables(values.db_identifier, values.region, values.database_name);
    }
  }, [values.db_identifier, values.region, values.database_name, regionsLoading]);

  const listMySQLUsers = async (db_identifier, region) => {
    try {
        setUsersLoading(true);
        const users_response = await api.aws.listMySQLUsers(db_identifier, region);
        const userNames = users_response.data.map(user => user[0]);
        setUsers(userNames);
    } catch (error) {
        toaster.danger('There was an error fetching the instances.');
        console.error(error);
    } finally {
      setUsersLoading(false);
    }
};

  useEffect(() => {
    if (!regionsLoading && values.region && values.db_identifier) {
      listMySQLUsers(values.db_identifier, values.region);
    }
  }, [values.region, values.db_identifier, regionsLoading]);

  const renderSelectedItems = (items) => {
    if (items.length <= MAX_DISPLAY_ITEMS) {
      return items.join(', ');
    } else {
      const displayedItems = items.slice(0, MAX_DISPLAY_ITEMS).join(', ');
      const additionalCount = items.length - MAX_DISPLAY_ITEMS;
      return `${displayedItems}, +${additionalCount} more...`;
    }
  };

  
  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Grant new privileges to MySQL user</Heading>
          </Pane>
          {regionsLoading && (
                <InlineAlert intent="none" marginBottom={16} marginTop={20}>
                  We are computing the regions where RDS instances are available.
                  This usually takes a few seconds, please do not close the window.
                </InlineAlert>
              )}
              <Pane display="flex" marginTop={10}>
                <SelectField
                  label="Region"
                  validationMessage={errors.region}
                  data-testid="region"
                  name="region"
                  disabled={regionsLoading}
                  onChange={handleChange}
                  value={values.region}>
                  <option value="" disabled={!values.region}>
                    Select a region
                  </option>
                  {!regionsLoading && regions.length > 0 &&
                    regions.map((region) => (
                      <option key={region} value={region}>{region}</option>
                    ))}
                </SelectField>
              </Pane>
              {values.region && (
                <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                  {instancesLoading && (
                    <InlineAlert intent="none" marginBottom={16}>
                      We are computing the available RDS instances in this region.
                      This usually takes a few seconds, please do not close the window.
                    </InlineAlert>
                  )}
                  {!instancesLoading && instances?.length > 0 && (
                    <FormField
                      validationMessage={errors.db_identifier}
                      width="100%"
                      label="Instances">
                      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
                      <Combobox
                        items={instances}
                        selectedItem={values.db_identifier}
                        onChange={(selected) => formik.setFieldValue('db_identifier', selected)}
                        width="25%"
                        disabled={instancesLoading}
                        openOnFocus
                        data-testid="db_identifier"
                    />
                      </Pane>
                    </FormField>
                  )}
                  {values.db_identifier && (
                    <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                      <FormField label="Privileges" validationMessage={errors.privileges}>
                        <SelectMenu
                          title="Select Privileges"
                          options={privilegesList.map(privilege => ({ label: privilege, value: privilege }))}
                          selected={values.privileges}
                          onSelect={(item) => formik.setFieldValue('privileges', [...values.privileges, item.value])}
                          onDeselect={(item) => formik.setFieldValue('privileges', values.privileges.filter(p => p !== item.value))}
                          isMultiSelect
                        >
                          <Button type="button" width="25%" display="flex" justifyContent="space-between" alignItems="center" className="selectButtonText">
                            {renderSelectedItems(values.privileges)}
                            <Icon icon="caret-down" marginLeft={8} />
                          </Button>
                        </SelectMenu>
                      </FormField>
                  </Pane>
                  )}
                  {databasesLoading && values.privileges?.length > 0 && (
                    <InlineAlert intent="none" marginBottom={16} marginTop={20}>
                      Searching databases...
                    </InlineAlert>
                  )}
                  {!databasesLoading && values.privileges?.length > 0 && (
                    <>
                    <FormField
                      validationMessage={errors.database_name}
                      width="100%"
                      label="Databases">
                      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
                      <Combobox
                        items={databases}
                        selectedItem={values.database_name}
                        onChange={(selected) => formik.setFieldValue('database_name', selected)}
                        width="25%"
                        openOnFocus
                        data-testid="database_name"
                    />
                      </Pane>
                    </FormField>
                  </>
                  )}
                  {tablesLoading && values.database_name != '' && (
                    <InlineAlert intent="none" marginBottom={16} marginTop={20}>
                      Searching tables...
                    </InlineAlert>
                  )}
                  {!tablesLoading && values.database_name != '' && (
                    <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                      <FormField label="Tables" validationMessage={errors.tables}>
                      <SelectMenu
                        title="Select Tables"
                        options={tableOptions}
                        selected={values.tables}
                        onSelect={(item) => {
                          if (item.value === '*.*') {
                            formik.setFieldValue('tables', ['*.*']);
                          } else {
                            if (!values.tables.includes('*.*')) {
                              formik.setFieldValue('tables', [...values.tables, item.value]);
                            }
                          }
                        }}
                        onDeselect={(item) => {
                          if (item.value === '*.*') {
                            formik.setFieldValue('tables', []);
                          } else {
                            formik.setFieldValue('tables', values.tables.filter(table => table !== item.value));
                          }
                        }}
                        isMultiSelect
                      >
                        <Button type="button" width="25%">{renderSelectedItems(values.tables)}</Button>
                      </SelectMenu>
                      </FormField>
                  </Pane>
                  )}
                  {usersLoading && values.tables?.length > 0 && (
                    <InlineAlert intent="none" marginBottom={16} marginTop={20}>
                      Searching users...
                    </InlineAlert>
                  )}
                  {!usersLoading && values.tables?.length > 0 && (
                    <>
                      <FormField
                      validationMessage={errors.username}
                      width="100%"
                      label="Username">
                      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
                      <Combobox
                        items={users}
                        selectedItem={values.username}
                        onChange={(selected) => formik.setFieldValue('username', selected)}
                        width="25%"
                        openOnFocus
                        data-testid="username"
                    />
                      </Pane>
                    </FormField>
                    </>
                  )}
                </Pane>
              )}
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border="default"
          marginTop={majorScale(2)}>
          <Button type="button" disabled={formik.isSubmitting} marginRight={majorScale(2)}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
}  

export default GrantMySQLUserPrivilegesForm;
