import React from 'react';
import { majorScale, Pane, Text, Badge } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseDataField from './base';

const BadgesDataField = ({ name, items, emptyMessage, loading }) => (
  <BaseDataField name={name} loading={loading}>
    <Pane marginTop={majorScale(1)}>
      {items && items.length > 0 ? (
        items.map((i) => (
          <Badge key={i} color="neutral" marginRight={8}>
            {i}
          </Badge>
        ))
      ) : (
        <Text color="muted">{emptyMessage}</Text>
      )}
    </Pane>
  </BaseDataField>
);

BadgesDataField.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

BadgesDataField.defaultProps = {
  items: [],
  loading: false,
  emptyMessage: 'No data',
};

export default BadgesDataField;
