import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  toaster,
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import NoResults from '../../../../../../components/no-results';
import { CustomCheckbox } from '../../../../../../components/inputs';
import { api } from '../../../../../../services/api';

const EmailsStep = (formik) => {
  const navigate = useNavigate();
  const { values, setFieldValue } = formik;
  let unmounted = false;
  const [loading, setLoading] = useState([]);
  const [predefinedEmails, setPredefinedEmails] = useState([]);

  const fetchEmails = async () => {
    setLoading(true);
    try {
      const { data } = await api.email.predefined.list();
      if (!unmounted) {
        setPredefinedEmails(data);
        setLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        toaster.danger('There was an error while proccessing your request');
        setLoading(false);
      }
    }
  };

  const handleChange = (pemail, checked) => {
    const currentValue = values.predefined_emails || [];
    if (checked) {
      currentValue.push(pemail);
    } else {
      const idx = currentValue.indexOf(pemail);
      currentValue.splice(idx, 1);
    }
    setFieldValue('predefined_emails', currentValue);
  };

  useEffect(() => {
    fetchEmails();

    return () => {
      unmounted = true;
    };
  }, []);

  return {
    title: 'Send emails',
    description: 'Select all the emails you want to send to the new employee (apart from account emails)',
    body: loading ? (
      <Text>Loading...</Text>
    ) : (
      <Pane>
        {predefinedEmails.length < 1 && (
          <NoResults
            message="There are no predefined emails to show"
            buttonText="Create a predefined email"
            buttonAction={() => navigate('/settings/email')} />
        )}
        {predefinedEmails.map((pemail) => (
          <CustomCheckbox
            key={pemail.id}
            name={`predefined_emails.${pemail.id}`}
            label={pemail.name}
            onCheck={(e) => handleChange(pemail, e.target.checked)}
            defaultValue={(
              values.predefined_emails
              && values.predefined_emails.map((pe) => pe.id).includes(pemail.id)
            )} />
        ))}
      </Pane>
    ),
  };
};

export default EmailsStep;
