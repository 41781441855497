import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  IconButton,
  majorScale,
  SearchInput,
  toaster,
  EditIcon,
  Spinner,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import SettingsNavigation from '../navigation';
import EditUserSheet from './sidesheets/edit';
import { searchParams } from '../../../services/history';
import { api } from '../../../services/api';
import appTypes from '../../../constants/types';
import { AuthorizedWrapper } from '../../../components/authorized-wrapper';
import { stickyExpandOnScroll } from '../../../assets/scripts/sticky';
import PaginationMenu from '../../../components/pagination-menu';
import NoResults from '../../../components/no-results';

const SettingsUsersPage = () => {
  const [loading, setLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [metadata, setMetadata] = useState({});

  let unmounted = false;
  const fetchData = async () => {
    setLoading(true);
    try {
      const page = searchParams.get('page') || 1;
      const itemsPerPage = searchParams.get('per_page') || 10;
      const usersData = await api.users.list(page, itemsPerPage, searchTerm);
      const rolesData = await api.roles.list(1, 1000);
      if (!unmounted) {
        setUsers(usersData.data.data);
        setMetadata(usersData.data.metadata);
        setRoles(rolesData.data.data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    stickyExpandOnScroll('-24px');
    const event = setTimeout(() => {
      searchParams.set('search', searchTerm);
      fetchData();
    }, 500);

    return () => {
      clearTimeout(event);
      unmounted = true;
    };
  }, [searchTerm, editOpen]);

  const openEditUserSheet = (user) => {
    setSelectedUser(user);
    setEditOpen(true);
  };

  return (
    <Pane>
      <SettingsNavigation />
      <Pane
        id="jampp-sticky-expand"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={majorScale(2)}
        marginBottom={majorScale(4)}
        elevation={0}
        position="sticky"
        top="-24px"
        backgroundColor="white"
        zIndex="9"
        marginX={-1}>
        <SearchInput
          placeholder="Search user"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          display="flex"
          alignItems="center"
          maxWidth="50%" />
      </Pane>

      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}>
          <Spinner />
        </Pane>
      )}

      {!loading && users.length < 1 && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={250}>
          <NoResults
            message="There are no users to show" />
        </Pane>
      )}

      {!loading && users.length > 0 && (
        <>
          {users.map((user) => (
            <User key={user.id} user={user} openEditUserSheet={openEditUserSheet} />
          ))}
          <PaginationMenu
            pageMetadata={metadata}
            onPreviousPage={() => fetchData()}
            onNextPage={() => fetchData()}
            onChangePage={() => fetchData()}
            onItemsPerPageChange={() => fetchData()} />
        </>
      )}

      {editOpen && (
        <EditUserSheet
          visible={editOpen}
          user={selectedUser}
          roles={roles}
          close={() => setEditOpen(false)} />
      )}
    </Pane>
  );
};

const User = ({ user, openEditUserSheet }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    backgroundColor="white"
    justifyContent="space-between"
    marginY={majorScale(2)}
    padding={majorScale(2)}
    borderRadius="3px">
    <Text>{user.name}</Text>

    <Pane display="flex" alignItems="center">
      <AuthorizedWrapper resource="users" action="assign_role">
        <IconButton icon={EditIcon} onClick={() => openEditUserSheet(user)} />
      </AuthorizedWrapper>
    </Pane>
  </Pane>
);

User.propTypes = {
  user: appTypes.allowedUser.props.isRequired,
  openEditUserSheet: PropTypes.func.isRequired,
};

export default SettingsUsersPage;
