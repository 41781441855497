import React, { useState, useEffect } from 'react';
import { Button, Card, FormField, Heading, majorScale, Pane, TextInput, SelectField, toaster, InlineAlert, Combobox } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';
import { api } from '../../../../../services/api';

const TemporaryAccessSSHForm = ({ submit }) => {
  const currentUser = useSelector((state) => state.authentication.user);
  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState([]);
  const [instancesLoading, setInstancesLoading] = useState([]);
  const [instances, setInstances] = useState([]);
  
  const formik = useFormik({
    initialValues: {
      instance_ip: '',
      ssh_key_owner: currentUser.email,
      hours: '2',
      region: '',
      instances: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      instance_ip: Yup.string().required('IP of the instance is required'),
      hours: Yup.string().required("Amount of hours for access is required"),
      region: Yup.string().required("Region is required"),
    }),
    validateOnChange: false,
  });

  let unmounted = false;

  async function fetchData() {
    setRegionsLoading(true);
    try {
      const { data } = await api.aws.listRegionsWhereEC2InstancesExist();
      if (!unmounted) {
        setRegions(data);
        setRegionsLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while fetching AWS regions.');
      setRegionsLoading(false);
    } 
  }

  useEffect(() => {
    fetchData();
    return () => {
      unmounted = true;
    };
  }, []);


  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('ssh_key_owner', user.email);
    }
  };

  const listAvailableInstances = async (region) => {
    try {
      setInstancesLoading(true);
      const response = await api.aws.listEC2InstancesInRegion(region);
      setInstances(response.data);
    } catch (error) {
      toaster.danger('There was an error fetching the instances.');
      console.error(error);
    } finally {
      setInstancesLoading(false);
    }
  };

  useEffect(() => {
    if (!regionsLoading && values.region) {
      listAvailableInstances(values.region);
    }
  }, [values.region, regionsLoading]);

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Heading size={600}>Temporary SSH access to an EC2 instance</Heading>
            <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
          </Pane>
          {regionsLoading && (
                <InlineAlert intent="none" marginBottom={16} marginTop={20}>
                  We are computing the regions where EC2 instances are available.
                  This usually takes a few seconds, please do not close the window.
                </InlineAlert>
              )}
              <Pane display="flex" marginTop={10}>
                <SelectField
                  label="Region"
                  validationMessage={errors.region}
                  data-testid="region"
                  name="region"
                  disabled={regionsLoading}
                  onChange={handleChange}
                  value={values.region}>
                  <option value="" disabled={!values.region}>
                    Select a region
                  </option>
                  {!regionsLoading && regions.length > 0 &&
                    regions.map((region) => (
                      <option key={region} value={region}>{region}</option>
                    ))}
                </SelectField>
              </Pane>
              {values.region && (
                <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                  {instancesLoading && (
                    <InlineAlert intent="none" marginBottom={16}>
                      We are computing the available EC2 instances in this region.
                      This usually takes a few seconds, please do not close the window.
                    </InlineAlert>
                  )}
                  {!instancesLoading && instances?.length > 0 && (
                    <FormField
                      validationMessage={errors.instance_ip}
                      width="100%"
                      label="Instances">
                      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
                        <Combobox
                          items={instances.map(instance => `${instance.Name} (${instance["Private IP"]})`)}
                          selectedItem={values.instance_ip}
                          onChange={selected => {
                            if (selected) {
                              const match = selected.match(/\(([^)]+)\)/);
                              if (match) {
                                formik.setFieldValue('instance_ip', match[1]);
                              }
                            } else {
                              formik.setFieldValue('instance_ip', '');
                            }
                          }}
                          width="25%"
                          openOnFocus
                          data-testid="instance_ip"
                        />
                      </Pane>
                    </FormField>
                  )}
                  {values.instance_ip && (
                    <>
                      <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
                        <FormField
                          label="Email"
                          validationMessage={errors.ssh_key_owner}
                          width="100%">
                          <TextInput
                            data-testid="ssh_key_owner"
                            name="ssh_key_owner"
                            width="100%"
                            onChange={handleChange}
                            value={values.ssh_key_owner}
                            isInvalid={errors.ssh_key_owner && true}/>
                        </FormField>
                      </Pane>
                      <Pane display="flex" flexDirection="column">
                        <SelectField
                          label="Duration"
                          hint="Select for how long will this access be needed"
                          validationMessage={errors.hours}
                          data-testid="hours"
                          name="hours"
                          width="100%"
                          onChange={handleChange}
                          value={values.hours}
                          isInvalid={errors.hours && true}>
                          <option value="2">2 hours</option>
                          <option value="8">8 hours</option>
                          <option value="24">24 hours</option>
                        </SelectField>
                      </Pane>
                    </>
                  )}
                </Pane>
              )}
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border="default"
          marginTop={majorScale(2)}>
          <Button type="button" disabled={formik.isSubmitting} marginRight={majorScale(2)}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
}  

export default TemporaryAccessSSHForm;
