import React, { useState, useEffect } from 'react';
import { Combobox } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { api } from '../../services/api';

const SelectCompanyID = ({ value, onSelect, disabled, isAdmin }) => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.snipe.getCompanies();
        const filteredData = isAdmin ? data : data.filter(company => company.id === 1); // company.id 1 is Jampp
        // TODO: Change when this goes to GKE since 'company.id 1' will be another company
        setCompanies(filteredData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [isAdmin]);

  return (
    <Combobox
      items={companies}
      selectedItem={companies.find(company => company.id === value) || ''}
      onChange={(selected) => onSelect && onSelect(selected ? selected.id : null)}
      disabled={disabled}
      isLoading={loading}
      width="40%"
      openOnFocus
      itemToString={item => (item ? item.name : '')}
      data-testid="company"
    />
  );
};

SelectCompanyID.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectCompanyID.defaultProps = {
  value: null,
};

export default SelectCompanyID;
