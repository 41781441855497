import React, { useState, useEffect } from 'react';
import { Combobox } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { api } from '../../services/api';
import appTypes from '../../constants/types';
import Skeleton from '../skeleton';

const SelectEmailTemplateInput = ({ defaultValue, onChange, disabled }) => {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.email.templates.list();
        setTemplates(_.sortBy(data, 'name'));
        setLoading(false);
        return;
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (!loading) {
    return (
      <Combobox
        items={templates}
        initialSelectedItem={templates.filter((t) => t.id === defaultValue)[0]}
        onChange={(selected) => onChange && onChange(selected || null)}
        itemToString={(item) => (item ? item.name : '')}
        isLoading={loading}
        disabled={disabled || loading}
        width="100%"
        openOnFocus
        data-testid="email_template" />
    );
  }

  return (
    <Skeleton width="100%" height="32px" style={{ borderRadius: '4px' }} />
  );
};

SelectEmailTemplateInput.propTypes = {
  defaultValue: appTypes.emailTemplate.props,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectEmailTemplateInput.defaultProps = {
  defaultValue: null,
};

export default SelectEmailTemplateInput;
