import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  Heading,
  majorScale,
  minorScale, Pane,
  SearchInput,
  Text,
  toaster,
  Strong,
} from 'evergreen-ui';
import { api } from '../../services/api';
import filter from '../../assets/scripts/filter';
import Skeleton from '../skeleton';

const SelectGithubRepositoryDialog = ({ onSelect, region }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(false);
  const [githubRepositories, setGithubRepositories] = useState([]);
  const [selectedGithubRepository, setSelectedGithubRepository] = useState({name:''});


  let unmounted = false;
  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.github.listRepositories();
      if (!unmounted) {
        setGithubRepositories(data);
        setLoading(false);
      }
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (dialogOpened) {
      fetchData();
    }
    return () => {
      unmounted = true;
      setSearchTerm('');
    };
  }, [dialogOpened]);

  const handleConfirm = (close) => {
    if (onSelect) {
      onSelect(selectedGithubRepository);
    }
    close();
  };

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setDialogOpened(true);
        }}>
        Select Github repository
      </Button>

      <Dialog
        width="50%"
        isShown={dialogOpened}
        title="Select Github repository"
        onCloseComplete={() => setDialogOpened(false)}
        onConfirm={(close) => handleConfirm(close)}
        onCancel={(close) => onCancel(close)}
        isConfirmLoading={loading}
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <Pane>
          <Pane
            position="sticky"
            top="0px"
            backgroundColor="white"
            paddingBottom={majorScale(2)}
            marginX="-2px"
            borderBottom="muted"
            zIndex="1">
            <SearchInput
              width="100%"
              placeholder="Filter by name"
              disabled={loading}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </Pane>

          <Pane>
            {!loading && githubRepositories.length > 0
              ? filter(searchTerm, githubRepositories, ['name']).map((gRepo) => (
                <GithubRepositoryRow
                  key={gRepo.name}
                  githubRepository={gRepo}
                  select={setSelectedGithubRepository}
                  isSelected={selectedGithubRepository.name === gRepo.name} />
              ))
              : (
                <Pane>
                  {[1, 2, 3, 4].map((key) => (
                    <Skeleton
                      key={key}
                      width="100%"
                      height="64px"
                      style={{
                        marginTop: majorScale(2),
                        marginBottom: majorScale(2),
                        borderRadius: '3px',
                      }} />
                  ))}
                </Pane>
              )}
          </Pane>
        </Pane>

      </Dialog>
    </>
  );
};

const GithubRepositoryRow = ({ githubRepository, select, isSelected }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    background="white"
    marginY={majorScale(2)}
    padding={minorScale(3)}
    borderRadius="3px"
    hoverElevation={1}
    activeElevation={0}
    cursor="pointer"
    onClick={() => select(githubRepository)}
    border={isSelected ? '2px solid #3366FF' : 'default'}>

    <Pane display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Pane marginLeft={majorScale(2)}>
        <Pane>
          <Heading size={400}>{githubRepository.name}</Heading>
        </Pane>
        <Pane>
          <Text color="muted">{`Visibility: ${githubRepository.visibility}`}</Text>
        </Pane>
      </Pane>
    </Pane>
  </Pane>
);

export default SelectGithubRepositoryDialog;

SelectGithubRepositoryDialog.propTypes = {
  onSelect: PropTypes.func,
};

SelectGithubRepositoryDialog.defaultProps = {
  onSelect: null,
};

GithubRepositoryRow.propTypes = {
  githubRepositories: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    visibility: PropTypes.string.isRequired
  }),
  select: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

GithubRepositoryRow.defaultProps = {
  githubRepositories: null,
  isSelected: false,
};
