import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import { CornerDialogProvider } from './contexts/corner-dialog';
import { Layout } from './components/layout';
import 'assets/styles/_reset.scss';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CornerDialogProvider>
        <BrowserRouter history={history}>
          <Layout>
            <Routes />
          </Layout>
        </BrowserRouter>
      </CornerDialogProvider>
    );
  }
}

export default App;
