import React, { useEffect, useState } from 'react';
import { Pane, toaster } from 'evergreen-ui';
import PropTypes from 'prop-types';
import BaseSheet from '../../../../components/sidesheets/base';
import { searchParams } from '../../../../services/history';
import { api } from '../../../../services/api';
import {
  BadgesDataField,
  TextDataField,
} from '../../../../components/data-fields';

const LocationDetailsSheet = ({ visible, close }) => {
  const locationId = searchParams.get('selected');
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.locations.get(locationId);
        setLocation(data);
        setLoading(false);
      } catch (error) {
        toaster.danger('There was an error while proccessing your request');
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <BaseSheet.Details
      close={close}
      visible={visible}
      title={location && location.name}
      loading={loading}
      creator={location && location.created_by}
      created={location && location.created_at}
      updated={location && location.updated_at}>
      <Pane>
        <TextDataField
          name="Country code"
          loading={loading}
          value={location && location.country_code} />
        <TextDataField
          name="Name"
          loading={loading}
          value={location && location.name} />
        <BadgesDataField
          name="Aliases"
          loading={loading}
          items={location && location.aliases} />
      </Pane>
    </BaseSheet.Details>
  );
};

LocationDetailsSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default LocationDetailsSheet;
