import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Pane,
  majorScale,
  minorScale,
  toaster,
  Text,
  Spinner,
  Card,
  Button,
  RefreshIcon,
} from 'evergreen-ui';
import dayjs from 'dayjs';
import { api } from '../../../services/api';
import SectionCard from '../../../components/section-card';
import { DatesDataField, StatusDataField, TextDataField, UserDataField } from '../../../components/data-fields';
import { JiraAccountDetails, OnboardingDetails, OffboardingDetails, PritunlAccountDetails, SilverAccountDetails, SnipeCheckoutAssetDetails, SnipeCheckinAssetDetails, NewEC2SandboxDetails, ModifyEC2SandboxDetails, NewIAMUserDetails, NewIAMRoleDetails, TemporaryAccessSSHDetails, ResetIAMUserPasswordDetails, CreateMySQLUserDetails, GrantMySQLUserPrivilegesDetails, InviteGithubUserDetails, CreateNewRepoDetails, AddNewSshKeyDetails, AddTeamToRepoDetails, AddUserToRepoDetails, AddUserToTeamDetails, NewADUserDetails, APRoleChangeDetails, GoogleCreateGroupDetails, GoogleDeleteGroupDetails, GoogleAddMemberToGroupDetails, GoogleRemoveMemberFromGroupDetails } from './ticket-details';
import { ExecutionLogsDialog, UpdateTicketStatusDialog } from '../../../components/dialogs';
import { useSelector } from 'react-redux';


const TicketDetailsPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState(null);
  const role = useSelector((state) => state.authentication.user.role);
  const isAdmin = role.name === 'Administrator';
  
  const fetchData = async () => {
    try {
      const { data } = await api.tickets.details(id);
      setTicket(data);
      setLoading(false);
    } catch (error) {
      toaster.danger('There was an error while processing your request');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const retryTicket = async () => {
    setLoading(true);
    try {
      await api.tickets.retry(id);
      setTimeout(() => {
        setLoading(false);
        history.go(0);
      }, 3000);
    } catch (error) {
      toaster.danger('There was an error while processing your request');
      setLoading(false);
    }
  };

  const renderTicketInformation = () => {
    switch (ticket.type) {
      case 'silver_account':
        return <SilverAccountDetails ticket={ticket} />;
      case 'snipe_checkout':
        return <SnipeCheckoutAssetDetails ticket={ticket} />;
      case 'snipe_checkin':
        return <SnipeCheckinAssetDetails ticket={ticket} />;
      case 'onboarding':
        return <OnboardingDetails ticket={ticket} />;
      case 'offboarding':
        return <OffboardingDetails ticket={ticket} />;
      case 'jira_account':
        return <JiraAccountDetails ticket={ticket} />;
      case 'pritunl_account':
        return <PritunlAccountDetails ticket={ticket} />;
      case 'active_directory_user':
        return <NewADUserDetails ticket={ticket} />;
      case 'access_platform_role_change':
        return <APRoleChangeDetails ticket={ticket} />;
      case 'new_sandbox_ec2':
        return <NewEC2SandboxDetails ticket={ticket} />;
      case 'modify_sandbox_ec2':
        return <ModifyEC2SandboxDetails ticket={ticket} />;
      case 'new_iam_user':
        return <NewIAMUserDetails ticket={ticket} />;
      case 'new_iam_role':
        return <NewIAMRoleDetails ticket={ticket} />;
      case 'temporary_ssh_access':
        return <TemporaryAccessSSHDetails ticket={ticket} />;
      case 'reset_iam_user_password':
        return <ResetIAMUserPasswordDetails ticket={ticket} />;
      case 'create_mysql_user':
        return <CreateMySQLUserDetails ticket={ticket} />;
      case 'grant_mysql_user_privileges':
        return <GrantMySQLUserPrivilegesDetails ticket={ticket} />;
      case 'github_invite_user':
        return <InviteGithubUserDetails ticket={ticket} />;
      case 'github_new_repo':
        return <CreateNewRepoDetails ticket={ticket} />;
      case 'github_new_ssh_key':
        return <AddNewSshKeyDetails ticket={ticket} />;
      case 'github_add_team_to_repo':
        return <AddTeamToRepoDetails ticket={ticket} />;
      case 'github_add_user_to_repo':
        return <AddUserToRepoDetails ticket={ticket} />;
      case 'github_add_user_to_team':
        return <AddUserToTeamDetails ticket={ticket} />;
      case 'google_create_group':
        return <GoogleCreateGroupDetails ticket={ticket} />;
      case 'google_delete_group':
        return <GoogleDeleteGroupDetails ticket={ticket} />;
      case 'google_add_member_to_group':
        return <GoogleAddMemberToGroupDetails ticket={ticket} />;
      case 'google_remove_member_from_group':
        return <GoogleRemoveMemberFromGroupDetails ticket={ticket} />;
      default:
        return (<div></div>);
    }
  };

  return (
    <Pane display="flex">
      <Pane flex="70%" marginRight={majorScale(2)}>
        {loading ? (
          <Pane display="flex" alignItems="center">
            <Spinner size={32} />
            <Text marginLeft={majorScale(2)}>Loading ticket details</Text>
          </Pane>
        ) : renderTicketInformation()}
      </Pane>

      <Pane flex="30%">
        <Pane position="sticky" top="0">
          {!loading && ticket.status === 'pending' && (
            <Card border="default" padding={minorScale(3)} marginBottom={majorScale(2)} background="white">
              <Pane display="flex" alignItems="center" justifyContent="flex-end">
                <UpdateTicketStatusDialog id={id} />
              </Pane>
            </Card>
          )}
          <SectionCard title="Ticket Information">
            <TextDataField
              name="Ticket type"
              value={ticket && ticket.friendly_type}
              loading={loading} />
            <StatusDataField
              name="Status"
              status={ticket && ticket.status}
              loading={loading} />
            {ticket && ticket.status !== 'pending' && ticket.status_reason && (
              <TextDataField
                name="Status Reason"
                value={ticket.status_reason}
                loading={loading} />
            )}
            <UserDataField
              name="Created by"
              userName={ticket && ticket.created_by.name}
              loading={loading} />

            {ticket && ticket.status !== 'pending' && (
              <>
                <UserDataField
                  name="Reviewed by"
                  userName={ticket && ticket.updated_by.name}
                  loading={loading} />

                {ticket.status === 'approved'
                  && (
                    <>
                      <TextDataField
                        name="Execution date"
                        value={ticket && dayjs(ticket.updated_at).format('DD-MM-YYYY')}
                        loading={loading} />
                      <Pane display="flex" alignItems="center" justifyContent="space-between">
                        <TextDataField
                          name="Execution logs"
                          value={ticket && <ExecutionLogsDialog />}
                          loading={loading} />
                        {isAdmin && (
                          <Button
                            height={28}
                            appearance="primary"
                            intent="none"
                            iconBefore={<RefreshIcon />}
                            onClick={retryTicket}
                            isLoading={loading}>
                            Retry
                          </Button>
                        )}
                        </Pane>
                    </>
                  )}
              </>
            )}
            <DatesDataField
              loading={loading}
              created={ticket && ticket.created_at}
              updated={ticket && ticket.updated_at} />
          </SectionCard>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default TicketDetailsPage;
