import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  majorScale,
  Pane,
  toaster,
  Spinner,
  Button,
  Dialog,
  SearchInput,
  Alert
} from 'evergreen-ui';
import { api } from '../../services/api';
import SnipeAsset from '../../components/snipe-asset';

const SelectSnipeAssetDialog = ({ onSelect, company_id }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState({id:''});

  let unmounted = false;

  async function fetchData(company_id) {
    try {
      const { data } = await api.snipe.listAvailableAssets(company_id);
      if (!unmounted) {
        setAssets(data.laptops || []);
        setLoading(false);
      }
    } catch (error) {
      if (!unmounted) {
        toaster.danger('There was an error while processing your request');
        setLoading(false);
      }
    }
  }  

  useEffect(() => {
    if (company_id) {
      setSelectedAsset({ id: '' });
      setAssets([]);
      setLoading(true);
      fetchData(company_id);
    }
  }, [company_id]);

  useEffect(() => {
    if (dialogOpened) {
      fetchData(company_id);
    }
    return () => {
      unmounted = true;
      setSearchTerm('');
    };
  }, [dialogOpened, company_id]);



  const handleConfirm = (close) => {
    if (onSelect && selectedAsset) {
      onSelect(selectedAsset);
    }
    close();
  };

  const onCancel = (close) => {
    if (!loading) {
      close();
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setDialogOpened(true);
        }}
        disabled={!company_id}>
        
        Search available assets
      </Button>

      <Dialog
        width="50%"
        isShown={dialogOpened}
        title="Search available assets"
        onCloseComplete={() => setDialogOpened(false)}
        onConfirm={(close) => handleConfirm(close)}
        onCancel={(close) => onCancel(close)}
        isConfirmLoading={loading}
        containerProps={{
          borderRadius: '3px',
        }}
        contentContainerProps={{
          paddingY: '0px',
        }}
        confirmLabel="Confirm"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>

        <Pane>
          <Pane
            position="sticky"
            top="0px"
            backgroundColor="white"
            paddingBottom={majorScale(2)}
            marginX="-2px"
            borderBottom="muted"
            zIndex="1">
            <SearchInput
              width="100%"
              placeholder="Filter by name"
              disabled={loading}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </Pane>
        </Pane>
        <Pane padding={majorScale(1)} flex="1">
          {loading ? (
            <Spinner />
          ) : assets.length > 0 ? (
            assets.map((asset) => (
              <SnipeAsset
                key={asset.id}
                asset={asset}
                select={setSelectedAsset}
                isSelectable
              />
            ))
          ) : (
            <Alert
              intent="none"
              title="There are no available assets for this company."
              marginTop={majorScale(2)}
            />
          )}
        </Pane>
      </Dialog>
    </>
  );
};

export default SelectSnipeAssetDialog;

SelectSnipeAssetDialog.propTypes = {
  onSelect: PropTypes.func,
  company_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SelectSnipeAssetDialog.defaultProps = {
  onSelect: null,
  company_id: null
};
