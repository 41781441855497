import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  Pane,
  Button,
  majorScale,
  Heading,
  Card,
  Paragraph,
  Text,
} from 'evergreen-ui';

const FormWizard = forwardRef(({ steps, onSubmit, nextDisabled }, ref) => {
  const [step, setStep] = useState(0);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  useImperativeHandle(ref, () => ({
    getCurrentStep: () => step,
  }));

  const handleSubmit = (e) => {
    e.stopPropagation();
    onSubmit();
  };

  return (
    <Pane>
      <form>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Header
            title={steps[step].title}
            description={steps[step].description}
            actions={steps[step].headerActions} />
          <Pane paddingY={majorScale(2)}>{steps[step].body}</Pane>
        </Card>
        <Footer
          prevStep={prevStep}
          nextStep={nextStep}
          numberOfSteps={steps.length}
          currentStep={step}
          backDisabled={step === 0}
          nextDisabled={nextDisabled}
          handleSubmit={handleSubmit}
          showSubmit={step === steps.length - 1} />
      </form>
    </Pane>
  );
});

const Header = ({ title, description, actions }) => (
  <Pane
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    paddingY={majorScale(2)}
    borderBottom="1px solid #edeff5">
    <Pane>
      <Heading size={600}>{title}</Heading>
      {description && <Paragraph color="muted">{description}</Paragraph>}
    </Pane>
    {actions && actions}
  </Pane>
);

const Footer = ({
  prevStep,
  nextStep,
  numberOfSteps,
  currentStep,
  backDisabled,
  nextDisabled,
  handleSubmit,
  showSubmit,
}) => (
  <Card
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    backgroundColor="white"
    padding={majorScale(2)}
    border>
    <Button onClick={prevStep} disabled={backDisabled} type="button">
      Back
    </Button>

    <Pane display="flex" alignItems="center">
      {Array.from({ length: numberOfSteps }, (x, i) => i).map((step) => (
        <Pane key={step} marginX={majorScale(2)} textAlign="center">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="32px"
            width="32px"
            marginX="auto"
            backgroundColor={currentStep === step ? '#EBF0FF' : '#F4F5F9'}
            borderRadius="4px">
            <Text color={currentStep === step ? '#5C85FF' : 'inherit'}>{step + 1}</Text>
          </Pane>
        </Pane>
      ))}
    </Pane>

    <Button
      marginLeft={majorScale(2)}
      appearance="primary"
      type="button"
      onClick={showSubmit ? handleSubmit : nextStep}
      disabled={nextDisabled}>
      {showSubmit ? 'Submit' : 'Next'}
    </Button>
  </Card>
);

FormWizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      headerActions: PropTypes.node,
      body: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func,
  nextDisabled: PropTypes.bool,
};

FormWizard.defaultProps = {
  onSubmit: null,
  nextDisabled: false,
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  actions: PropTypes.element,
};

Header.defaultProps = {
  description: undefined,
  actions: undefined,
};

Footer.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  numberOfSteps: PropTypes.arrayOf(PropTypes.number),
  currentStep: PropTypes.number,
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  showSubmit: PropTypes.bool.isRequired,
};

Footer.defaultProps = {
  backDisabled: false,
  nextDisabled: false,
  numberOfSteps: [],
  currentStep: 0,
};

export default FormWizard;
