import React, { useState } from 'react';
import {
  Pane, majorScale, FormField, toaster, Combobox,
} from 'evergreen-ui';
import validator from 'validator';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { api } from '../../../../services/api';
import BaseSheet from '../../../../components/sidesheets/base';
import {
  CustomTagInput,
  SelectLocationInput,
  SelectTeamInput,
} from '../../../../components/inputs';
import formHelp from '../../../../constants/help';

const CreateEmailGroupSheet = ({ visible, close }) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const validateForm = (values) => {
    const errors = {};
    if (!values.team && values.type === 'Team') {
      errors.team = 'This field is required';
    }
    if (!values.location && values.type === 'Location') {
      errors.location = 'This field is required';
    }
    if (values.groups && values.groups.length > 0) {
      // eslint-disable-next-line array-callback-return
      values.groups.forEach((g) => {
        if (!validator.isEmail(g)) {
          errors.groups = `${g} is invalid`;
        }
        if (!g.includes('@jampp.com')) {
          errors.groups = 'Only @jampp.com emails are allowed';
        }
      });
    }
    return errors;
  };

  const submitForm = async (values) => {
    setSubmitLoading(true);
    const data = values;
    if (data.type === 'Team') {
      data.name = data.team.name;
      data.team = data.team.id;
      data.location = null;
    }
    if (data.type === 'Location') {
      data.name = data.location.name;
      data.location = data.location.id;
      data.team = null;
    }

    try {
      await api.groups.create(data);
      toaster.success('Group created successfully');
      close();
    } catch (error) {
      toaster.danger(error.response.data.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: submitForm,
    validate: validateForm,
    validateOnChange: true,
  });

  const form = (
    <Pane>
      <FormField
        label="Association type"
        marginTop={majorScale(2)}
        maxWidth="50%"
        validationMessage={formik.errors.type}>
        <Combobox
          items={['Team', 'Location']}
          onChange={(selected) => {
            formik.setFieldValue('type', selected);
            formik.setFieldValue('team', undefined);
            formik.setFieldValue('location', undefined);
          }}
          width="100%"
          openOnFocus />
      </FormField>
      {formik.values.type === 'Team' && (
        <FormField
          label="Team"
          marginTop={majorScale(2)}
          maxWidth="50%"
          validationMessage={formik.errors.team}>
          <SelectTeamInput
            disabled={!formik.values.type || formik.values.type !== 'Team'}
            onChange={(selected) => formik.setFieldValue('team', selected)} />
        </FormField>
      )}
      {formik.values.type === 'Location' && (
        <FormField
          label="Location"
          marginTop={majorScale(2)}
          maxWidth="50%"
          validationMessage={formik.errors.location}>
          <SelectLocationInput
            disabled={!formik.values.type || formik.values.type !== 'Location'}
            onChange={(selected) => formik.setFieldValue('location', selected)} />
        </FormField>
      )}
      <FormField
        label="Groups"
        marginTop={majorScale(2)}
        validationMessage={formik.errors.groups}>
        <CustomTagInput
          disabled={submitLoading}
          onChange={(values) => formik.setFieldValue('groups', values)} />
      </FormField>
    </Pane>
  );

  return (
    <BaseSheet.Create
      form={form}
      close={close}
      visible={visible}
      help={formHelp.team}
      submitLoading={submitLoading}
      onSubmit={formik.handleSubmit}
      title="Create a new email group association"
      submitDisabled={Object.keys(formik.errors).length > 0} />
  );
};

CreateEmailGroupSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CreateEmailGroupSheet;
