export const stickyExpandOnScroll = (expandedMargin = '0px', margin = '0px') => {
  const elem = document.getElementById('jampp-route');
  elem.addEventListener('scroll', () => {
    const element = document.getElementById('jampp-sticky-expand');
    if (!element) {
      return;
    }
    const observer = new IntersectionObserver((e) => {
      let m = margin;
      if (e[0].intersectionRect.top === 64) {
        m = expandedMargin;
      }

      element.style.marginLeft = m;
      element.style.marginRight = m;
      element.style.transition = 'margin 150ms ease-in-out';
    }, {
      threshold: [1],
    });

    observer.observe(element);
  });
};
