import React, { useState, useEffect } from 'react';
import { Button, Card, toaster, Heading, majorScale, Pane, SelectField } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../../../services/api';
import { TextDataField } from '../../../../../components/data-fields';

const ChangeAccessPlatformRole = ({ submit }) => {
  const [roles, setRoles] = useState([]);
  const currentUser = useSelector((state) => state.authentication.user);

  const fetchData = async () => {
    try {
      const { data } = await api.roles.list(1, 1000);
      setRoles(data.data);
    } catch (error) {
      toaster.danger('There was an error while proccessing your request');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: currentUser.email,
      role_id: currentUser.role.id,
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      email: Yup.string(),
      role_id: Yup.string().required('Role is required'),
    }),
    validateOnChange: true,
  });
  const { values, errors, handleChange } = formik;

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Change my Access Platform role</Heading>
            </Pane>
          </Pane>

          <Pane paddingY={majorScale(2)}>
            <TextDataField name="My current role" value={currentUser.role.name} />
            <SelectField
              label="Role"
              hint="Access level depends on the role you select"
              validationMessage={errors.role_id}
              data-testid="role_id"
              name="role_id"
              maxWidth="50%"
              marginTop={majorScale(2)}
              onChange={handleChange}
              value={values.role_id}
              isInvalid={errors.role_id && true}>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </SelectField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default ChangeAccessPlatformRole;
