import React, { createContext, useState, useContext } from 'react';

const UserAccessContext = createContext(null);

export const useUserAccess = () => useContext(UserAccessContext);

export const UserAccessProvider = ({ children }) => {
  const [userAccessLoading, setUserAccessLoading] = useState(true);

  return (
    <UserAccessContext.Provider value={{ userAccessLoading, setUserAccessLoading }}>
      {children}
    </UserAccessContext.Provider>
  );
};
