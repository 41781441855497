import React, { useState, useEffect } from 'react';
import {
  Pane,
  Avatar,
  majorScale,
  NotificationsIcon,
  LogOutIcon,
  IconButton,
  Popover,
  Menu,
  Position,
  Text,
  Strong,
  MenuIcon,
  Heading,
  Spinner,
  CogIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import { api } from '../../services/api';
import inbox from '../../assets/images/inbox.png';

const Navbar = ({ onChangeDrawer }) => {
  const currentUser = useSelector((state) => state.authentication.user);

  return (
    <Pane
      is="header"
      width="100%"
      height="64px"
      paddingX="18px"
      borderBottom="1px solid #edeff5"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      top="0"
      background="white">
      <Pane display="flex" alignItems="center">
        <IconButton icon={MenuIcon} onClick={onChangeDrawer} appearance="minimal" />
      </Pane>

      <Pane display="flex" alignItems="center">
        {/* Menus */}
        <Pane display="flex" alignItems="center" marginRight={majorScale(2)}>
          <Popover
            position={Position.BOTTOM_RIGHT}
            content={<NotificationsMenu />}>
            <IconButton icon={NotificationsIcon} appearance="minimal" />
          </Popover>
        </Pane>
        {/* Profile */}
        <ProfileMenu currentUser={currentUser} />
      </Pane>
    </Pane>
  );
};

const ProfileMenu = ({ currentUser }) => {
  const logout = async () => {
    await api.users.logout();
    window.location.reload();
  };

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={(
        <Menu>
          <Menu.Group>
            <Pane
              paddingX={majorScale(2)}
              paddingY={majorScale(1)}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <Avatar name={currentUser && currentUser.name} size={48} />
              <Pane marginLeft={majorScale(2)}>
                <Pane>
                  <Strong>{currentUser && currentUser.name}</Strong>
                </Pane>
                <Pane>
                  <Text>{currentUser && currentUser.email}</Text>
                </Pane>
              </Pane>
            </Pane>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item is={ReactRouterLink} to="/user/settings" icon={CogIcon}>My settings</Menu.Item>
            <Menu.Item icon={LogOutIcon} onSelect={logout}>Logout</Menu.Item>
          </Menu.Group>
        </Menu>
      )}>
      <Avatar name={currentUser && currentUser.name} size={40} cursor="pointer" />
    </Popover>
  );
};

const NotificationsMenu = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });

  return (
    <Pane paddingY={majorScale(2)} maxHeight="50vh" overflowY="auto">
      {loading
        ? (
          <Pane display="flex" justifyContent="center" alignItems="center">
            <Spinner size={32} />
          </Pane>
        )
        : (
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img src={inbox} alt="No notifications" height="48px" />
            <Text marginTop={majorScale(2)}>No new notifications</Text>
          </Pane>
        )}
    </Pane>
  );
};

const NotificationItem = () => (
  <Pane
    padding={majorScale(2)}
    display="flex"
    alignItems="flex-start"
    hoverElevation={1}
    activeElevation={0}
    cursor="pointer">
    <Avatar name="Test notificcation" size={32} />
    <Pane marginLeft={majorScale(2)}>
      <Heading size="400">Test</Heading>
      <Text
        maxWidth="240px"
        overflow="hidden"
        whiteSpace="normal"
        textOverflow="ellipsis"
        display="-webkit-box"
        style={{ display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      </Text>
    </Pane>
  </Pane>
);

Navbar.propTypes = {
  onChangeDrawer: PropTypes.func,
};

ProfileMenu.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};

ProfileMenu.defaultProps = {
  currentUser: null,
};

export default Navbar;
