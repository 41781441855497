import React, { useState, useEffect } from 'react';
import {
  Pane,
  Text,
  Button,
  Alert,
  Tooltip,
  PlusIcon,
  EditIcon,
  TrashIcon,
  majorScale,
  IconButton,
  Pagination,
  TextHighlightIcon,
  toaster,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { api } from '../../../../../services/api';
import appTypes from '../../../../../constants/types';
import Skeleton from '../../../../../components/skeleton';
import NoResults from '../../../../../components/no-results';
import DeletePopConfirm from '../../../../../components/popconfirm/delete';
import SectionCard from '../../../../../components/section-card';
import EmailEditorSheet from '../../../../../components/email-editor';
import { AuthorizedWrapper } from '../../../../../components/authorized-wrapper';
import UpdateEmailTemplateName from './update-name';

const itemsPerPage = 5;

const SettingsEmailTemplates = () => {
  // Flags
  const [loading, setLoading] = useState(true);
  const [editorOpen, setEditorOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [templateToEdit, setTemplateToEdit] = useState(null);

  let unmounted = false;
  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.email.templates.list();
      if (!unmounted) {
        setTemplates(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setLoading(false);
      }
    } catch (e) {
      if (!unmounted) {
        setError('There was an error while proccessing your request');
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();

    return () => {
      unmounted = true;
    };
  }, []);

  const openEditor = async (template) => {
    if (template) {
      try {
        const loadedTemplate = await api.email.templates.details(template.id);
        setTemplateToEdit(loadedTemplate.data);
      } catch (e) {
        toaster.danger('There was an error while proccessing your request');
        return;
      }
    }
    setEditorOpen(true);
  };

  const saveTemplate = async (data) => {
    try {
      if (templateToEdit) {
        await api.email.templates.update(
          templateToEdit.id,
          { design: data.design, html: data.html },
        );
      } else {
        await api.email.templates.create(
          { design: data.design, html: data.html },
        );
      }
    } catch (e) {
      toaster.danger('There was an error while saving your template');
    }
  };

  const deleteTemplate = async (id) => {
    setLoading(true);
    try {
      await api.email.templates.delete(id);
      fetchData();
    } catch (e) {
      toaster.danger('There was an error while deleting your template');
    }
  };

  const closeEditor = () => {
    setTemplateToEdit(null);
    setEditorOpen(false);
    fetchData();
  };

  const openRenameDialog = (template) => {
    setTemplateToEdit(template);
    setRenameOpen(true);
  };

  const closeRenameDialog = () => {
    setTemplateToEdit(null);
    setRenameOpen(false);
    fetchData();
  };

  return (
    <SectionCard
      title="Templates"
      button={(
        <AuthorizedWrapper resource="email_templates" action="create">
          <Button iconBefore={PlusIcon} marginY={-6} appearance="primary" onClick={() => openEditor()}>
            New template
          </Button>
        </AuthorizedWrapper>
      )}>

      {loading && !error && templates.length < 1 && [...Array(3)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={i} width="100%" height="64px" style={{ borderRadius: '3px', marginTop: '16px' }} />
      ))}

      {!loading && !error && templates.length < 1 && (
        <NoResults buttonText="Create a new template" buttonAction={() => openEditor()} />
      )}

      {!loading && error && (
        <Alert intent="danger" title={error} />
      )}

      {!loading && !error && templates.length > 0 && (
        <>
          <Text color="muted">
            We use the Jinja2 syntax for specifying variables in the template.
            You can find the syntax
            {' '}
            <a href="https://jinja.palletsprojects.com/en/3.1.x/templates/" target="_blank" rel="noreferrer">here</a>
          </Text>
          {templates.slice(
            (currentPage - 1) * itemsPerPage,
            (currentPage - 1) * itemsPerPage + itemsPerPage,
          ).map((template) => (
            <EmailTemplate
              key={template.id}
              template={template}
              editTemplate={openEditor}
              deleteTemplate={deleteTemplate}
              renameTemplate={openRenameDialog} />
          ))}

          <Pane display="flex" justifyContent="flex-end">
            <Pagination
              page={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
              onPreviousPage={() => setCurrentPage(currentPage - 1)}
              onNextPage={() => setCurrentPage(currentPage + 1)} />
          </Pane>
        </>
      )}

      {editorOpen && (
        <EmailEditorSheet
          visible={editorOpen}
          close={closeEditor}
          template={templateToEdit}
          save={saveTemplate} />
      )}

      {renameOpen && (
        <UpdateEmailTemplateName
          isOpen={renameOpen}
          onCloseComplete={closeRenameDialog}
          template={templateToEdit}
        />
      )}
    </SectionCard>
  );
};

const EmailTemplate = ({ template, editTemplate, renameTemplate, deleteTemplate }) => (
  <Pane
    elevation={0}
    display="flex"
    alignItems="center"
    backgroundColor="white"
    justifyContent="space-between"
    marginY={majorScale(2)}
    padding={majorScale(1)}
    borderRadius="3px">
    <Text>{template.name}</Text>

    <Pane display="flex" alignItems="center">
      <AuthorizedWrapper resource="email_templates" action="update">
        <Tooltip content="Rename template">
          <IconButton icon={TextHighlightIcon} onClick={() => renameTemplate(template)} />
        </Tooltip>
      </AuthorizedWrapper>
      <AuthorizedWrapper resource="email_templates" action="update">
        <Tooltip content="Edit template">
          <IconButton
            marginLeft={majorScale(1)}
            icon={EditIcon}
            onClick={() => editTemplate(template)} />
        </Tooltip>
      </AuthorizedWrapper>
      {template.deletable && (
        <AuthorizedWrapper resource="email_templates" action="delete">
          <DeletePopConfirm onConfirm={() => deleteTemplate(template.id)}>
            <Tooltip content="Delete template">
              <IconButton marginLeft={majorScale(1)} icon={TrashIcon} intent="danger" />
            </Tooltip>
          </DeletePopConfirm>
        </AuthorizedWrapper>
      )}
    </Pane>
  </Pane>
);

EmailTemplate.propTypes = {
  template: PropTypes.shape(appTypes.emailTemplate.props).isRequired,
  editTemplate: PropTypes.func.isRequired,
  renameTemplate: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
};

export default SettingsEmailTemplates;
