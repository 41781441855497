import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const AuthorizedWrapper = ({ resource, action, uname, children }) => {
  const role = useSelector((state) => state.authentication.user.role);

  const isAuthorized = () => {
    if (uname) return role.uname === uname;
    return role.permissions[resource] && role.permissions[resource].actions.includes(action);
  };

  return isAuthorized() ? children : null;
};

AuthorizedWrapper.propTypes = {
  resource: PropTypes.string,
  action: PropTypes.string,
  uname: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AuthorizedWrapper.defaultProps = {
  resource: undefined,
  action: undefined,
  uname: undefined,
};
