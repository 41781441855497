import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from './route';

import SettingsLocationsPage from '../pages/settings/locations';
import SettingsFeaturesPage from '../pages/settings/features';
import SettingsUsersPage from '../pages/settings/users';
import SettingsTeamsPage from '../pages/settings/teams';
import SettingsEmailGroupsPage from '../pages/settings/email-groups';
import RequestsPage from '../pages/requests';
import TicketsPage from '../pages/tickets';
import TicketsCreatePage from '../pages/tickets/create';
import TicketDetailsPage from '../pages/tickets/details';
import SettingsRolesPage from '../pages/settings/roles';
import UserSettingsPage from '../pages/user-settings';
import MyAccountsPage from '../pages/my-accounts';
import SettingsEmailPage from '../pages/settings/email';
import SettingsMetricsPage from '../pages/metrics';

const Router = () => (
  <Routes>
    <Route
      exact
      path="/settings"
      element={(
        <PrivateRoute>
          <Navigate replace to="/settings/email" />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/teams"
      element={(
        <PrivateRoute>
          <SettingsTeamsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/locations"
      element={(
        <PrivateRoute>
          <SettingsLocationsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/groups"
      element={(
        <PrivateRoute>
          <SettingsEmailGroupsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/users"
      element={(
        <PrivateRoute>
          <SettingsUsersPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/roles"
      element={(
        <PrivateRoute>
          <SettingsRolesPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/email"
      element={(
        <PrivateRoute>
          <SettingsEmailPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/metrics"
      element={(
        <PrivateRoute>
          <SettingsMetricsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/settings/features"
      element={(
        <PrivateRoute>
          <SettingsFeaturesPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/"
      element={(
        <PrivateRoute>
          <Navigate replace to="/tickets" />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/requests"
      element={(
        <PrivateRoute>
          <RequestsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/tickets"
      element={(
        <PrivateRoute>
          <TicketsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/tickets/create"
      element={(
        <PrivateRoute>
          <TicketsCreatePage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/tickets/:id"
      element={(
        <PrivateRoute>
          <TicketDetailsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/user/settings"
      element={(
        <PrivateRoute>
          <UserSettingsPage />
        </PrivateRoute>
      )} />
    <Route
      exact
      path="/my-accounts"
      element={(
        <PrivateRoute>
          <MyAccountsPage />
        </PrivateRoute>
      )} />
  </Routes>
);

export default Router;
