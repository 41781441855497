import React, { useState, createContext } from 'react';
import { CornerDialog } from 'evergreen-ui';
import PropTypes from 'prop-types';

const { Provider, Consumer } = createContext({
  isOpen: false,
  open: () => {},
  close: () => {},
});

export const CornerDialogProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState({});

  const open = (dialogData) => {
    setDialog(dialogData);
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };

  return (
    <Provider
      value={{
        // isOpen: show,
        open,
        close,
      }}>
      <>
        {children}
        {show && dialog && (
          <CornerDialog
            title={dialog.title}
            isShown={show}
            onCloseComplete={close}
            confirmLabel={dialog.confirmLabel}
            cancelLabel={dialog.cancelLabel}
            hasClose={dialog.hasClose}
            hasCancel={dialog.hasCancel}
            onConfirm={dialog.onConfirm}
            containerProps={{ zIndex: 10 }}>
            {dialog.content}
          </CornerDialog>
        )}
      </>
    </Provider>
  );
};

export const CornerDialogConsumer = Consumer;

CornerDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
