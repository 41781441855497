import React, { useState } from 'react';
import { Button, Card, Checkbox, FormField, Heading, majorScale, Pane, TextInput } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadUserFromGoogleDialog } from '../../../../../components/dialogs';
import { SelectSnipeAssignedAssetsDialog } from '../../../../../components/dialogs'

const SnipeCheckinAssetForm = ({ submit }) => {
  const [allowEdit, setAllowEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      asset_id: '',
    },
    onSubmit: (values) => submit(values),
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Must be a valid email'),
      asset_id: Yup.number().required('Asset is required'),
    }),
    validateOnChange: false,
  });
  const { values, errors, handleChange } = formik;

  const loadGoogleUser = (user) => {
    if (user) {
      formik.setFieldValue('email', user.email);
    }
  };

  const selectSnipeAsset = (selectedAsset) => {
    if (selectedAsset) {
      formik.setFieldValue('asset_id', selectedAsset.id);
    }
  };

  return (
    <Pane>
      <form onSubmit={formik.handleSubmit}>
        <Card border="default" marginBottom={majorScale(2)} paddingX={majorScale(2)} backgroundColor="white">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingY={majorScale(2)}
            borderBottom="1px solid #edeff5">
            <Pane>
              <Heading size={600}>Checkin Snipe asset from selected user</Heading>
            </Pane>
            <Pane>
              <LoadUserFromGoogleDialog onSelect={loadGoogleUser} />
            </Pane>
          </Pane>

          <Pane paddingBottom={majorScale(2)}>
            <Checkbox
              label="Enter account manually"
              checked={allowEdit}
              onChange={(e) => setAllowEdit(e.target.checked)} />
            <FormField
              label="Email"
              marginTop={majorScale(2)}
              maxWidth="19%"
              validationMessage={errors.email}>
              <TextInput
                data-testid="email"
                name="email"
                width="100%"
                onChange={handleChange}
                value={values.email}
                readOnly={!allowEdit}
                isInvalid={errors.email && true} />
            </FormField>
            <FormField label="Asset ID" validationMessage={errors.asset_id} paddingTop={majorScale(1)}>
              <Pane display="flex" alignItems="center">
                <TextInput
                  value={values.asset_id}
                  readOnly
                  width={120}
                  marginRight={majorScale(1)}
                />
                <SelectSnipeAssignedAssetsDialog
                  onSelect={selectSnipeAsset}
                  email={values.email}
                />
              </Pane>
            </FormField>
          </Pane>
        </Card>
        <Card
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="white"
          padding={majorScale(2)}
          border>
          <Button type="button" disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <Button
            marginLeft={majorScale(2)}
            appearance="primary"
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}>
            Submit
          </Button>
        </Card>
      </form>
    </Pane>
  );
};

export default SnipeCheckinAssetForm;
